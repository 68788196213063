<template>
  <div class="user-login">
    <div class="login-form">
      <h2>集体经济组织实名认证</h2>
      <el-form ref="form" :model="form" label-width="160px" :rules="formRules">
        <el-form-item label="集体经济组织登记证" prop="businessLicense">
          <el-upload
            list-type="picture-card"
            class="upload-demo"
            :action="uploadUrl"
            :limit="1"
            multiple
            :before-upload="beforeUpload"
            :on-change="uploadImages"
            :on-remove="removeImages"
            :auto-upload="false"
            :accept="'.jpg,.jpeg,.gif,.png,.pdf'"
          >
            <el-button v-if="uploading == 0" size="small" type="primary"
              >点击上传</el-button
            >
            <el-button
              v-else-if="uploading == 1"
              size="small"
              type="primary"
              :loading="true"
              style="
                display: inline-flex;
                justify-content: center;
                align-items: center;
              "
              >上传中...</el-button
            >
            <el-button
              v-else-if="uploading == 2"
              size="small"
              type="primary"
              :loading="true"
              style="
                display: inline-flex;
                justify-content: center;
                align-items: center;
              "
              >努力识别中</el-button
            >
            <div slot="tip" class="el-upload__tip" style="margin-top: -6px">
              上传图片最大6M，支持：JPG/JPEG/GIF/PNG/PDF格式。
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="组织名称" prop="groupName">
          <el-input placeholder="" v-model="form.groupName"> </el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="socialCreditId">
          <el-input placeholder="" v-model="form.socialCreditId"> </el-input>
        </el-form-item>
        <!--组织所在地 精确到县 镇 村 3级 -->
        <el-form-item label="组织所在地" prop="areaCode">
          <el-cascader
            ref="address"
            v-model="value"
            :options="options"
            :props="props"
            style="width: 49%; margin-right: 2%"
            @change="cityChange"
          ></el-cascader>
          <el-cascader
            ref="address2"
            v-model="value2"
            :options="options2"
            :props="props"
            @change="roadChange"
            style="width: 49%"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="对公账号" prop="groupBankId">
          <el-input
            style="width: 258px"
            placeholder=""
            v-model="form.groupBankId"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="所属银行">
          <el-input
            style="width: 258px"
            placeholder=""
            v-model="form.affiliatedBank"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="负责人身份证人像面" class="is-required">
          <div class="upload">
            <div class="upload-box">
              <el-upload
                v-loading="loading"
                :element-loading-text="lo == 1 ? '努力识别中' : '上传中'"
                ref="front"
                action=""
                :auto-upload="false"
                :show-file-list="false"
                name="files"
                :on-change="
                  (file) => {
                    handleChange(file, '0');
                  }
                "
              >
                <img
                  slot="default"
                  :src="
                    imgUrl[0]
                      ? this.baseUrl + 'admin/file/get?ossFilePath=' + imgUrl[0]
                      : 'imgs/card-1.png'
                  "
                  alt=""
                />
              </el-upload>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="负责人身份证国徽面" class="is-required">
          <div class="upload">
            <div class="upload-box">
              <el-upload
                ref="back"
                action=""
                :auto-upload="false"
                :show-file-list="false"
                list-type="picture-card"
                :limit="1"
                name="files"
                :on-change="
                  (file) => {
                    handleChange(file, '1');
                  }
                "
              >
                <img
                  :src="
                    imgUrl[1]
                      ? this.baseUrl + 'admin/file/get?ossFilePath=' + imgUrl[1]
                      : 'imgs/card-2.png'
                  "
                  alt=""
                />
              </el-upload>
            </div>
            <p style="color: #ff6633; margin: 0">
              (特别提示：照片必须将身份证四个边框完整拍入)
            </p>
          </div>
        </el-form-item>
        <el-form-item label="负责人姓名" prop="leaderName">
          <el-input placeholder="" v-model="form.leaderName"> </el-input>
        </el-form-item>
        <el-form-item label="负责人身份证号" prop="leaderCardId">
          <el-input placeholder="" v-model="form.leaderCardId"> </el-input>
        </el-form-item>
        <!-- 来控制联系人显示隐藏 -->
        <el-button
          class="btn"
          type="primary"
          style="margin-left: 10px; margin-bottom: 10px"
          @click="lxr = !lxr"
          >添加联系人</el-button
        >
        <div v-if="lxr">
          <el-form-item label="联系人" prop="contactName">
            <el-input
              placeholder="默认本账户姓名手机号"
              v-model="form.contactName"
              style="width: 258px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系人身份证号">
            <el-input
              placeholder="联系人身份证号"
              v-model="form.contactCard"
              style="width: 258px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="联系人电话" prop="contactPhone">
            <el-input
              style="width: 258px"
              v-model="form.contactPhone"
              placeholder="如非本人账户手机，请输入验证码"
            ></el-input>
            <el-button
              class="btn"
              type="primary"
              @click="getSmsCode6"
              :disabled="flag6"
              style="padding: 10px; margin-left: 10px"
            >
            </el-button>
          </el-form-item>
          <el-form-item label="联系人短信验证码" prop="contactSmsCode">
            <el-input
              placeholder=""
              v-model="form.contactSmsCode"
              style="width: 258px"
            >
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <div>
        <el-button
          type="success"
          @click="onSubmitOther"
          style="padding: 12px 100px; margin-top: 20px"
          >提交</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { getXzqArr, getIdentityList } from "@/api/add";
import { removeEmptyChildren } from "@/js/utils/util";
import { bankCardAttribution } from "./index";
import {
  groupOrgCertificate,
  getSmsCodeNew,
  getyyzz,
  getsfz,
} from "@/api/certificate";
import { mapGetters, mapMutations } from "vuex";
import { uploadFile, deleteFile } from "@/api/fileApi";
export default {
  data() {
    var checkArea = (rule, value, callback) => {
      // if (!value) {
      //   return callback(new Error("组织所在地不为空"));
      // } else {
      setTimeout(() => {
        if (this.areaHasChild) {
          callback(new Error("请选到镇或者村"));
        } else {
          callback();
        }
      }, 100);
      // }
    };
    var validateIdNumber = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输负责人身份证号"));
      } else if (!/(^\d{15}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
        callback(new Error("身份证号码格式不正确"));
      } else {
        callback();
      }
    };
    return {
      uploading: 0,
      lo: 0,
      loading: false,
      lxr: false,
      areaHasChild: false,
      value: "",
      value2: "",
      current5: 60,
      current6: 60,
      max: 60,
      imagesIds: [],
      imagesInfos: [],
      options: [],
      options2: [],
      proviceArr: [],
      decreseInterval2: null,
      decreseInterval: null,
      countryArr: [],
      flag5: false,
      flag6: false,
      regionOptions: [{}],
      props: {
        label: "areaName",
        children: "children",
        value: "id",
        checkStrictly: true,
        expandTrigger: "hover",
      },
      form: {
        bossCardBack: "",
        bossCardFront: "",
        groupName: "",
        socialCreditId: "",
        areaCode: "",
        groupBankId: "",
        // groupBankPhone: "",
        leaderName: "",
        leaderCardId: "",
        contactName: "",
        contactPhone: "",
        contactSmsCode: "",
        businessLicense: "",
        affiliatedBank: "",
        contactCard: "",
        id: "",
      },
      uploadUrl: `${window.globalUrl.HOME_API_WEI}/trade-website/cqjyService/upLoadCqpzImages`, // 产权凭证,
      fileList: [],
      cropType: [],
      opertion: [],
      areaType: [],
      formRules: {
        groupName: [
          { required: true, message: "请输入组织名称", trigger: "blur" },
        ],
        socialCreditId: [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "blur",
          },
        ],
        areaCode: [{ required: true, validator: checkArea, trigger: "change" }],
        // areaCode: [{ required: true,message: " ", trigger: "change" }],

        leaderName: [
          { required: true, message: "请输入负责姓名", trigger: "blur" },
        ],
        leaderCardId: [
          { required: true, validator: validateIdNumber, trigger: "blur" },
        ],
        contactName: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        contactPhone: [
          { required: true, message: "请输入联系人电话", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        contactSmsCode: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
        ],
        //  bossCardFront: [
        //   {
        //     required: true,
        //     // message: "请上传法人身份证人像面",
        //     // trigger: "blur",
        //   },
        // ],
        // bossCardBack: [
        //   {
        //     required: true,
        //     // message: "请上传法人身份证国徽面",
        //     // trigger: "blur",
        //   },
        // ],

        businessLicense: [
          {
            required: true,
            message: "请上传集体经济组织登记证",
            trigger: "change",
          },
        ],
      },
      value: [],
      imgUrl: ["", ""], //身份证人像页的本地图片url
      imgInfo: [undefined, undefined], //身份证人像页的上传返回数据
      baseUrl: window.globalUrl.HOME_API,
    };
  },
  computed: mapGetters(["userInfo", "identityObj"]),
  created() {
    this.getRegions();
  },
  mounted() {
    // 联系人默认
    // this.form.contactName = this.identityObj.contactName;
    this.form.contactPhone = this.identityObj.contactPhone
      ? this.identityObj.contactPhone
      : this.userInfo.phone;
    if (this.$route.params.item) {
      // 接收路由参数
      console.log("route-item", this.$route.params);
      this.form.groupName = this.$route.params.item.identityName
        ? this.$route.params.item.identityName
        : "";
      this.form.socialCreditId = this.$route.params.item.identityCode
        ? this.$route.params.item.identityCode
        : "";

      this.form.bankCardPhone = this.$route.params.item.contactPhone ? this.$route.params.item.contactPhone : "";
      this.form.leaderName = this.$route.params.item.bossName ? this.$route.params.item.bossName : "";
      this.form.leaderCardId = this.$route.params.item.bossCardId ? this.$route.params.item.bossCardId : "";
      // 身份证照片
      this.form.bossCardFront = this.$route.params.item.userCardFrontImg ? this.$route.params.item.userCardFrontImg : "";
      this.form.bossCardBack = this.$route.params.item.userCardBackImg ? this.$route.params.item.userCardBackImg : "";
      this.form.id = this.$route.params.item.identityId ? this.$route.params.item.identityId : "";
      this.imgUrl[0] = this.$route.params.item.userCardFrontImg
        ? this.baseUrl +
          "admin/file/get?ossFilePath=" +
          this.$route.params.item.userCardFrontImg
        : "";
      this.imgUrl[1] = this.$route.params.item.userCardBackImg
        ? this.baseUrl +
          "admin/file/get?ossFilePath=" +
          this.$route.params.item.userCardBackImg
        : "";
      this.imgInfo[0] = this.form.bossCardFront;
      this.imgInfo[1] = this.form.bossCardBack;
      this.form.groupBankId = this.$route.params.item.bankCardId;
      if (this.form.groupBankId.length >= 5) {
        const obj = bankCardAttribution(this.form.groupBankId); // 调用查看所属银行
        if (obj != "error") {
          this.form.affiliatedBank = obj.bankName;
        } else {
          this.form.affiliatedBank = "";
        }
      }
    }
  },
  watch: {
    "form.groupBankId": function () {
      if (this.form.groupBankId.length >= 5 && this.form.affiliatedBank == "") {
        const obj = bankCardAttribution(this.form.groupBankId + ""); // 调用查看所属银行
        if (obj != "error") {
          this.form.affiliatedBank = obj.bankName;
        } else {
          this.form.affiliatedBank = "";
        }
      }
    },
  },
  methods: {
    //上传身份证
    async handleChange(file, type) {
      console.log("8888", this.checkFormat(file.raw));
      let imgIndex = type * 1; //在数组中的索引
      if (this.checkFormat(file.raw)) {
        console.log("111111111111111");
        if (type == 0) {
          this.loading = true;
        }

        //符合格式要求
        if (this.imgInfo[imgIndex]) {
          //更换新的照片时需要删除旧照片
          this.deleteFile(this.imgInfo[imgIndex]);
          this.imgInfo[imgIndex] = undefined;
        }
        let res = await this.uploadSfz(file.raw, type);
        // console.log("res", res);
      } else {
        this.loading = false;
        return;
      }
    },
    checkFormat(file) {
      console.log("file", file);
      console.log("file.type", file.type);
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt6M = file.size / 1024 / 1024 < 6;
      if (!isJPG) {
        this.$message.error("只能上传 JPG/JPEG/PNG 格式的图片或文件");
      }
      if (!isLt6M) {
        this.$message.error("上传图片大小不能超过 6MB");
      }
      return isJPG && isLt6M;
    },
    async uploadSfz(file, type) {
      let imgIndex = type * 1; //在数组中的索引
      let param = new FormData();
      param.append("catalog", "sfz");
      param.append("file", file);
      // console.time('myCode')
      let res = await uploadFile(param);
      // console.timeEnd("myCode");
      console.log("res", res);

      // ocr认证身份证
      if (type == 0) {
        this.lo = 1;
        // console.time('myCode')
        let res1 = await getsfz({
          identity: res.data.thumbnailUrl
            ? res.data.thumbnailUrl
            : res.data.url,
        });
        // console.timeEnd("myCode");
        console.log("sfz", res1.data.result);
        // 如果是人头面的话执行
        if (res1.data.result[0].note == "back") {
          this.form.leaderName = res1.data.result[0].detail.name;
          this.form.contactName = res1.data.result[0].detail.name;
          this.form.leaderCardId = res1.data.result[0].detail.idn;
          // this.loading=false
          // this.lo=0
        }

        if (res.code == 0) {
          // this.$set(this.imgUrl, imgIndex, URL.createObjectURL(file));
          this.$set(this.imgUrl, imgIndex, res.data.url);
          this.imgInfo[imgIndex] = res.data.url;

          console.log("imgUrl", this.imgUrl);
        }
      } else {
        if (res.code == 0) {
          this.$set(this.imgUrl, imgIndex, res.data.url);
          this.imgInfo[imgIndex] = res.data.url;
        } else {
          this.$message.error("上传失败，请重新上传");
        }
      }

      this.loading = false;
    },

    async deleteFile(url) {
      let res = await deleteFile({ ossFilePath: url });
      if (res.code == 0 && res.data == true) {
        console.log("删除成功");
      }
    },
    removeImages(file) {
      let index = this.imagesIds.indexOf(file.uid);
      this.deleteFile(this.imagesInfos[index]);
      this.imagesIds.splice(index, 1);
      this.imagesInfos.splice(index, 1);
    },
    async getRegions() {
      // 获取行政区树
      let res = await getXzqArr({
        level: 3,
      });
      if (res.code == 0) {
        this.options = removeEmptyChildren(res.data, "children");
        console.log(JSON.parse(JSON.stringify(this.options)));
      }
    },
    async cityChange(val) {
      console.log("val", val);
      // 使用点符号访问props对象的label属性
      const checkedNodes =
        this.$refs["address"].getCheckedNodes()[0].pathLabels; // 获取当前点击的节点
      if (checkedNodes[checkedNodes.length - 1] == "市辖区") {
        this.areaHasChild = false;
        this.value2 = this.value;
      }
      console.log(checkedNodes[checkedNodes.length - 1]);
      // console.log(checkedNodes[0].data.label) // 获取当前点击的节点的label
      // console.log(checkedNodes[0].pathLabels) // 获取由 label 组成的数组

      if (val) {
        let params = { parentCode: val[val.length - 1] };
        let res = await getXzqArr(params);
        if (res.code == 0) {
          this.value2 = null;
          this.form.areaCode = val[val.length - 1];
          if (res.data.length > 0) {
            this.options2 = removeEmptyChildren(res.data, "children");
            console.log("点击", this.options2);
            this.areaHasChild = true;
          } else {
            this.options2 = [];
            this.areaHasChild = false;
          }
        }
      }
    },
    async uploadImages(file, filelist) {
      const isJPG =
        file.raw.type === "image/jpeg" || file.raw.type === "image/png";
      const isPDF = file.raw.type === "application/pdf";
      const isLt6M = file.raw.size / 1024 / 1024 < 6;
      if (!isJPG && !isPDF) {
        this.$message.error("只能上传 JPG/JPEG/PNG/GIF/PDF 格式的图片或文件");
        const indexOfFile = filelist.indexOf(file);
        filelist.splice(indexOfFile, 1);
        return;
      }

      // if (!isLt6M) {
      //   this.$message.error("上传图片或文件大小不能超过 6MB");
      //   const indexOfFile = filelist.indexOf(file);
      //   filelist.splice(indexOfFile, 1);
      //   return
      // }
      this.uploading = 1;
      let res = await this.uploadFile(file, "images");
    },
    async uploadFile(file, type) {
      let param = new FormData();
      param.append("catalog", "register");
      param.append("file", file.raw);
      let res = await uploadFile(param);
      if (res.code == 0) {
        this.imagesIds.push(file.uid);
        this.imagesInfos.push(res.data.url);
        this.uploading = 2;
      } else {
        this.$message.error("上传失败");
        const indexOfFile = filelist.indexOf(file);
        filelist.splice(indexOfFile, 1);
      }
      let res1 = await getyyzz({
        identity: res.data.thumbnailUrl ? res.data.thumbnailUrl : res.data.url,
      });
      this.form.groupName = this.form.groupName
        ? this.form.groupName
        : res1.data.returnObj.detail.name;
      this.form.socialCreditId = this.form.socialCreditId
        ? this.form.socialCreditId
        : res1.data.returnObj.detail.union_id;
      this.uploading = 0;
    },
    roadChange(val) {
      let node = this.$refs.address2.getCheckedNodes()[0];
      if (node.children.length == 0) {
        this.areaHasChild = false;
        this.form.areaCode = val[val.length - 1];
      }

      console.log(2, this.value2);
      // const checkedNodes = this.$refs['address'].getCheckedNodes() // 获取当前点击的节点
      // if(checkedNodes[checkedNodes.length].pathLabels == "市辖区"){
      //   this.areaHasChild=false
      //   return
      // }
      let check = this.checkStringLength(this.value2);
      console.log(check);
      this.areaHasChild = check;
    },

    // 定义一个函数来判断数组中倒数第一位或者倒数第二位的字符串长度是否大于等于9
    checkStringLength(arr) {
      // if (arr.length < 2) {
      //   return true; // 如果数组长度小于2，直接返回 false
      // }
      // 获取数组的倒数第一位和倒数第二位字符串
      const lastItem = arr[arr.length - 1];
      const secondLastItem = arr[arr.length - 2];

      // 判断倒数第一位或者倒数第二位字符串的长度是否大于等于9
      if (
        (lastItem && lastItem.length >= 9) ||
        (secondLastItem && secondLastItem.length >= 9)
      ) {
        return false; // 返回 true 表示满足条件
      } else {
        return true; // 返回 false 表示不满足条件
      }
    },
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isPDF = file.type === "application/pdf";
      const isLt6M = file.size / 1024 / 1024 < 6;
      if (!isJPG && !isPDF) {
        this.$message.error("只能上传 JPG/JPEG/PNG/GIF/PDF 格式的图片或文件");
      }

      if (!isLt6M) {
        this.$message.error("上传图片或文件大小不能超过 6MB");
      }

      return isJPG || (isPDF && isLt6M);
    },
    handleSuccess(response, file) {
      this.$message.success("上传成功");
    },
    handleError(error, file) {
      this.$message.error("上传失败");
    },
    async onSubmitOther() {
      console.log(this.form);
      this.form.userId =
        localStorage.getItem("loginStep1UserId") || this.userInfo.id;
      this.form.businessLicense = this.imagesInfos
        ? this.imagesInfos.join(",")
        : "";
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          // let cardId = {
          //   bossCardFront: this.imgUrl[0],
          //   bossCardBack: this.imgUrl[1],
          // };
          let cardId = {
            bossCardFront:
              this.imgUrl && this.imgUrl.length > 0 ? this.imgUrl[0] : null,
            bossCardBack:
              this.imgUrl && this.imgUrl.length > 1 ? this.imgUrl[1] : null,
          };
          console.log("111", cardId);
          let res = await groupOrgCertificate({ ...this.form, ...cardId });
          if (res.code == 0) {
            this.$message.success("认证成功!即将跳转至登录页");
            localStorage.clear();
            setTimeout(() => {
              // this.loadIdentityList();
              this.$router.push({ name: "login" });
            }, 500);
          } else {
            this.$message.error(res.msg);
          }
        } else {
          this.$message.error("请完善认证信息");
        }
      });
    },
    async loadIdentityList() {
      let res = await getIdentityList();
      if (res.code == 0) {
        if (res.data.length == 1) {
          this.$store.dispatch("SetIdentityObj", res.data[0]);
          this.$router.push({ name: "homepage" });
        } else {
          this.$router.push({ name: "chooseRole" });
        }
      }
    },
    // async getSmsCode5() {
    //   // 获取手机验证码
    //   if (this.form.groupBankPhone) {
    //     let param = {
    //       phone: this.form.groupBankPhone + "",
    //       type: "5",
    //     };
    //     let res = await getSmsCodeNew(param);
    //     let { code, data, msg } = res;
    //     if (code == 0) {
    //       this.$message.success(msg);
    //       this.current5 = this.max;
    //       this.decreseInterval = setInterval(() => {
    //         if (this.current5 <= this.max && this.current5 > 0) {
    //           this.current5--;
    //           this.flag5 = true;
    //         } else if (this.current5 <= 0) {
    //           this.current5 = 0;
    //           this.flag5 = false;
    //           clearInterval(this.decreseInterval);
    //         }
    //       }, 1000);
    //     } else {
    //       this.$message.error(msg);
    //     }
    //   } else {
    //     this.$message.error("请先输入手机号！");
    //   }
    // },
    async getSmsCode6() {
      if (this.form.contactPhone) {
        let param = {
          phone: this.form.contactPhone + "",
          type: "6",
        };
        let res = await getSmsCodeNew(param);
        let { code, data, msg } = res;
        if (code == 0) {
          this.$message.success(msg);
          this.current6 = this.max;
          this.decreseInterval2 = setInterval(() => {
            if (this.current6 <= this.max && this.current6 > 0) {
              this.current6--;
              this.flag6 = true;
            } else if (this.current6 <= 0) {
              this.current6 = 0;
              this.flag6 = false;
              clearInterval(this.decreseInterval2);
            }
          }, 1000);
        } else {
          this.$message.error(msg);
        }
      } else {
        this.$message.error("请先输入手机号！");
      }
    },
  },
};
</script>
<style lang="less" scoped>
h2 {
  margin-bottom: 30px;
}
.user-login {
  width: 1200px;
  margin: 0 auto;
  background: #f2f2f2;
  padding: 30px 0;
  .login-form {
    width: 520px;
    padding: 6px 140px 40px 110px;
    background-color: #ffffff;
    margin: 0px auto;
    text-align: center;
  }
}
/deep/.el-form-item__content {
  text-align: left;
}

.upload-box {
  /deep/.el-upload {
    width: 148px !important;
    height: 126px !important;
    img {
      width: 100%;
      height: 100%;
    }
  }
  // .el-upload-list--picture-card .el-upload-list__item {
  //   width: 301px;
  //   height: 261px;
  // }
}
/deep/.el-form-item__content {
  text-align: left;
}
/deep/.el-loading-spinner {
  // text-align: left;
  width: 39% !important;
}
/deep/.el-loading-text {
  // text-align: left;
  margin-top: -29px;
}
</style>
