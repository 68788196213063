<template>
  <div class="land-trust">
    <div class="quick-banner">
      <p>农资服务</p>
      <img src="../../assets/imgs/banner2.png" alt="" />
    </div>
    <div class="land-main">
      <ul class="detail">
        <li>名称：{{ info.name }}</li>
        <li>地址：{{ info.address }}</li>
        <li>联系电话：{{ info.phone }}        <el-button type="primary" @click="communication()"
            >在线沟通</el-button
          ></li>
        <li>经营范围：</li>
        <li>{{ info.businessRange }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card/index.vue";
import BaiduMap from "../productionService/components/landTrustSubpage/baiduMap.vue";
import {
  getAgriculturalDetail,
} from "@/api/supplyDemand.js";
import { conversationMachineryAgricultural } from "@/api/websocket.js";

export default {
  components: {
    Card,
    BaiduMap,
  },
  data() {
    return {
      total: 0,
      currentPage: 1,
      pageSize: 5,
      currentCode: 0,
      keyword: "",
      tableData: [],
      list: [
        {
          id: "0",
          name: "宣汉县县运营公司名称",
          lot: 107.95,
          lat: 31.4,
        },
        {
          id: "1",
          name: "宣汉县测试公司名称",
          lot: 108,
          lat: 31.5,
        },
      ],

      info: "",
    };
  },
  mounted() {
    // this.getDataAll();
    this.info = this.$route.params.item;
    // this.getDataAll();
  },
  methods: {
    async communication() {
      const res = await conversationMachineryAgricultural({
        registrationCode: this.info.registrationCode,
      });
      if(res.code==0){
        this.$router.push({
            path: "/home/Chat",
         
          });
      }
    },
    async getDataAll() {
      let res = await getAgriculturalDetail({ id: this.info.id });
      if (!res || res.code != 0) {
        return;
      }

      // console.log(res.data);
    },
  },
};
</script>

<style lang="less" scoped>
.land-trust {
  background: #fff;
  li {
    list-style: none;
  }
}
.quick-banner {
  position: relative;
  height: 180px;
  overflow: hidden;

  p {
    position: absolute;
    top: 40px;
    left: 20%;
    font-size: 32px;
    color: #fff;
  }
}

.land-main {
  width: 1472px;
  text-align: left;
  margin: 0 auto;

  .detail {
    li {
      padding: 20px 0;
    }
  }

  .new-list {
    margin-top: 20px;
    .news-tab {
      color: #999;
      font-size: 16px;
      border-bottom: 1px solid #d9d9d9;
      display: flex;
      padding: 0px 0 16px 0px;
      position: relative;

      .input-with-select {
        position: absolute;
        right: 0;
        top: -10px;
        width: auto;
      }
      li {
        margin: 0px 20px 0px 0px;
        padding-left: 16px;
        font-size: 16px;
        font-weight: bold;

        &:hover {
          cursor: pointer;
          color: #333;
        }

        &.active {
          color: #333;
          position: relative;
          &::before {
            position: absolute;
            content: "";
            width: 5px;
            height: 5px;
            top: 10px;
            left: 3px;
            background: #00a0e9;
            border-radius: 5px;
          }
        }
      }
    }

    .new-des {
      ul,
      li {
        padding: 0;
        margin: 0;
      }
      ul {
        padding-top: 20px;
      }
      ul li {
        display: flex;
        padding-bottom: 10px;
        border-bottom: 1px dashed #dcdcdc;

        .img {
          img {
            width: 120px;
            height: 100px;
          }
        }

        .des {
          padding-left: 20px;
          text-align: left;

          h4 {
            font-size: 24px;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }

  .pages {
    margin-top: 16px;
    text-align: right;
  }

  .news-des {
    margin-bottom: 20px;
  }

  .map-area {
    margin: 20px 0;
    height: 500px;
  }
}
</style>

<style lang="less">
.land-trust .el-table th {
  background: #0668b3;
  color: #fff;
}
</style>
