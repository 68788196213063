<template>
  <!-- 会员中心 -->
  <div class="ybmxm">
    <div class="project">
      <div style="padding: 0 30px">
        <h3>涉农补贴管理</h3>
        <el-button type="primary" @click="add">新增补贴项目</el-button>
      </div>
      <div class="table-card">
        <div class="table-con">
          <el-table border class="table" :data="tableData" style="width: 100%">
            <el-table-column
              align="center"
              prop="subsidyId"
              label="补贴项目编号"
              width="180px"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="subsidyType"
              label="名称"
              width="120px"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.subsidyType == 1">种粮大户补贴</span>
                <span v-if="scope.row.subsidyType == 2">粮食价格补贴</span>
                <span v-if="scope.row.subsidyType == 3">农机购置补贴</span>
                <span v-if="scope.row.subsidyType == 4">社会化服务补贴</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="applyStartTime"
              label="申请时间"
              width="120px"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="createTime"
              label="发布时间"
              width="160px"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="createName"
              label="发布人"
              width="160px"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="updateTime"
              label="更新时间"
              width="160px"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="updateName"
              label="更新人"
              width="160px"
            ></el-table-column>
            <el-table-column align="center" prop="delFlag" label="状态">
              <template slot-scope="scope">
                <span v-if="scope.row.delFlag == 0">启用</span>
                <span v-if="scope.row.delFlag == 1">停用</span>
              </template>
            </el-table-column>
            <el-table-column align="left" label="操作" width="200">
              <template slot-scope="scope">
                <el-button
                  style="height: 28px; padding: 0 8px"
                  type="primary"
                  @click="edit(scope.row)"
                  >修改</el-button
                >
                <el-button
                  style="height: 28px; padding: 0 8px"
                  type="success"
                  v-if="scope.row.delFlag == 1"
                  @click="enabled(scope.row)"
                  >启用</el-button
                >
                <el-button
                  style="height: 28px; padding: 0 8px"
                  type="danger"
                  v-if="scope.row.delFlag == 0"
                  @click="noSelect(scope.row)"
                  >停用</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <el-dialog :title="typeindex==1 ? '修改补贴项目' : '新增补贴项目'" :visible.sync="dialogVisible" width="70%">
      <table style="width: 100%">
        <tr>
          <td style="font-weight: bold">补贴类别:</td>
          <td>
            <el-select v-model="subsidyType" clearable placeholder="请选择"  :disabled="typeindex==0 ? false : true">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td style="font-weight: bold">补贴申请时间:</td>
          <td>
            <el-date-picker
              v-model="applyStartTime"
              value-format="yyyy-MM-dd"
              placeholder="选择开始日期"
              :picker-options="pickerOptions1"
            >
            </el-date-picker>
            至
            <el-date-picker
              v-model="applyEndTime"
              value-format="yyyy-MM-dd"
              placeholder="选择结束日期"
              :picker-options="pickerOptions2"
            >
            </el-date-picker>
          </td>
        </tr>

        <tr>
          <td style="font-weight: bold">服务对象:</td>
          <td>
            <el-checkbox-group v-model="serviceType">
              <el-checkbox
                v-for="city in cities"
                :label="city.label"
                :key="city.value"
                >{{ city.value }}</el-checkbox
              >
            </el-checkbox-group>
          </td>
        </tr>
        <tr>
          <td style="font-weight: bold">补贴要求:</td>
          <td>
            <el-input
              type="textarea"
              :rows="3"
              placeholder="请简短介绍补贴要求，文字不超的300字。"
              v-model="subsidyRequire"
              maxlength="300"
              show-word-limit
            >
            </el-input>
          </td>
        </tr>
      </table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSave" :disabled="isSaving"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  selectBackSubsidyTypeList,
  subsidytype,
  insertSubsidyType,
  deleteSubsidyType,
  updateSubsidyType,
} from "@/api/snbt";
import { log } from "console";
import { type } from "os";
import { t } from "@wangeditor/editor";
export default {
  components: {},
  data() {
    return {
      tableData: [], // 表格数据
      dialogVisible: false, // 新增补贴项目

      applyEndTime: "", // 补贴申请结束时间
      applyStartTime: "", // 补贴申请开始时间
      serviceType: [], // 服务对象
      subsidyRequire: "", // 补贴要求
      subsidyType: "", // 补贴类型
      subsidyId: "", // 补贴项目编号
      options: [], // 补贴类别

      cities: [
        { value: "农户", label: "2" },
        { value: "家庭农场", label: "41" },
        { value: "农业企业", label: "42" },
        { value: "专业合作社", label: "43" },
        { value: "集体经营组织", label: "3" },
      ], // 服务对象
      typeindex: 0, //  0新增 1修改
      isSaving: false, // 用于防止重复请求
      pickerOptions1: {
          //开始日期的校验
          disabledDate: (time) => {
            // 获取当前日期并将时间调整为 00:00:00
            const today = new Date().setHours(0, 0, 0, 0);
            // 获取要比较的日期并将时间调整为 00:00:00
            const compareDate = new Date(time).setHours(0, 0, 0, 0);
            return (
              compareDate < today ||
              time.getTime() > new Date(this.applyStartTime).getTime()
            );
          },
        },
        pickerOptions2: {
          //结束日期的校验
          disabledDate: (time) => {
            // 获取当前日期并将时间调整为 00:00:00
            const today = new Date().setHours(0, 0, 0, 0);
            // 获取要比较的日期并将时间调整为 00:00:00
            const compareDate = new Date(time).setHours(0, 0, 0, 0);
            return (
              compareDate < today ||
              time.getTime() < new Date(this.applyEndTime).getTime()
            );
          },
        },
    };
  },
  computed: mapGetters(["userInfo", "identityObj"]),
  created() {
    this.getData();
    this.type();
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.applyEndTime = ""; // 补贴申请结束时间
        this.applyStartTime = ""; // 补贴申请开始时间
        this.serviceType = []; // 服务对象
        this.subsidyRequire = ""; // 补贴要求
        this.subsidyType = ""; // 补贴类型
        this.subsidyId = ""; // 补贴项目编号
      }
    },
  },
  methods: {
    async getData() {
      let res = await selectBackSubsidyTypeList();
      if (res.code === 0) {
        this.tableData = res.data;
      }
    },
    async type() {
      let res = await subsidytype();
      if (res.code === 0) {
        this.options = res.data;
      }
    },
    // 新增补贴项目
    add() {
      this.dialogVisible = true;
      this.typeindex = 0;
      this.type();
    },
    // 修改补贴项目
    edit(row) {
      this.dialogVisible = true;
      this.typeindex = 1;
      this.type();
      // this.form = row;
      console.log(row, row.serviceType.split(","));
      this.applyEndTime = row.applyEndTime;
      this.applyStartTime = row.applyStartTime;
      this.serviceType = row.serviceType.split(",");
      this.subsidyRequire = row.subsidyRequire;
      this.subsidyType =
        row.subsidyType
        //  == 1
        //   ? "种粮大户补贴"
        //   : row.subsidyType == 2
        //   ? "粮食价格补贴"
        //   : row.subsidyType == 3
        //   ? "农机购置补贴"
        //   : row.subsidyType == 4
        //   ? "社会化服务补贴"
        //   : "";
      this.subsidyId = row.subsidyId;
    },

    // 启用-停用
    enabled(row) {
      this.$confirm("确认启用该项目？")
        .then(() => {
          this.updateStatus(row.subsidyId, 0);
        })
        .catch(() => {});
    },
    noSelect(row) {
      this.$confirm("确认停用该项目？")
        .then(() => {
          this.updateStatus(row.subsidyId, 1);
        })
        .catch(() => {});
    },
    async updateStatus(row, index) {
      if (index == 0) {
        let res = await deleteSubsidyType({
          delFlag: 0,
          subsidyId: row,
        });
        if (res.code === 0) {
          this.$message.success("启用成功");
          this.getData();
        } else {
          this.$message.error("启用失败");
        }
      } else {
        let res = await deleteSubsidyType({
          delFlag: 1,
          subsidyId: row,
        });
        if (res.code === 0) {
          this.$message.success("停用成功");
          this.getData();
        } else {
          this.$message.error("停用失败");
        }
      }
      this.getData();
    },

    // 保存补贴项目
    async handleSave() {
      if (this.isSaving) return; // 如果正在保存，则退出

      this.isSaving = true; // 开始保存
      try {
        // 检查必传字段
        if (
          !this.applyEndTime ||
          !this.applyStartTime ||
          !this.serviceType.length ||
          !this.subsidyRequire ||
          !this.subsidyType
        ) {
          this.$message.error("请确保所有必填项都已填写");
          return;
        }

        let res;
        if (this.typeindex === 0) {
          res = await insertSubsidyType({
            applyEndTime: this.applyEndTime,
            applyStartTime: this.applyStartTime,
            serviceType: this.serviceType.join(","),
            subsidyRequire: this.subsidyRequire,
            subsidyType: this.subsidyType,
          });
        } else {
          // this.subsidyType = this.subsidyType == "种粮大户补贴" ? 1 : this.subsidyType == "粮食价格补贴" ? 2 : this.subsidyType == "农机购置补贴" ? 3 : this.subsidyType == "社会化服务补贴" ? 4 : "";
          res = await updateSubsidyType({
            applyEndTime: this.applyEndTime,
            applyStartTime: this.applyStartTime,
            serviceType: this.serviceType.join(","),
            subsidyRequire: this.subsidyRequire,
            subsidyType: this.subsidyType,
            subsidyId: this.subsidyId,
          });
        }
        console.log(this.subsidyType);

        if (res.code === 0) {
          this.$message.success(this.typeindex === 0 ? "添加成功" : "修改成功");
          this.dialogVisible = false;
          this.getData();
        } else {
          this.$message.error(this.typeindex === 0 ? "添加失败" : "修改失败");
        }
      } catch (error) {
        this.$message.error("操作失败，请稍后再试");
        console.error(error); // 输出错误信息
      } finally {
        this.isSaving = false; // 结束保存，无论结果如何，都要重置状态
      }
    },

    // 关闭新增补贴项目
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.ybmxm {
  text-align: left;
  height: 100%;
  position: relative;
  // margin-top: 46px;

  .s-title {
    .title {
      margin-left: 10px;
      font-size: 15px;
      font-weight: 600;
    }
  }
  .project {
    // margin-top: 38px;
    .table-con {
      margin-top: 12px;
      padding: 0 15px 0 18px;
    }
  }
  .news {
    margin-top: 33px;
  }
  .pagenation {
    // margin-top: 0;
    // position: absolute;
    // right: 20px;
    // bottom: 33px;
    text-align: right;
  }
}
.query-form {
  display: flex;
  .el-form-item {
    display: flex;
    align-items: center;
    .el-form-item__content {
      width: 100px;
    }
  }
  .query-btn {
    width: 70px;
  }
}
</style>

<style lang="less">
.ybmxm {
  .el-dialog__wrapper {
    position: absolute;
  }
  .v-modal {
    position: absolute;
  }
  .el-checkbox-button__inner {
    width: 160px;
    border-left: 1px solid #dcdfe6;
  }
  .el-dialog--center .el-dialog__body {
    padding: 25px 60px 30px 38px;
  }
  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    border-radius: 5px;
    border: solid 1px #00a0e9;
    background: #fff;
    color: #00a0e9;
  }
  .el-dialog__footer {
    padding: 10px 20px 45px;
  }
}
.disUoloadSty .el-upload--picture-card {
  display: none; /* 上传按钮隐藏 */
}
</style>
