export const wdbtsqColumn = [
  {
    prop: "subsidyName",
    label: "名称",

  },
  {
    prop: "applyUser",
    label: "申请人",
  },

  {
    prop: "createTime",
    label: "申请时间",
  },
  {
    prop: "declareStatus",
    label: "审核状态",
    template: true
  },
  {
    prop: "orderState",
    label: "操作",
    // width: 200,
    template: true
  },
]
export const zldhbtsqColumn = [
  {
    prop: "typeFarmerName",
    label: "经营主体",
    // template: true,
    width: 150,
  },
  {
    prop: "serialNumber",
    label: "序号",
    tooltip: false,
  },

  {
    prop: "applyUser",
    label: "姓名/单位",
    template: true,
  },
  {
    prop: "declareAreaName",
    label: "种粮地点",
  },
  {
    prop: " idCard",
    label: "身份证号/机构代码",
  },
  {
    prop: "bankName",
    label: "开户行",
  },
  {
    prop: "bankCard",
    label: "银行账户",
  },
  {
    prop: "flowState",
    label: "耕地面积(亩)",
    children: [
      {
        prop: 'sum1',
        label: '合计',

      },
      {
        prop: 'contractArea',
        label: '承包耕地',

      }, {
        prop: 'rentalArea',
        label: '租种耕地',
      }
    ]
  },
  {
    prop: "estimatePrice",
    label: "种植面积(亩)",
    children: [
      {
        prop: 'sum2',
        label: '合计',
      },
      {
        prop: 'riceArea',
        label: '水稻',

      }, {
        prop: 'cornArea',
        label: '玉米',
      },
      {
        prop: 'wheatArea',
        label: '小麦',
      }
    ]
  },
  {
    prop: "totalSubsidyArea",
    label: "核实补贴面积(亩)",
  },
  {
    prop: "phone",
    label: "联系电话",
  },
  {
    prop: "orderState",
    label: "操作",
    width: 300,
    template: true,

  },
]
export const subsidyListColumn = [
  {
    prop: "applyUser",
    label: "姓名",
  },
  {
    prop: "address",
    label: "地址",
  },

  {
    prop: "idCard",
    label: "身份证号",
  },
  {
    prop: "agriculturalMachineryType",
    label: "农机类型",
  },
  {
    prop: "agriculturalMachineryManufacturer",
    label: "生产厂商",
  },
  {
    prop: "agriculturalMachineryModel",
    label: "农机型号",
  },
  {
    prop: "agriculturalMachineryPrice",
    label: "价格",
  },
  {
    prop: "orderState",
    label: "操作",
    width: 300,
    template: true
  },
]
// 种粮大户补贴详情
export const grainSubsidyColumn = [
  {
    prop: "xmdz",
    label: "区域",
  },
  {
    prop: "cqlxbh2",
    label: "土地类型",
  },

  {
    prop: "crf",
    label: "流出方",
  },
  {
    prop: "flowAreaMu",
    label: "面积(亩)",
  },
  {
    prop: " fbsj",
    label: "开始时间",
    width: 80,
  },
  {
    prop: "endTime",
    label: "结束时间",
    width: 140,
  },

  {
    prop: "operation",
    label: "操作",
    width: 280,
    template: true,
  },

]
//社会化补贴服务化地块详情
export const socialAllowanceColumn = [
  {
    prop: "areaName",
    label: "区域",
  },
  {
    prop: "orderType",
    label: "托管类型",
    width: 80,
    template: true,
  },
  {
    prop: "cropCode",
    label: "作物类型",
  },
  {
    prop: "takeOverName",
    label: "托管人",
  },
  {
    prop: "landArea",
    label: "面积(亩)",
  },

  {
    prop: "createTime",
    label: "发布时间",
    width: 140,
    template: true
  },
  {
    prop: "Time",
    label: "托管时间",
    width: 140,
    template: true
  },
  {
    prop: "operation",
    label: "操作",
    width: 280,
    template: true,
  },
]

