export const dbspColumn = [
  // {
  //   prop: "processId",
  //   label: "审批号",
  //   width: 100,
  // },
  {
    prop: "processName",
    label: "流程名称",
  },

  {
    prop: "taskName",
    label: "任务名称",
  },
  {
    prop: "startAssignee",
    label: "发起人",
  },
  {
    prop: "startTime",
    label: "任务创建时间",
    width: 200,

  },
  // {
  //   prop: "processSchedule",
  //   label: "流程进度",
  //   width: 140,

  // },

  {
    prop: "operation",
    label: "操作",
    width: 280,
    template: true,
  },
]
export const dbspColumn1 = [
  // {
  //   prop: "processId",
  //   label: "审批号",
  //   width: 100,
  // },
  {
    prop: "processName",
    label: "流程名称",
  },

  {
    prop: "taskName",
    label: "任务名称",
  },
  {
    prop: "startAssignee",
    label: "发起人",
  },
  {
    prop: "startTime",
    label: "任务创建时间",
    width: 200,

  },
  {
    prop: "processSchedule",
    label: "流程进度",
    width: 140,

  },

  {
    prop: "operation",
    label: "操作",
    width: 280,
    template: true,
  },
]
export const ProcessRecordColumn = [
  {
    prop: "taskName",
    label: "任务名称",
    // width: 80,
  },
  {
    prop: "assignee",
    label: "办理人",
  },

  {
    prop: "startTime",
    label: "任务开始时间",
  },
  {
    prop: "endTime",
    label: "任务完成时间",
  },

]

