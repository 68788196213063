<template>
  <card title="交易大厅">
    <div class="content">
      <el-tabs
        class="eltabsStyle"
        v-model="activeName"
        @tab-click="handleTypeClick"
      >
        <el-tab-pane
          v-for="(item, index) in menuItems"
          :key="index"
          :label="item.name"
          :name="item.id"
        >
          <el-table
            ref="multipleTable"
            :data="tableData"
            v-loading="loading"
            element-loading-text="加载中"
            height="650px"
            :stripe="true"
            :header-row-style="{
              'font-size': '16px',
              'font-family': 'Source Han Sans CN',
              'font-weight': '400',
              color: '#333333',
            }"
            :header-cell-style="{ 'text-align': 'center' }"
            :cell-style="{ 'text-align': 'center' }"
            :row-style="{ height: '59px' }"
          >
            <el-table-column prop="xmbh" label="项目编号" width="250">
            </el-table-column>
            <el-table-column prop="xmmc" label="项目名称" width="300">
            </el-table-column>
            <!-- <el-table-column prop="jzsj" label="报名截止时间">
            </el-table-column> -->
            <el-table-column prop="fbsj" label="登记时间"> </el-table-column>

              <el-table-column prop="bmycsj" label="竞价截止时间" width="160px"  v-if="cqlxbh == '01'">
                <template slot-scope="scope"
                  ><span>{{
                    scope.row.bmycsj ? scope.row.bmycsj : "——————"
                  }}</span></template
                >
              </el-table-column>


            <el-table-column
              align="center"
              prop="gpjg"
              width="180px"
              label="挂牌价格"
            >
              <template slot-scope="scope"
                ><span>{{
                  scope.row.gpjg ? scope.row.gpjg + "元" : ""
                }}</span></template
              >
            </el-table-column>

            <el-table-column prop="dqbj" label="当前报价" width="100" v-if="cqlxbh == '01'">
              <template slot-scope="scope">
                <span>{{
                  scope.row.dqbj ? scope.row.dqbj + "元" : "—————"
                }}</span>
              </template>
            </el-table-column>


            <el-table-column label="操作">
              <template slot-scope="scope">
                <!-- 查看按钮 -->
                <el-button
                  style="width: 46px; height: 28px; padding: 0 8px"
                  type="primary"
                  @click="showDetail(scope.row)"
                  >查看</el-button
                >
                <!-- 竞价按钮 -->
                <el-button
                  v-if="scope.row.jyfs == '在线竞价交易'"
                  @click="bidding(scope.row)"
                  type="success"
                  size="mini"
                  >竞价
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <!-- 分页查询 -->
          <!-- <div class="pages">
            <el-pagination
              background
              layout="total, prev, pager, next"
              :current-page="params.pnum"
              :page-size="params.psize"
              :total="total"
              @current-change="currentChange"
            >
            </el-pagination>
          </div> -->
          <div class="pages">
            <!-- <el-pagination
              background
              layout="prev, pager, next"
              :current-page="params.pnum"
              :page-size="params.psize"
              :total="total1"
              @current-change="currentChange"
            >
            </el-pagination> -->
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage4"
              :page-sizes="[10, 20, 50]"
              :page-size="100"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total1"
              prev-text="上一页"
              next-text="下一页"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 加价多少的弹窗 -->
    <bidding-dialog
      v-if="dialogVisible"
      :dialogVisible.sync="dialogVisible"
      :data="currentBid"
      @close="dialogClose"
      @submit="submit"
    ></bidding-dialog>
  </card>
</template>

<script>
import Card from "@/components/Card/index.vue";
import { mapGetters } from "vuex";
import { getStore } from "@/js/utils/store";
import biddingDialog from "../../projectManagement/components/biddingDialog";
import { getPropertyType, getXmxxDetail, saveBidding } from "@/api/exchange";
import { getDtBidInfo, getBiddingXmxxByPg } from "@/api/projectManage";

export default {
  components: {
    Card,
    biddingDialog,
  },
  data() {
    return {
      cqlxbh:'',
      params: {
        pnum: 1,
        psize: 10,
      },
      total1: 10,
      currentPage4: 1,

      activeName: "0",
      loading: false,
      menuItems: [],
      dialogVisible: false,
      currentBid: {},

      tableData: [],

      currentBidTab: {},
      biddingInterval: null, // 竞价信息轮询
      // biddingTypes: [],

      form: {
        area: "",
      },
    };
  },
  mounted() {
    this.initPropertyType();
  },
  computed: mapGetters(["userInfo", "topSelectAreacode"]),
  watch: {
    topSelectAreacode(val) {
      this.handleTypeClick(this.currentBidTab);
    },
  },
  methods: {
    handleCurrentChange(val) {
      this.params.pnum = val;
      this.handleTypeClick(this.currentBidTab);
      console.log("----------------------",val);
    },
    handleSizeChange(val) {
      this.params.psize = val;
      console.log(`每页 ${val} 条`);
      this.handleTypeClick(this.currentBidTab);
    },
    handleCurrentChange(val) {
      this.params.pnum = val;
      console.log(`当前页: ${val}`);
      this.handleTypeClick(this.currentBidTab);
    },

    //切换菜单标签页
    // handleTabClick(tab, event) {},

    async initPropertyType() {
      // 初始化产权类型
      let res = await getPropertyType();
      let { status, data } = res;
      if (status) {
        data.forEach((element) => {
          // if (element.bh !== "12" && element.bh !== "13") {     // 去掉最后面两个

          this.menuItems.push({
            id: element.bh,
            name: element.lx,
          });
          // }
        });
        this.activeName = data[0].bh;
        this.handleTypeClick(data[0]);
      }
    },

    async handleTypeClick(tab) {
      // 网络竞价大厅切换
      this.currentBidTab = tab;
      this.loading = true;
      let params = {
        // keyword: "",
        xzqbh: this.topSelectAreacode,
        // xmbh: "",
        cqlx: tab.name || tab.bh,
        jjzt: "1",
        current: this.params.pnum,
        size: this.params.psize,
      };
      let res = await getBiddingXmxxByPg(params);
      let { status, data, message } = res;
      if (status) {
        if(data.records.length >0){
             this.tableData = data.records;
        this.cqlxbh=this.tableData[0].cqlxbh
        this.total1 = Number(data.total);
        console.log("tableData", this.tableData);
        console.log(this.total1);
        }else{
          this.tableData=[];
          // this.params.psize=0;
          this.total1=0
        }

        // setTimeout(this.setBiddingInterval, 100000);
      }
      this.loading = false;
    },
    setBiddingInterval() {
      if (this.biddingInterval) {
        clearInterval(this.biddingInterval);
      }
      let that = this;
      async function change() {
        let params = {
          xzqbh: "",
          cqlxbh: that.currentBidTab.name || that.currentBidTab.bh,
          zdmj: that.form.maxArea,
          pnum: 1,
          psize: 10,
        };
        let res = await getDtBidInfo(params);
        let { status, data, dataNum } = res;
        if (status) {
          that.tableData = data;
          that.total = dataNum;
        }
      }
      // that.biddingInterval = setInterval(change, 5000);
    },

    // 竞价
    async bidding(row) {
      // 竞价
      let token = getStore({ name: "access_token" });
      if (token) {
        // 竞价
        let res = await getXmxxDetail({ xmbh: row.xmbh });
        let { status, data } = res;
        if (status == 200) {
          this.currentBid = data;
        }
        this.dialogVisible = true;
      } else {
        this.$confirm("您需要登陆才可以竞价，是否跳转到登陆页?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            localStorage.setItem("backPath", this.$route.fullPath);
            this.$router.push({ name: "login" });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },

    showDetail(row) {
      // 查看项目详情
      this.$router.push({ name: "projectDetail", query: { c: row.xmbh } });
      // let token = getStore({ name: 'access_token' })
      // if (token) {
      // 	this.$router.push({ name: 'projectDetail', query: { c: row.xmbh } })
      // } else {
      // 	this.$confirm('您需要登陆才可以查看项目详情，是否跳转到登陆页?', '提示', {
      // 		confirmButtonText: '确定',
      // 		cancelButtonText: '取消',
      // 		type: 'warning',
      // 	})
      // 		.then(() => {
      // 			localStorage.setItem('backPath', this.$route.fullPath)
      // 			this.$router.push({ name: 'login' })
      // 		})
      // 		.catch(() => {
      // 			this.$message({
      // 				type: 'info',
      // 				message: '已取消',
      // 			})
      // 		})
      // }
    },

    dialogClose() {
      this.dialogVisible = false;
      this.handleTypeClick(this.currentBidTab);
    },

    async submit(val, offer) {
      // 竞价信息提交
      let total = 0;
      if (offer == 0 || !offer) {
        total = parseFloat(val) + parseFloat(this.currentBid.gpjg);
      } else {
        total = parseFloat(val) + parseFloat(this.currentBid.dqbj);
      }
      let params = {
        xmbh: this.currentBid.xmbh,
        amount: total,
      };

      let res = await saveBidding(params);
      let { status, data, message } = res;
      if (status == 200) {
        this.$message.info(message);
        this.dialogVisible = false;
        this.handleTypeClick(this.currentBidTab);
      } else {
        this.$message.error(message);
        console.log(message);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  height: 100%;
  .eltabsStyle {
    ::v-deep .el-tabs__header {
      margin: 0;

      height: 84px;
      background: #0668b3;
    }
    ::v-deep .el-tabs__nav-wrap {
      padding: 0 46px;
      height: 100%;
      text-align: center;
      align-items: center;
    }

    ::v-deep .el-tabs__nav-scroll {
      height: 100%;
      text-align: center;
      align-items: center;
    }

    ::v-deep .el-tabs__nav {
      height: 100%;
      text-align: center;
      align-items: center;
    }
    ::v-deep .el-tabs__item {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #cdecfa;
      padding: 0 35px;
      line-height: 84px;
    }

    ::v-deep .el-tabs__item.is-active {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      padding: 0 54px;
      line-height: 84px;
    }

    //底部蓝色划线改为白色
    ::v-deep .el-tabs__active-bar {
      //display: none;
      background-color: #ffffff !important;

      position: relative;
      top: 80%;
      left: 0;
    }

    //去掉tabs底部下划线
    ::v-deep .el-tabs__nav-wrap::after {
      position: static !important;
    }

    ::v-deep .el-icon-arrow-left {
      position: relative;
      top: 15%;
      left: 1%;
      color: #ffffff;
    }

    ::v-deep .el-icon-arrow-right {
      position: relative;
      top: 15%;
      left: 1%;
      color: #ffffff;
    }
    ::v-deep .el-tabs__nav-prev {
      height: 24px;
      width: 24px;
      border: 1px solid #dcdfe6;
      line-height: 16px;
      color: #fff;
      border-radius: 50%;

      margin: 34px 0 0 10px;
    }
    ::v-deep .el-tabs__nav-next {
      height: 24px;
      width: 24px;
      border: 1px solid #dcdfe6;
      line-height: 16px;
      color: #fff;
      border-radius: 50%;

      margin: 34px 10px 0 0;
    }

    ::v-deep .el-table__header {
      height: 60px;

      //border-bottom: 1px dashed #E0E0E0;
    }
    ::v-deep .el-table td .el-table th {
      height: 60px;
    }

    ::v-deep .el-table__row--striped td {
      background: #eef6f6 !important;
    }
  }
}

.pages {
  margin-top: 16px;
  text-align: right;
}
</style>
