<!-- 涉农补贴 -->
<template>
  <div class="snbt">
    <!--补贴项目列表页面 -->
    <!-- 弹窗 -->
    <el-dialog :visible.sync="dialogVisible" width="30%">
      <div class="dialogContent">
        <div class="dialogTitle"> <span>{{userNotice.newUserTitle}}</span> </div>
        <p> <span class="tit">补贴对象：</span> <span class="cont">{{userNotice.newSubsidyType}}</span> </p>
        <p> <span class="tit">申请时间：</span> <span
            class="cont">{{userNotice.applyStartTime}}——{{userNotice.applyEndTime}}</span> </p>
        <div>
          <span class="tit">补贴要求：</span>
          <div>
            <p class="cont">{{userNotice.subsidyRequire}}</p>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <img src="../../assets/banner-1.png" alt="">
    <div class="subsidyBox" v-if="subsidyTypeList.length">
      <div class="contentItem" v-for="(item,index) in subsidyTypeList" :key="index">
        <div class="innerBox">
          <img src="../../assets/subsidy.png" />
          <span class="tit">{{item.nowYear}}</span>
          <span class="tit">{{item.description}}</span>

          <div class="content">
            <span class="tit2">补贴对象：</span>
            <div class="cont">
              <!-- <span v-for="(item,index) in item.serviceType2" :key="index">
                  {{ item }}、
              </span> -->
              <span>{{item.serviceType2}}</span>
            </div>
          </div>

          <div class="content">
            <span class="tit2">申请时间：</span>
            <div class="cont">
              <span class="time">{{item.applyStartTime}} 至 {{item.applyEndTime}}</span>
            </div>
          </div>
          <div class="Btn">
            <el-button type="text" @click="TipsBtn(item.twActivitySubsidyTypeVo)">申报须知</el-button>
            <el-button type="primary" @click="applySubsidy(item)">立即申报</el-button>
          </div>
        </div>

      </div>
    </div>
    <div class="subsidyBox2" v-else>
      <el-empty description="暂无数据"></el-empty>
    </div>

  </div>
</template>
<script>

  import { mapGetters } from "vuex";
  import { selectFrontSubsidyTypeList } from '@/api/subsidy'
  import { subsidytype } from '@/api/snbt'
  export default {
    data() {
      return {
        dialogVisible: false,
        subsidyTypeList: [],
        subsidyTypes: [],
        userNotice: {},
        types: [{
          label: "集体经济组织",
          value: "3",
        },
        {
          label: "家庭农场",
          value: "41",
        },
        {
          label: "农业企业",
          value: "42",
        },
        {
          label: "专业合作社",
          value: "43",
        }, {
          label: "农户",
          value: "2",
        },

        ], // 主体类型
      };

    },
    watch: {
      topSelectAreacode: function () {
        this.getSubsidyTypeList();
      },
    },
    computed: {
      ...mapGetters(["identityObj", 'topSelectAreacode']),
    },
    mounted() {
      this.getSubsidyTypeList()
      // this.getSubsidyType()
    },

    methods: {
      // 获取补贴类型列表
      async getSubsidyTypeList() {
        let params = {
          areaCode: this.topSelectAreacode ? this.topSelectAreacode : null
        }
        let res = await selectFrontSubsidyTypeList(params)
        if (res.code == 0) {
          //获取项目类型
          let res2 = await subsidytype()
          if (res2.code == 0) {
            this.subsidyTypes = res2.data
            let newData = res.data.map((item, index) => {
              let newServiceType = item.serviceType.split(',');
              console.log(this.subsidyTypes, 'this.subsidyTypes')
              let newType2 = this.subsidyTypes.find(its => item.subsidyType == its.value).description
              let newType = newServiceType.filter(its => this.types.find(type => type.value === its)).map(its => this.types.find(type => type.value === its).label); // 使用 find 来确认类型是否存在
              return {
                ...item,
                serviceType2: newType.join('、'), // 用逗号和空格连接.join(', ')
                description: newType2
              }
            })
            this.subsidyTypeList = newData
          }

        }
      },
      // 获取补贴类型列表
      // async getSubsidyType() {

      // },
      // 用户须知
      TipsBtn(obj) {
        let newTypes = obj.serviceType.split(',')
        let newUserTitle = this.subsidyTypes.find(its => obj.subsidyType == its.value).description
        let newSubsidyType = newTypes.filter(its => this.types.find(type => type.value === its)).map(its => this.types.find(type => type.value === its).label).join('、')
        obj.newUserTitle = newUserTitle
        obj.newSubsidyType = newSubsidyType
        this.userNotice = obj
        // 种粮大户补贴申报须知
        this.dialogVisible = true
      },
      // 申报
      applySubsidy(obj) {
        console.log()
        if (!this.identityObj.identityTypeCode) {
          this.$message.info("请登录后进行操作");
          return;
        }

        // 获取今天的日期
        const today = new Date();
        // today.setHours(0, 0, 0, 0); // 设置时间为当天的00:00:00.000
        const applyStartTime = new Date(obj.applyStartTime);
        applyStartTime.setHours(0, 0, 0, 0);
        const applyEndTime = new Date(obj.applyEndTime);
        // console.log(applyEndTime.getHours(), 'applyEndTime')
        if (applyEndTime.getHours() == 8) {
          applyEndTime.setHours(0, 0, 0, 0); // 设置为当天的00:00:00.000
          applyEndTime.setDate(applyEndTime.getDate() + 1); // 加一天，变为第二天的00:00:00.000
        }
        // console.log(today, 'today')
        // console.log(applyStartTime, 'applyStartTime')
        // console.log(applyEndTime, 'applyEndTime')

        // 检查今天的日期是否在申请时间范围内
        if (today >= applyStartTime && today <= applyEndTime) {
          // 今天在范围内，继续执行后续操作
          //项目的补贴的类型
          let subsidyType = obj.subsidyType
          //项目服务的人群
          let serviceTypeList = obj.serviceType.split(',');
          //登录人的身份类别
          let identityTypeCode = this.identityObj.identityTypeCode

          //种粮大户补贴
          if (subsidyType == "1") {
            if (identityTypeCode == "4") {
              const name = this.identityObj.identityName;
              const substring = "专业合作社";
              const substring2 = "家庭农场"
              if (name.includes(substring)) {
                let isTrue = serviceTypeList.includes('43')

                if (isTrue) {
                  this.$router.push({
                    path: '/btsqtx/grainSubsidy', query: {
                      // objType:objType
                      subsidyId: obj.subsidyId,
                      subsidyType: obj.subsidyType,
                      typeItemId: '2'
                    }
                  })
                } else {
                  this.$message.info("您暂无权限申请此补贴，请选择其他补贴项目")
                }


              } else if (name.includes(substring2)) {
                let isTrue = serviceTypeList.includes('41')

                if (isTrue) {
                  this.$router.push({
                    path: '/btsqtx/grainSubsidy', query: {
                      // objType:objType
                      subsidyId: obj.subsidyId,
                      subsidyType: obj.subsidyType,
                      typeItemId: '3'
                    }
                  })
                } else {
                  this.$message.info("您暂无权限申请此补贴，请选择其他补贴项目")
                }
              }
              else {
                let isTrue = serviceTypeList.includes('42')
                console.log('应该执行的是这里')
                if (isTrue) {
                  this.$router.push({
                    path: '/btsqtx/grainSubsidy', query: {
                      // objType:objType
                      subsidyId: obj.subsidyId,
                      subsidyType: obj.subsidyType,
                      typeItemId: '3'
                    }
                  })
                } else {
                  this.$message.info("您暂无权限申请此补贴，请选择其他补贴项目")
                }
              }
            } else {
              let isTrue = serviceTypeList.includes(identityTypeCode)
              if (!isTrue) {
                this.$message.info("您暂无权限申请此补贴，请选择其他补贴项目")
                return
              }

              this.$router.push({
                path: '/btsqtx/grainSubsidy', query: {
                  // objType:objType
                  subsidyId: obj.subsidyId,
                  subsidyType: obj.subsidyType,
                }
              })
            }
          }
          // 社会化服务补贴
          else if (subsidyType == "4") {
            if (identityTypeCode == "4") {

              const name = this.identityObj.identityName;
              const substring = "专业合作社";
              const substring2 = "家庭农场"
              //判断名字是否包含substring或substring2
              if (name.includes(substring)) {
                let isTrue = serviceTypeList.includes('43')
                if (isTrue) {
                  this.$router.push({
                    path: '/btsqtx/socialAllowance', query: {
                      // objType:objType
                      subsidyId: obj.subsidyId,
                      subsidyType: obj.subsidyType,
                    }
                  })
                } else {
                  this.$message.info("您暂无权限申请此补贴，请选择其他补贴项目")
                }

              } else if (name.includes(substring2)) {
                let isTrue = serviceTypeList.includes('41')
                if (isTrue) {
                  this.$router.push({
                    path: '/btsqtx/socialAllowance', query: {
                      // objType:objType
                      subsidyId: obj.subsidyId,
                      subsidyType: obj.subsidyType,
                    }
                  })
                } else {
                  this.$message.info("您暂无权限申请此补贴，请选择其他补贴项目")
                }
              } else {
                let isTrue = serviceTypeList.includes('42')
                if (isTrue) {
                  this.$router.push({
                    path: '/btsqtx/socialAllowance', query: {
                      // objType:objType
                      subsidyId: obj.subsidyId,
                      subsidyType: obj.subsidyType,
                    }
                  })
                } else {
                  this.$message.info("您暂无权限申请此补贴，请选择其他补贴项目")
                }

              }
            } else {

              let isTrue = serviceTypeList.includes(identityTypeCode)
              if (!isTrue) {
                this.$message.info("您暂无权限申请此补贴，请选择其他补贴项目")
                return
              }

              this.$router.push({
                path: '/btsqtx/socialAllowance', query: {
                  // objType:objType
                  subsidyId: obj.subsidyId,
                  subsidyType: obj.subsidyType,
                }
              })
            }

          }
          // 农机购置补贴
          else if (subsidyType == "3") {

            if (this.identityObj.identityTypeCode == 4) {
              const name = this.identityObj.identityName;
              const substring = "专业合作社";
              const substring2 = "家庭农场"
              //判断名字是否包含substring或substring2
              if (name.includes(substring)) {
                let isTrue = serviceTypeList.includes('43')

                // 申报类型包含41或42
                if (isTrue) {
                  this.$router.push({
                    path: '/btsqtx/farmMachinery', query: {
                      // objType:objType
                      subsidyId: obj.subsidyId,
                      subsidyType: obj.subsidyType,
                    }
                  })
                } else {
                  this.$message.info("您暂无权限申请此补贴，请选择其他补贴项目")
                }
              } else if (name.includes(substring2)) {
                let isTrue = serviceTypeList.includes('41')
                if (isTrue) {
                  this.$router.push({
                    path: '/btsqtx/farmMachinery', query: {
                      // objType:objType
                      subsidyId: obj.subsidyId,
                      subsidyType: obj.subsidyType,
                    }
                  })
                } else {
                  this.$message.info("您暂无权限申请此补贴，请选择其他补贴项目")
                }
              } else {
                let isTrue = serviceTypeList.includes('42')
                if (isTrue) {
                  this.$router.push({
                    path: '/btsqtx/farmMachinery', query: {
                      // objType:objType
                      subsidyId: obj.subsidyId,
                      subsidyType: obj.subsidyType,
                    }
                  })
                } else {
                  this.$message.info("您暂无权限申请此补贴，请选择其他补贴项目")
                }
              }



            } else {
              let isTrue = serviceTypeList.includes(identityTypeCode)
              if (!isTrue) {
                this.$message.info("您暂无权限申请此补贴，请选择其他补贴项目")
                return
              }
              this.$router.push({
                path: '/btsqtx/farmMachinery', query: {
                  // objType:objType
                  subsidyId: obj.subsidyId,
                  subsidyType: obj.subsidyType,
                }
              })
            }

          }
          // 粮食价格补贴
          else if (subsidyType == "2") {
            if (this.identityObj.identityTypeCode == 4) {
              const name = this.identityObj.identityName;
              const substring = "专业合作社";
              const substring2 = "家庭农场"
              //判断名字是否包含substring或substring2
              if (name.includes(substring)) {
                let isTrue = serviceTypeList.includes('43')

                // 申报类型包含41或42
                if (isTrue) {
                  this.$router.push({
                    path: this.identityObj.identityTypeCode == 3 ? "/memberCenter/snbt/btsb-c" : "/memberCenter/snbt/btsb-z",
                    query: { title: obj.subsidyName, subsidyId: obj.subsidyId, subsidyType: obj.subsidyType, status: "0", taskKey: '' },

                  })
                } else {
                  this.$message.info("您暂无权限申请此补贴，请选择其他补贴项目")
                }
              } else if (name.includes(substring2)) {
                let isTrue = serviceTypeList.includes('41')
                if (isTrue) {
                  this.$router.push({
                    path: this.identityObj.identityTypeCode == 3 ? "/memberCenter/snbt/btsb-c" : "/memberCenter/snbt/btsb-z",
                    query: { title: obj.subsidyName, subsidyId: obj.subsidyId, subsidyType: obj.subsidyType, status: "0", taskKey: '' },

                  })
                } else {
                  this.$message.info("您暂无权限申请此补贴，请选择其他补贴项目")
                }
              } else {
                let isTrue = serviceTypeList.includes('42')
                if (isTrue) {
                  this.$router.push({
                    path: this.identityObj.identityTypeCode == 3 ? "/memberCenter/snbt/btsb-c" : "/memberCenter/snbt/btsb-z",
                    query: { title: obj.subsidyName, subsidyId: obj.subsidyId, subsidyType: obj.subsidyType, status: "0", taskKey: '' },

                  })
                } else {
                  this.$message.info("您暂无权限申请此补贴，请选择其他补贴项目")
                }
              }
            } else {
              let isTrue = serviceTypeList.includes(identityTypeCode)
              if (!isTrue) {
                this.$message.info("您暂无权限申请此补贴，请选择其他补贴项目")
                return
              }
              this.$router.push({
                path: this.identityObj.identityTypeCode == 3 ? "/memberCenter/snbt/btsb-c" : "/memberCenter/snbt/btsb-z",
                query: { title: obj.subsidyName, subsidyId: obj.subsidyId, subsidyType: obj.subsidyType, status: "0", taskKey: '' },

              })
            }





          }
          //暂未配置
          else {
            this.$message.info("暂未配置此项目")
          }


        } else {
          // 今天不在范围内，提示用户
          this.$message.info("当前补贴项目已停止申报，请选择其他补贴项目。");
        }
      },

    }
  }
</script>

<style lang="less" scoped>
  .snbt {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    height: auto;

    img {
      width: 1500px;
      height: 200px;
    }

    .subsidyBox {
      width: 70%;
      height: auto;
      display: flex;
      flex-wrap: wrap;

      justify-content: flex-start;

      .contentItem {
        width: 33%;
        height: auto;
        display: flex;
        margin-top: 15px;
        margin-bottom: 15px;
        flex-direction: column;
        align-items: center;

        .innerBox {
          width: 220px;
          height: auto;
          display: flex;
          align-items: center;
          flex-direction: column;

          box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);

          .tit {
            font-size: 24px;
            font-weight: 800;
          }

          img {
            width: 90px;
            height: 100px;
          }

          .content {
            width: 100%;
            padding-top: 10px;
            padding-left: 10px;

            .tit2 {
              font-size: 18px;
              font-weight: 800;
              margin-top: 10px;
            }

            .cont {
              margin-top: 10px;
              min-height: 50px;
              height: auto;
            }

            span {
              font-size: 17px;
            }

            .time {
              font-size: 17px;
            }
          }

          .Btn {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: space-around;
            margin-bottom: 10px;
          }

        }
      }
    }


  }

  /deep/.el-dialog__header {
    background: #ffff !important;
    padding: 0 !important;
  }

  /deep/.dialogContent {

    .dialogTitle {
      display: flex;
      justify-content: center;
      font-size: 20px;
      font-weight: 800;
    }

    .tit {
      font-weight: 700;
      font-size: 17px;
    }

    .cont {
      font-size: 17px;

    }


  }
</style>