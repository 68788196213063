<template>
  <card title="快速入口">
    <leftDetail
      v-if="showDetail"
      @closeDialog1="closeDialog1"
      ref="showDetail"
    />
    <rightDetail
      v-if="showDetail2"
      @closeDialog2="closeDialog2"
      ref="showDetail2"
    />

    <div class="quick-link">
      <div
        class="link"
        :class="item.code"
        v-for="item of linkList"
        :key="item.code"
        @click="linkHandle(item)"
      >
        <p>{{ item.title }}</p>
        <el-button class="more-btn"
          >立即进入<img src="../../../assets/icon-arrow.png"
        /></el-button>
      </div>
    </div>

    <div class="new-area">
      <div class="banner">
        <el-carousel height="350px" :interval="4000">
          <el-carousel-item>
            <img src="../../../assets/banner-2.png" alt="" />
          </el-carousel-item>
          <el-carousel-item>
            <img src="../../../assets/banner-1.png" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <br>
      <button @click="uo">按钮</button>
      <br> -->
      <div class="new-list">
        <ul class="news-tab">
          <li
            v-for="item of newTab"
            :key="item.code"
            :class="item.code == currentCode ? 'active' : ''"
            @click="tabHandle(item)"
          >
            {{ item.name }}
          </li>
          <span class="more" @click="More">更多</span>
        </ul>

        <div class="new-des">
          <ul>
            <li
              v-for="(item, index) of news"
              :key="index"
              @click="showDetails(item)"
            >
              <div class="new-date">
                <span>{{ item.date ? item.date.substr(8, 2) : "" }}</span>
                <p>
                  {{ item.date ? item.date.substr(0, 4) : "" }}.{{
                    item.date ? item.date.substr(5, 2) : ""
                  }}
                </p>
              </div>
              <div class="new-title">
                <span>{{ item.title }}</span>
                <p class="des">

                  <span v-if="item.currentIdentity == 2 || item.type == 2">
                    信息来源：{{
                      item.from.substr(0, 1) +
                      (item.sex == "2" ? "女士" : "先生")
                    }}</span>

                  <span v-else>信息来源：{{ item.from }}</span>

                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import { mapGetters } from "vuex";
import Card from "@/components/Card/index.vue";
import rightDetail from "./SupplyTgRightDetail.vue";
import leftDetail from "./SupplyTgLeftDetail.vue";
import { selectNoticeType } from "@/api/CollectivePurchase";

import {
  getGpggPage,
  getCjggPage,
  getBiddingXmxxByPg,
} from "@/api/homeMessage";

import { getSupplys } from "@/api/add";

const linkList = [
  {
    code: "one",
    title: "生产服务",
    name: "productionService",
  },
  {
    code: "two",
    title: "资源资产服务",
    name: "resourcesService",
  },
  {
    code: "three",
    title: "财务服务",
    name: "financialService",
  },
];

const newTab = [
  {
    code: 1,
    name: "资源资产供应信息",
  },
  {
    code: 2,
    name: "资源资产公告公示",
  },
  {
    code: 3,
    name: "生产服务供需信息",
  },
  {
    code: 4,
    name: "农资集采公告公示",
  },
];

export default {
  components: {
    Card,
    rightDetail,
    leftDetail,
  },

  data() {
    return {
      showDetail2: false,
      showDetail: false,
      news: [],
      linkList,
      newTab,
      currentCode: 1,
      params: {
        current: 1,
        size: 4,
      },
    };
  },

  computed: mapGetters(["topSelectAreacode"]),
  mounted() {
    this.init();
  },
  watch: {
    topSelectAreacode(val) {
      this.tabHandle({ code: this.currentCode });
    },
  },
  methods: {
//     uo(){
// console.log("linkHandle",linkHandle);
//     },
    closeDialog1() {
      this.showDetail = false;
    },
    closeDialog2() {
      this.showDetail2 = false;
    },
    linkHandle(item) {
      this.$router.push({
        name: item.name,
      });
    },
    More() {
      // console.log(this.currentCode, " this.currentCode");
      // this.$router.push("/home/PublicAnnouncement");
      // if (this.currentCode == 1) {
      //   let abc = "";
      //   abc = "01";
        // this.$router.push({
        //   name: "exchange",
        //   query: { index: abc },
        // });
      // }
      switch(this.currentCode){
        case 1:
        this.$router.push({
          name: "exchange",
          query: { index: "01" },
        });
        break;
        case 2:
        this.$router.push({
          name: "announcement",
          query: { index: "1" },
        });
        break;
        case 3:
        this.$router.push({
          name: "landTrust",

        });
        break;
        case 4:
        this.$router.push("/home/PublicAnnouncement")
        break;
      }
    },
    async init() {
      let res = await getBiddingXmxxByPg({
        current: this.params.current,
        size: this.params.size,
        jjzt: "1",
        xzqbh: this.topSelectAreacode,
      });
      if (res.data.records) {
        this.news = this.resetData(res.data.records);
      }
    },

    async getTabTwo() {
      let params = {
        ...this.params,
        ...{
          //1
          xzqbh: this.topSelectAreacode,
        },
      };
      let res1 = await getCjggPage(params);
      let res2 = await getGpggPage(params);
      let res = [];
      if (res1.data.records && res1.data.records.length > 0) {
        res = res.concat(res1.data.records);
      }
      if (res2.data.records && res2.data.records.length > 0) {
        res = res.concat(res2.data.records).slice(0, 4);
      }
      this.news = this.resetData(res);
    },

    async getTabThree() {
      let params = {
        // size: 4,
        // current: 1,
        // orderState: "00",
        areaCode: this.topSelectAreacode,
      };
      let res = await getSupplys(params);
      if (res.code == 0) {
        let news = [];
        res.data.companyVos.forEach((item) => {
          news.push({
            ...item,
            detailType: 1,
            date: item.createTime,
            // title: item.address+'-'+item.companyName+'-'+item.serviceType == 1 ? "全程托管" : "环节托管"+'-'+item.serviceArea ? item.serviceArea : 0+'亩',
            title:
              item.areaName +
              (item.serviceType == 1 ? "全程托管" : "环节托管") +
              "-" +
              item.serviceScope +
              (item.serviceArea ? item.serviceArea:0) +
              "亩",
            // des: item.orderCode,
            from: item.companyName,
          });
        });
        res.data.orderVos.forEach((item) => {
          news.push({
            ...item,
            date: item.createTime,
            // title: item.address+'-'+item.companyName+'-'+item.serviceType == 1 ? "全程托管" : "环节托管"+'-'+item.serviceArea ? item.serviceArea : 0+'亩',
            title:
              item.areaName +
              "-" +
              (item.orderType == 1 ? "全程托管" : "环节托管") +
              "-" +
              item.cropCode +
              item.landArea +
              "亩",
            des: item.orderCode,
            from: item.farmerName,
          });
        });
        this.news = news;
      }
    },

    async getTabFour() {
      let params = {
        size: 4,
        current: 1,
        areaCode: this.topSelectAreacode,
      };
      let res = await selectNoticeType(params);

      if (res.code == 0 && res.data.total > 0) {
        let news = [];
        res.data.records.forEach((item) => {
          news.push({
            title: item.title,
            date: item.endDate ? item.endDate : item.createTime,
            id: item.id,
            CollectivePurchase: item.type ? item.type : "",
            currentCode: 4,
            from:item.operatorName
          });
        });
        this.news = news;
      }
    },

    resetData(data) {
      let newList = [];
      data.forEach((element, index) => {
        newList.push({
          code: index + 1,
          title: element.xmmc,
          date: element.jjjzsj
            ? element.jjjzsj
            : element.cjgskssj
            ? element.cjgskssj
            : element.ggkssj,
          // 2
          des: element.lxr,
          xmbh: element.xmbh,
          // 3
          from: element.lxr,
          sex: element.sex,
          currentIdentity: element.currentIdentity,
        });
      });

      return newList;
    },

    tabHandle(item) {
      console.log("item", item);
      this.news = [];
      this.currentCode = item.code;
      if (item.code == 1) {
        this.init();
      } else if (item.code == 2) {
        this.getTabTwo();
      } else if (item.code == 3) {
        this.getTabThree();
      } else if (item.code == 4) {
        this.getTabFour();
      }
    },
    detailClick(val) {
      this.showDetail = true;
      this.$nextTick(() => {
        this.$refs.showDetail.init(val, "生产服务供需详情");
      });
    },
    detailClick2(val) {
      this.showDetail2 = true;
      this.$nextTick(() => {
        this.$refs.showDetail2.init(val, "生产服务供需详情");
      });
    },
    detailClick4(val) {
      console.log(val);
      if (val.CollectivePurchase == 1) {
        console.log("这是集采报名");
        this.$router.push({
          name: "Preview",
          query: { data: val.id },
        });
      } else if (val.CollectivePurchase == 2) {
        console.log("这是招标");

        this.$router.push({
          name: "LookTenderNotice",
          query: { data: val.id },
        });
      } else {
        console.log("这是中标");
        this.$router.push({ name: "WinTheBid", query: { data: val.id } });
      }
    },
    // 项目详情
    showDetails(row) {
      if (this.currentCode == 3) {
        if (row.detailType == 1) {
          row.photos = row.phonts;
          this.detailClick(row);
        } else {
          this.detailClick2(row);
        }
      } else if (this.currentCode == 4) {
        this.detailClick4(row);
      } else {
        this.$router.push({ name: "projectDetail", query: { c: row.xmbh } });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.quick-link {
  display: flex;
  .link {
    flex: 1;
    background: #fafafa;
    border: 1px solid #f2f2f2;
    // margin: 0px 10px;
    text-align: center;
    padding: 150px 0px 40px 0;
    cursor: pointer;

    p {
      font-size: 24px;
      font-weight: bold;
    }

    .more-btn {
      width: 160px;
      height: 52px;
      border-radius: 0;
      background: #0668b3;
      color: #fff;
      font-size: 18px;
      border: 0;
    }

    &:hover {
      color: #fff;
      transition: 0.3s;
      .more-btn {
        background: #67c23a;
      }
    }

    &.one {
      background: url("../../../assets/icon_ql1.png") no-repeat center 40px;
      &:hover {
        background: url("../../../assets/icon_active_ql1.png") no-repeat center
          40px #0668b3;
      }
    }

    &.two {
      border-left: 0;
      border-right: 0;
      background: url("../../../assets/icon_assets1.png") no-repeat center 40px;
      &:hover {
        background: url("../../../assets/icon_active_assets1.png") no-repeat
          center 40px #0668b3;
      }
    }

    &.three {
      background: url("../../../assets/icon_money.png") no-repeat center 40px;
      &:hover {
        background: url("../../../assets/icon_active_money.png") no-repeat
          center 40px #0668b3;
      }
    }
  }
}

.new-area {
  margin-top: 40px;
  display: flex;

  .banner {
    padding-right: 25px;
    width: 460px;
  }

  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }

  p {
    margin: 0;
    padding: 0;
  }

  .new-list {
    flex: 1;

    .news-tab {
      position: relative;
      color: #999;
      font-size: 16px;
      border-bottom: 1px solid #d9d9d9;
      display: flex;
      padding: 0px 0 16px 0px;
      li {
        margin: 0px 20px 0px 0px;
        padding-left: 16px;
        font-size: 16px;
        font-weight: bold;

        &:hover {
          cursor: pointer;
          color: #333;
        }

        &.active {
          color: #333;
          position: relative;
          &::before {
            position: absolute;
            content: "";
            width: 5px;
            height: 5px;
            top: 10px;
            left: 3px;
            background: #00a0e9;
            border-radius: 5px;
          }
        }
      }
      .more {
        position: absolute;
        right: 0px;
        color: #999999;
      }
    }

    .new-des {
      li {
        border-bottom: dashed 1px rgba(112, 112, 112, 0.3);
        display: flex;
        padding: 12px 0;
        cursor: pointer;

        &:last-child {
          border-bottom: 0;
        }
        .new-date {
          background: #f2f2f2;
          text-align: center;
          color: #666;
          width: 56px;
          height: 52px;
          padding: 0 5px;
          margin-right: 15px;
          font-family: Arial, Helvetica, sans-serif;
          span {
            font-size: 30px;
          }
          p {
            font-size: 12px;
            margin: 0;
            padding: 0;
          }
        }

        .new-title {
          flex: 1;
          > span {
            margin-top: 5px;
            font-size: 16px;
            color: #666;
            font-weight: bold;
          }

          p {
            color: rgba(102, 102, 102, 0.6);
            margin-top: 5px;
            width: 100%;
            display: flex;

            > span {
              flex: 1;
              text-align: right;
            }
          }
        }
      }
    }
  }
}
</style>
