const getters = {
  tag: state => state.tags.tag,
  website: state => state.website,
  userInfo: state => state.user.userInfo,

  theme: state => state.common.theme,
  themeName: state => state.common.themeName,
  isShade: state => state.common.isShade,
  isCollapse: state => state.common.isCollapse,
  keyCollapse: (state, getters) => getters.screen > 1 ? getters.isCollapse : false,
  screen: state => state.common.screen,
  isLock: state => state.common.isLock,
  isFullScreen: state => state.common.isFullScreen,
  lockPasswd: state => state.common.lockPasswd,
  tagList: state => state.tags.tagList,
  tagWel: state => state.tags.tagWel,
  access_token: state => state.user.access_token,
  vipLeftTree: state => state.user.vipLeftTree,
  topSelectAreacode: state => state.user.topSelectAreacode,
  identityObj: state => state.user.identityObj,
  menuList: state => state.user.menuList,
  refresh_token: state => state.user.refresh_token,
  expires_in: state => state.user.expires_in,
  roles: state => state.user.roles,
  permissions: state => state.user.permissions,
  menu: state => state.user.menu,
  menuAll: state => state.user.menuAll,
  logsList: state => state.logs.logsList,
  logsLen: state => state.logs.logsList.length || 0,
  logsFlag: (state, getters) => getters.logsLen === 0,
  AllMessages: state => state.user.AllMessages,
  // ShowRouter:state => state.user.ShowRouter,
  CollectionVariety: state => state.user.CollectionVariety,
  formList: state => state.user.formList,
  dispositionList: state => state.user.dispositionList,
  // approveList: state => state.user.approveList,
  // approveList2:state => state.user.approveList2,
  // isState: state => state.user.isState,

}
export default getters
