<template>
  <div class="subsidyApplicationDetails">
    <!-- 种粮大户申请 -->
    <div class="back">
      <el-button type="text" @click="goBack"><i class="el-icon-arrow-left"></i>返回</el-button>
    </div>
    <div class="title">
      <h3>{{detailData.areaName}}种粮大户补贴申请表</h3>
    </div>
    <div class="time">
      申报日期:{{ detailData.declareTime }}
    </div>

    <div class="table">
      <table style="border: solid 1px #ccc">
        <tr>
          <td rowspan="5" colspan="2">基础信息</td>
          <td>姓名/单位</td>
          <td>
            {{detailData.subsidyLargeGrainGrowersVo.applyUser}}
          </td>
          <td>联系电话</td>
          <td>{{detailData.subsidyLargeGrainGrowersVo.phone}}</td>
        </tr>
        <tr>
          <td>身份证号/机构代码</td>
          <td colspan="4">{{detailData.subsidyLargeGrainGrowersVo.idCard}}</td>
        </tr>
        <tr>
          <td>地址</td>
          <td colspan="4">{{detailData.subsidyLargeGrainGrowersVo.address}}</td>
        </tr>
        <tr>
          <td rowspan="2">账户</td>
          <td>开户行</td>
          <td colspan="2">账户</td>
        </tr>
        <tr>

          <td>{{detailData.subsidyLargeGrainGrowersVo.bankName}}</td>
          <td colspan="2">{{detailData.subsidyLargeGrainGrowersVo.idCard}}</td>
        </tr>
      </table>

      <table>
        <tr>
          <td>承包面积(亩)</td>
          <td colspan="3">租种面积(亩) <span v-if="type == 'xq'" style=" color:#169BD5;text-decoration: underline;"
              @click="showadd = true">查看详情</span> <el-button v-if="type != 'xq'" type="primary"
              @click="showadd = true">修改</el-button> </td>
          <td>补贴面积(亩)</td>
        </tr>
        <tr>
          <td>
            <el-input type="number" v-model="form.contractArea" style="width:120px" placeholder="请输入面积"
              :disabled="true"></el-input>
          </td>
          <td colspan="3" style=" padding:0; margin:0; box-sizing: border-box; ">
            <!-- 第二个表格 -->
            <table transparent style=" box-sizing: border-box;">
              <tr>
                <td style="width:120px;">合计</td>
                <td
                  v-for="(item,index) in detailData.subsidyLargeGrainGrowersVo.twActivitySubsidyLargeGrainGrowersAreaList"
                  :key="index">
                  {{item.areaName}}
                </td>
              </tr>
              <tr>
                <td>
                  <el-input type="number" :disabled="true" v-model="form.rentalArea" style="width:120px"
                    placeholder="请输入面积"></el-input>
                  <p style=" color:#169BD5;text-decoration: underline;" @click="showDetail2()">
                    其中平台流转({{this.landAreaMu}}亩)
                  </p>
                </td>

                <td
                  v-for="(item,index) in detailData.subsidyLargeGrainGrowersVo.twActivitySubsidyLargeGrainGrowersAreaList"
                  :key="index">
                  {{item.area}}
                </td>
              </tr>
            </table>

          </td>
          <td>
            <el-input type="number" :disabled="true" v-model="form.totalSubsidyArea" style="width:120px"
              placeholder="请输入面积"></el-input>
            <!-- 补贴面积 -->
          </td>
        </tr>
        <tr>
          <td rowspan="2">种植品种和面积(亩)</td>
          <td style="width:100px;">种植面积合计</td>
          <td>水稻</td>
          <td>玉米</td>
          <td>小麦</td>
        </tr>
        <tr>
          <td>

            <el-input type="number" v-model="form.totalPlantingArea" style="width:120px" placeholder="请输入面积"
              :disabled="true"></el-input>
            <!-- 在这里 -->
          </td>
          <td>
            <el-input type="number" v-model="form.riceArea" style="width:120px" placeholder="请输入面积" @input="riceAreaIpt"
              :disabled="isDisabled"></el-input>

          </td>
          <td>
            <el-input type="number" v-model="form.cornArea" style="width:120px" placeholder="请输入面积" @input="cornAreaIpt"
              :disabled="isDisabled"></el-input>
          </td>
          <td>
            <el-input type="number" v-model="form.wheatArea" style="width:120px" placeholder="请输入面积"
              @input="riceAreaIpt" :disabled="isDisabled"></el-input>
          </td>
        </tr>
      </table>

    </div>

    <div class="introduce">
      <div class="left">
        <div class="title">
          <span> 本人承诺以上信息及提供的土地承包、租种、流转、捡种手续和面积、独立核算证明等均真实合法，如有弄虚作假，本人愿承担相关法律责任。</span>
        </div>
        <div class="acceptor">
          <div class="name">承诺人(盖章签字):</div>
          <div class="img">
            <!-- detailData.subsidyLargeGrainGrowersVo.sign -->
            <el-image style="width: 100px; height: 100px" :src="baseUrl + 'admin/file/get?ossFilePath=' + sign">
            </el-image>
          </div>
        </div>
        <div class="time">
          <!-- 2024年01月01日 -->
          {{detailData.subsidyLargeGrainGrowersVo.declareTime}}
        </div>
      </div>
      <!-- v-if="sign" -->
      <div class="right" v-if="cun">
        <div class="title">
          村委会意见:
        </div>
        <div class="auditResult">
          <el-input style="margin-top:10px;" :disabled="villageCouncil" type="textarea" placeholder="请输入审批意见"
            v-model="idea" @input="villageCouncilIpt">
          </el-input>
        </div>

        <div class="acceptor" v-if="sign&&!villageCouncil">
          <div class="name">负责人签字:</div>
          <div class="img">
            <vue-qr ref="qrCode" :logoSrc="logoSrc" :text="text" width="150" height="150"></vue-qr>
            <p>请使用微信扫码签字</p>
          </div>
        </div>
        <div class="acceptor" v-else>
          <div class="name">负责人签字:</div>
          <div class="img">
            <el-image style="width: 100px; height: 100px"
              :src="baseUrl + 'admin/file/get?ossFilePath=' + villageCouncil">
            </el-image>
          </div>
        </div>
        <div class="time">
          <!-- 2024年01月01日 -->
          {{detailData.twActivitySubsidyTaskHistories[0]?detailData.twActivitySubsidyTaskHistories[0].createTime:''}}
        </div>
      </div>
    </div>
    <div class="introduce2">
      <!-- v-if="villageCouncil" -->
      <div class="left" v-if="cai">
        <div class="title">
          <span> 财政所签署意见并加盖公章</span>
        </div>
        <div class="auditResult">
          <el-input style="height: 100px;" :disabled="financeSignature" type="textarea" placeholder="请输入审批意见"
            v-model="idea2" @input="villageCouncilIpt">
          </el-input>
        </div>
        <div class="acceptor" v-if="villageCouncil&&!financeSignature">
          <div class="name">负责人签字:</div>
          <div class="img">
            <vue-qr ref="qrCode" :logoSrc="logoSrc" :text="text" width="150" height="150"></vue-qr>
            <p>请使用微信扫码签字</p>
          </div>
        </div>
        <div class="acceptor" v-else>
          <div class="name">承诺人(盖章签字):</div>
          <div class="img">
            <el-image style="width: 100px; height: 100px"
              :src="baseUrl + 'admin/file/get?ossFilePath=' + financeSignature">
            </el-image>
          </div>
        </div>
        <div class="time">
          {{detailData.twActivitySubsidyTaskHistories[1]?detailData.twActivitySubsidyTaskHistories[1].createTime:''}}
        </div>
      </div>
      <!-- v-if="financeSignature" -->
      <div class="right" v-if="nong">
        <div class="title">
          农服中心签署意见并加盖公章
        </div>
        <div class="auditResult">
          <el-input :disabled="RuralUniform" type="textarea" placeholder="请输入审批意见" v-model="idea3"
            @input="villageCouncilIpt">
          </el-input>
        </div>

        <div class="acceptor" v-if="financeSignature&&!RuralUniform">
          <div class="name">负责人签字:</div>
          <div class="img">
            <vue-qr ref="qrCode" :logoSrc="logoSrc" :text="text" width="150" height="150"></vue-qr>
            <p>请使用微信扫码签字</p>
          </div>
        </div>
        <div class="acceptor" v-else>
          <div class="name">负责人签字:</div>
          <div class="img">
            <el-image style="width: 100px; height: 100px" :src="baseUrl + 'admin/file/get?ossFilePath=' + RuralUniform">
            </el-image>
          </div>
        </div>

        <div class="time">
          {{detailData.twActivitySubsidyTaskHistories[2]?detailData.twActivitySubsidyTaskHistories[2].createTime:''}}
        </div>
      </div>
    </div>
    <div class="introduce3" v-if="ren">
      <p class="tit">乡、镇(街道)人民政府(办事处)审核意见:</p>
      <div class="contentIdea">
        <span style="margin-left: 20px;">经种粮大户</span> <span
          class="textContent">{{detailData.subsidyLargeGrainGrowersVo.applyUser}} </span>
        <span>申报，农业服务中心审验备案资料</span><span class="textContent"><input type="text" :disabled="governmentImg.length"
            v-model="createTime"
            @input="villageCouncilIpt"></span>组织纪检、财政、农业服务中心及种植所在村委会人员通过走访村民、实地踏堪等方式审查了种植作物和面积。该种粮大户申报备案资料齐备、种植作物为<span
          class="textContent"><input type="text" :disabled="governmentImg" v-model="growCrops"
            @input="villageCouncilIpt"></span>实际种植面积为<span class="textContent"><input type="text"
            :disabled="governmentImg" v-model="form.totalSubsidyArea" @input="villageCouncilIpt"></span>亩。
      </div>
      <div class="title">
        <span>乡镇(街道)负责任签字：</span>
      </div>

      <div class="acceptor" v-if="RuralUniform&&!governmentImg">
        <!-- <div class="name">负责人签字：</div> -->
        <div class="imgBox">
          <div class="img">
            <vue-qr ref="qrCode" :logoSrc="logoSrc" :text="text" width="200" height="200"></vue-qr>
            <p>请使用微信扫码签字</p>
          </div>
        </div>

      </div>
      <div class="acceptor" v-else>
        <!-- <div class="name">负责人签字:</div> -->
        <div class="imgBox">
          <div class="img">
            <el-image style="width: 100px; height: 100px"
              :src="baseUrl + 'admin/file/get?ossFilePath=' + governmentImg">
            </el-image>
          </div>
        </div>
      </div>
      <div class="time">
        {{detailData.twActivitySubsidyTaskHistories[3]?detailData.twActivitySubsidyTaskHistories[3].createTime:''}}
      </div>
    </div>
    <div class="ImgBox">
      <div class="Left">
        <div class="title">
          <span> 法定代表人身份证照片</span>
        </div>
        <div class="filBox">
          <el-image style="width: 150px; height: 90px;padding-right: 20px"
            :src="baseUrl + 'admin/file/get?ossFilePath=' + legalIdcardPicFront">
          </el-image>
          <el-image style="width: 150px; height: 90px"
            :src="baseUrl + 'admin/file/get?ossFilePath=' +legalIdcardPicBack">
          </el-image>
        </div>
      </div>
      <div class="Right" v-if="detailData.subsidyLargeGrainGrowersVo.businessLicense">
        <div class="title">
          <span>营业执照</span>
        </div>
        <div class="filBox">
          <el-image style="width: 100px; height: 100px"
            :src="baseUrl + 'admin/file/get?ossFilePath=' + detailData.subsidyLargeGrainGrowersVo.businessLicense">
          </el-image>
        </div>
      </div>
    </div>
    <div class="ImgFiles" v-if="JSON.parse(detailData.subsidyLargeGrainGrowersVo.certificateContractPicUrl).length">
      <div class="title">
        承包耕地证明
      </div>
      <div class="filBox">
        <el-image style="width: 100px; height: 100px"
          v-for="(item,index) in JSON.parse(detailData.subsidyLargeGrainGrowersVo.certificateContractPicUrl)"
          :key="index" :src="baseUrl + 'admin/file/get?ossFilePath=' + item.imgUrl">
        </el-image>
      </div>
    </div>

    <div class="ImgFiles" v-if="JSON.parse(detailData.subsidyLargeGrainGrowersVo.landTransferContractPicUrl).length ">
      <div class="title">
        耕地流转合同
      </div>
      <div class="filBox">
        <el-image style="width: 100px; height: 100px"
          v-for="(item,index) in JSON.parse(detailData.subsidyLargeGrainGrowersVo.landTransferContractPicUrl)"
          :key="index" :src="baseUrl + 'admin/file/get?ossFilePath=' + item.imgUrl">
        </el-image>
      </div>
    </div>

    <div class="ImgFiles" v-if="JSON.parse(detailData.subsidyLargeGrainGrowersVo.otherFiles) .length">
      <div class="title">
        承包耕地证明
      </div>
      <div class="filBox">
        <el-image style="width: 100px; height: 100px"
          v-for="(item,index) in JSON.parse(detailData.subsidyLargeGrainGrowersVo.otherFiles)" :key="index"
          :src="baseUrl + 'admin/file/get?ossFilePath=' + item.imgUrl">
        </el-image>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div class="BtnBox" v-if="!isDisabled">
      <div class="inner">
        <el-button style="color: #fff;background-color: #F7C6CC;" round @click="Edit()">修改</el-button>
      </div>
    </div>
    <el-dialog title="兴村通平台社会化服务记录" :visible.sync="dialogVisible" width="70%" :before-close="handleClose">
      <table-page :data="tableData" :columns="columns" :pagination="false">
        <template slot="operation" slot-scope="scope">
          <el-button type="text" size="small" @click="showDel(scope.row)">详情</el-button>
        </template>
      </table-page>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="租种详情" :visible.sync="showadd" width="40%" style="text-align: center;">
      <el-table :data="form.twActivitySubsidyLargeGrainGrowersAreaList" style="width: 100%">
        <el-table-column prop="areaCode" label="所在地区" align="center">
          <template slot-scope="scope">
            <!-- 根据 type 判断是否显示只读信息 -->
            <span v-if="type === 'xq'">{{ scope.row.areaName }}</span>
            <el-cascader v-else ref="cascader" v-model="scope.row.areaCode" :props="props" :options="options"
              :show-all-levels="false" @change="regionChange1(scope.row, scope.$index)"></el-cascader>
          </template>
        </el-table-column>
        <el-table-column prop="area" label="亩数" align="center">
          <template slot-scope="scope">
            <!-- 根据 type 判断是否显示只读信息 -->
            <span v-if="type === 'xq'">{{ scope.row.area }}</span>
            <el-input v-else v-model="scope.row.area" placeholder="请输入亩数"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <!-- 根据 type 判断是否显示删除按钮 -->
            <el-button v-if="type !== 'xq'" type="danger" size="mini" @click="deleteRow(scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 根据 type 判断是否显示添加按钮 -->
      <el-button v-if="type !== 'xq'" style="margin-top: 20px;" @click="addRow">添加一行</el-button>


      <span slot="footer" class="dialog-footer">
        <el-button @click="showadd = false">取 消</el-button>
        <el-button type="primary" @click="rentalAreaIpt">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  import VueQr from "vue-qr";
  import { Utils } from "@/assets/js/util.js";
  import { mapGetters } from "vuex";
  const Url = window.globalUrl.api2;
  import TablePage from "@/components/table/table-page";
  import { grainSubsidyColumn } from '../../tableColumn'
  import { selectLargeGrainGrowersDetail, selectLandArea, updateLargeGrainGrowersDetail, redisWorkflow } from '@/api/subsidy'
  import { getAreaTree } from "@/api/certificate";
  import { removeEmptyChildren } from "@/js/utils/util";
  export default {
    data() {
      return {
        type: '', // 类型
        showadd: false, //添加平台服务面显示
        baseUrl: window.globalUrl.BASE_API,
        text: '',
        dialogVisible: false,
        tableData: [],
        columns: grainSubsidyColumn,
        logoSrc: "",
        taskId: "", // 查询id
        randomColor: "black",
        qrCodeUrl: "", // 存放生成的二维码url
        textarea2: '',
        sign: '',//本人签字
        villageCouncil: '',// 村委会负责人签字
        idea: '',//村审批意见
        financeSignature: '',//财政所属签字
        idea2: '',//财政所属审批意见
        RuralUniform: '',//农服中心签字
        idea3: '',//农服中心审批意见
        governmentImg: '',//人民政府签字
        governmentText: '',//人民政府签字
        taskKey: '',//是否显示本节点的
        totalArea: 0,//合计
        growCrops: '',// 种植作物
        createTime: '',//创建时间
        detailData: {
          subsidyLargeGrainGrowersVo: {
          }
        },
        isDisabled: false, // 是否禁用输入框
        num: 0,//合并格数
        landAreaMu: 0, //平台流转面积,
        legalIdcardPicFront: '',
        legalIdcardPicBack: '',
        key: '',// 二维码key
        content: '',// 村委会意见
        cun: false,//村是否可见
        cai: false,//财政所
        nong: false,//农服中心
        ren: false,//人民政府
        props: {
          label: "areaName",
          children: "children",
          value: "id",
          fullPathName: "fullPathName",
          checkStrictly: true,
          emitPath: false,
        },
        options: [],
        form: {
          contractArea: 0,//承包面积
          totalSubsidyArea: 0,//补贴面积
          rentalArea: 0,    // 租种面积
          wheatArea: 0,//小麦面积
          cornArea: 0,//玉米面积
          riceArea: 0,//水稻面积
          totalPlantingArea: 0,//种植面积合并
          twActivitySubsidyLargeGrainGrowersAreaList: []
        },


      }
    },
    components: {
      VueQr,
      TablePage
    },
    computed: {
      ...mapGetters(["identityObj"]),

    },
    created() {
      this.type = this.$route.query.type;
      if (this.$route.query.type == 'xq') {
        this.isDisabled = true
      } else {
        this.isDisabled = false
      }

      var that = this;
      Utils.$off("dbspDetails");
      Utils.$on("dbspDetails", function (e) {
        // console.log("4445411111111111111111111111111111111111111111111111", e.data);
        console.log(JSON.parse(e.data));
        let res = JSON.parse(e.data);
        if (res.isBoolean == true) {
          console.log('这里执行没有')
          // that.showy = false;
          that.goBack();
        }
      });
      this.selectLargeGrainGrowersDetail()
      this.getRegions()

    },

    methods: {
      // 添加一行
      addRow() {
        console.log(this.form.twActivitySubsidyLargeGrainGrowersAreaList, '这是添加一行前的数组');

        this.form.twActivitySubsidyLargeGrainGrowersAreaList.push({
          area: "",
          areaCode: "",
          areaName: "",
          declareId: ""
        });
      },
      deleteRow(index) {
        console.log(index, '这是删除的index')
        this.form.twActivitySubsidyLargeGrainGrowersAreaList.splice(index, 1)
      },
      // 租种面积-
      rentalAreaIpt() {
        this.showadd = false;
        this.form.rentalArea = this.form.twActivitySubsidyLargeGrainGrowersAreaList.reduce((accumulator, item) => {
          return accumulator + (parseFloat(item.area) || 0);
        }, 0);

        console.log(this.form.rentalArea, '租种面积');

        const contractArea = parseFloat(this.form.contractArea) || 0;
        const rentalArea = parseFloat(this.form.rentalArea) || 0;
        this.form.totalSubsidyArea = contractArea + rentalArea;
        console.log(this.form.totalSubsidyArea, '总补贴面积');
      },
      // 获取行政区树
      async getRegions() {
        let param = {
          level: "5",
          // parentCode:this.topSelectAreacode
        };
        let res = await getAreaTree(param);

        let { code, data, msg } = res;
        if (code == 0) {
          this.options = removeEmptyChildren(data);
        } else {
          this.$message.error(msg);
        }
      },
      regionChange1(value, index) {
        console.log('这里执行了嘛', value, index)
        let node = this.$refs.cascader.getCheckedNodes()[0];
        console.log(node, '查看node')
        this.form.twActivitySubsidyLargeGrainGrowersAreaList[index].areaCode = node.value;
        this.form.twActivitySubsidyLargeGrainGrowersAreaList[index].areaName = node.label;
        // this.form.twActivitySubsidyLargeGrainGrowersAreaList[index].areaName = node.pathLabels.join("/");
      },
      goBack() {
        this.$router.go(-1); // 返回上一级路由
      },
      //租种面积
      // rentalAreaIpt() {
      //   const contractArea = parseFloat(this.form.contractArea) || 0;
      //   const rentalArea = parseFloat(this.form.rentalArea) || 0;
      //   let sum = 0
      //   this.tableData.forEach((item, index) => {
      //     console.log(item, '查看item')
      //     sum += item.flowAreaMu
      //   })
      //   console.log(sum, '查看sum')
      //   // sum += this.form.rentalArea
      //   this.totalArea = sum

      // },
      // 种粮大户的详情
      async selectLargeGrainGrowersDetail() {
        console.log('详情接口是否执行')
        let params = {
          declareId: this.$route.query.declareId
        }
        let res = await selectLargeGrainGrowersDetail(params)
        if (res.code == 0) {
          console.log(res.data.subsidyLargeGrainGrowersVo, '查看这是什么')
          this.selectLandArea(res.data.subsidyLargeGrainGrowersVo.areaCode
          )

          this.detailData = res.data
          this.taskId = res.data.taskId
          //添加种植作物
          let str2 = ''
          if (res.data.subsidyLargeGrainGrowersVo
            .riceArea > 0) {
            str2 += '水稻,'
          }
          if (res.data.subsidyLargeGrainGrowersVo
            .cornArea > 0) {
            str2 += '玉米,'
          }
          if (res.data.subsidyLargeGrainGrowersVo
            .wheatArea) {
            str2 += '小麦'
          }
          this.growCrops = str2
          this.createTime = res.data.declareTime

          this.form.riceArea = parseFloat(res.data.subsidyLargeGrainGrowersVo
            .riceArea) || 0//水稻面积

          this.form.cornArea = parseFloat(res.data.subsidyLargeGrainGrowersVo
            .cornArea) || 0//玉米面积

          this.form.wheatArea = parseFloat(res.data.subsidyLargeGrainGrowersVo
            .wheatArea) //小麦面积

          this.form.totalSubsidyArea = parseFloat(res.data.subsidyLargeGrainGrowersVo.totalSubsidyArea) || 0 //补贴面积

          this.form.totalPlantingArea = parseFloat(res.data.subsidyLargeGrainGrowersVo
            .totalPlantingArea) || 0//种植面积合并

          this.form.contractArea = parseFloat(res.data.subsidyLargeGrainGrowersVo.contractArea) || 0 //承包面积
          this.form.rentalArea = parseFloat(res.data.subsidyLargeGrainGrowersVo.rentalArea)//租种面积
          this.legalIdcardPicBack = res.data.subsidyLargeGrainGrowersVo.legalIdcardPicBack ? res.data.subsidyLargeGrainGrowersVo.legalIdcardPicBack : ''
          this.legalIdcardPicFront = res.data.subsidyLargeGrainGrowersVo.legalIdcardPicFront ? res.data.subsidyLargeGrainGrowersVo.legalIdcardPicFront : ''
          this.form.twActivitySubsidyLargeGrainGrowersAreaList = res.data.subsidyLargeGrainGrowersVo.twActivitySubsidyLargeGrainGrowersAreaList
          //签字图片和意见
          this.sign = res.data.subsidyLargeGrainGrowersVo.sign ? res.data.subsidyLargeGrainGrowersVo.sign : false//本人签字
          this.villageCouncil = res.data.twActivitySubsidyTaskHistories[0] ? res.data.twActivitySubsidyTaskHistories[0].pictureUrl : false//村委会签字图片
          this.idea = res.data.twActivitySubsidyTaskHistories[0] ? res.data.twActivitySubsidyTaskHistories[0].content : ''//村委会意见
          this.financeSignature = res.data.twActivitySubsidyTaskHistories[1] ? res.data.twActivitySubsidyTaskHistories[1].pictureUrl : false  // 财政所属签字
          this.idea2 = res.data.twActivitySubsidyTaskHistories[1] ? res.data.twActivitySubsidyTaskHistories[1].content : ''  // 财政所属意见
          this.RuralUniform = res.data.twActivitySubsidyTaskHistories[2] ? res.data.twActivitySubsidyTaskHistories[2].pictureUrl : false//农服中心签字
          this.idea3 = res.data.twActivitySubsidyTaskHistories[2] ? res.data.twActivitySubsidyTaskHistories[2].content : ''//农服中心意见
          this.governmentImg = res.data.twActivitySubsidyTaskHistories[3] ? res.data.twActivitySubsidyTaskHistories[3].pictureUrl : false//人民政府签字
          this.governmentText = res.data.twActivitySubsidyTaskHistories[3] ? res.data.twActivitySubsidyTaskHistories[3].content : false//人民政府签字
          // 获取当前可见任务
          this.taskKey = res.data.taskKey
          // 定义每个 taskKey 对应的可见角色
          const visibilityMap = {
            'sid-90f0fe3d-ec94-49da-9233-fe56d6e472c0': ['村'],
            'sid-aa38af04-0e09-4e7f-b531-ff7e8f7cff2f': ['村', '财政所'],
            'sid-a38561e0-aa6a-464b-a111-de3dcdc0e851': ['村', '财政所', '农服中心'],
            'sid-92618f01-ddcb-41b1-8258-3a7c84c0c77a': ['村', '财政所', '农服中心', '人民政府']
          };
          // 获取当前可见角色
          const visibleRoles = visibilityMap[this.taskKey] || [];
          let isShow = this.$route.query.str == 'yb' ? true : false
          console.log(isShow, '查看是否显示')
          console.log(this.$route.query.type != 'xq', 'true为点击的修改')
          if (isShow || this.$route.query.type != 'xq') {
            // 渲染村相关内容
            let num = res.data.twActivitySubsidyTaskHistories.length
            console.log(num, '查看num')

            switch (num) {
              case 1:
                this.cun = true
                break;
              case 2:
                this.cun = true
                this.cai = true
                break;
              case 3:
                this.cun = true
                this.cai = true
                this.nong = true
                break;
              case 4:
                this.cun = true
                this.cai = true
                this.nong = true
                this.ren = true
                //如果政府图片存在重新组织意见
                // .content
                let data = JSON.parse(res.data.twActivitySubsidyTaskHistories[3])
                console.log(data, '查看data')
                this.createTime = data.createTime
                this.growCrops = data.growCrops
                this.from.totalSubsidyArea = data.totalSubsidyArea
                break;
            }
          } else {
            // 根据可见角色进行渲染
            if (visibleRoles.includes('村')) {
              this.cun = true
            }
            if (visibleRoles.includes('财政所')) {
              // 渲染财政所相关内容
              this.cun = true
              this.cai = true
            }

            if (visibleRoles.includes('农服中心')) {
              this.cun = true
              this.cai = true
              this.nong = true
              this.ren = true
              // 渲染农服中心相关内容
            }

            if (visibleRoles.includes('人民政府')) {
              // 渲染人民政府相关内容
              this.cun = true
              this.cai = true
              this.nong = true
              this.ren = true
            }
            //如果没有审批过就调用qrCodeCallback
            if (!res.data.twActivitySubsidyTaskHistories.pictureUrl) {
              this.qrCodeCallback()
            }
          }

          // this.text = `${Url}?taskId=${this.taskId}/processInstanceId=${this.detailData}/agreeReason=${this.idea}/identityId=${this.identityId}`;
        }

      },

      // 种粮大户租种面积查询
      async selectLandArea(areaCode) {
        console.log('这里执行了嘛', this.$route.query.declareIdentityId)
        let params = {
          areaCode: areaCode,
          identityId: this.$route.query.declareIdentityId
        }
        let res = await selectLandArea(params)
        this.tableData = res.data.landAreas
        this.landAreaMu = res.data.landAreaMu

        let sum = 0
        res.data.landAreas.forEach((item, index) => {
          // console.log(item, '查看item')
          if (item.xzqbh != areaCode) {
            sum += item.flowAreaMu
          }
        })
        // console.log(sum, '查看sum')
        // sum += this.form.rentalArea
        this.totalArea = sum

      },
      // 处理二维码回调，设置二维码的URL
      async qrCodeCallback(url) {
        // this.qrCodeUrl = url;
        let allTaskIds = []
        allTaskIds.push({
          declareId: this.detailData.subsidyLargeGrainGrowersVo.declareId,
          taskId: this.detailData.taskId,
        })
        let res = await redisWorkflow(allTaskIds)
        this.key = res.data;
        this.callApiOnBlur()
      },
      // 监听审批意见
      callApiOnBlur() {
        let identityObj = JSON.parse(localStorage.getItem("identityObj"));
        this.identityId = identityObj.content.identityId;
        let content = '';

        if (this.RuralUniform) {
          // content = " 经种粮大户" + this.detailData.subsidyLargeGrainGrowersVo.applyUser + ' 申报，农业服务中心审验备案资料' + this.createTime + '组织纪检、财政、农业服务中心及种植所在村委会人员通过走访村民、实地踏堪等方式审查了种植作物和面积。该种粮大户申报备案资料齐备、种植作物为' + this.growCrops + '实际种植面积为' + this.form.totalSubsidyArea + '亩。'
          let data = {
            createTime: this.createTime,//时间
            growCrops: this.growCrops,//作物种类
            totalSubsidyArea: this.form.totalSubsidyArea,//补贴面积
          }
          content = JSON.stringify(data)
        } else if (this.financeSignature) {
          content = this.idea3
        } else if (this.villageCouncil) {
          content = this.idea2
        } else if (this.sign) {
          content = this.idea
        }
        this.text = `${Url}?key=${this.key}/identityId=${identityObj.content.identityId}/agreeReason=${content}`;
        // console.log(this.text, '查看text为啥不能扫码')
      },
      //显示以服务列表
      showDetail(row) {
        this.dialogVisible = true
        // console.log(row, '查看row是什么')
      },
      //关闭弹窗
      handleClose() {
        this.dialogVisible = false
      },
      // 计算总种植面积
      calculateTotalPlantingArea() {
        const wheatArea = parseFloat(this.form.wheatArea) || 0;
        const cornArea = parseFloat(this.form.cornArea) || 0;
        const riceArea = parseFloat(this.form.riceArea) || 0;
        this.form.totalPlantingArea = wheatArea + cornArea + riceArea;
      },
      // 小麦种植面积变化
      wheatAreaIpt() {
        this.calculateTotalPlantingArea();
      },
      // 玉米种植面积变化
      cornAreaIpt() {
        this.calculateTotalPlantingArea();
      },

      // 水稻种植面积变化
      riceAreaIpt() {
        this.calculateTotalPlantingArea();
      },
      //显示流转亩数详情
      showDetail2() {
        this.dialogVisible = true
      },
      // 展示补贴详情亩数
      showDel(row) {
        console.log(row.xmbh, '查看是什么')
        let xmbh = row.xmbh
        window.open(this.baseUrl + "projectDetail?c=" + xmbh + '#/home/projectDetail?c=' + xmbh);
      },
      // 村委会签字
      villageCouncilIpt() {
        this.callApiOnBlur()
      },
      //修改
      async Edit() {
        let params = {
          contractArea: parseFloat(this.form.contractArea),
          totalSubsidyArea: parseFloat(this.form.totalSubsidyArea),
          rentalArea: parseFloat(this.form.rentalArea),
          wheatArea: parseFloat(this.form.wheatArea),
          cornArea: parseFloat(this.form.cornArea),
          riceArea: parseFloat(this.form.riceArea),
          totalPlantingArea: parseFloat(this.form.totalPlantingArea),
          declareId: this.detailData.subsidyLargeGrainGrowersVo.declareId,
          twActivitySubsidyLargeGrainGrowersAreaList: this.form.twActivitySubsidyLargeGrainGrowersAreaList
        }
        let res = await updateLargeGrainGrowersDetail(params)
        if (code == 0) {
          this.selectLargeGrainGrowersDetail()
          this.$message.success(res.msg)
        }
        console.log(this.idea, '查看idea是什么')
      }
    }
  }
</script>

<style lang="less" scoped>
  .subsidyApplicationDetails {
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    .back {
      font-size: 22px;
      margin-bottom: 20px;
    }

    .title {
      display: flex;
      justify-content: center;
    }

    .time {
      text-align: right;
      margin-bottom: 20px;
      font-size: 14px;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      text-align: center;

      th,
      td {
        border: 1px solid #ccc;
        padding: 10px;
      }
    }

    .introduce {
      box-sizing: border-box;
      padding: 0px;
      margin: 0px;
      height: auto;
      width: 100%;
      /* border: solid 1px black; */
      border-top: none;
      display: flex;

      .left {
        width: 50%;
        display: flex;
        padding-top: 20px;
        align-items: center;
        flex-direction: column;
        border: solid 1px #ccc;

        .title {
          width: 90%;
          height: 60%;
          font-size: 20px;
        }

        .acceptor {
          width: 90%;
          height: auto;
          display: flex;

          .img {
            margin-left: 20px;
            ;
          }
        }

        .time {
          width: 95%;
        }
      }

      .right {
        width: 50%;
        display: flex;
        padding-top: 20px;
        align-items: center;
        flex-direction: column;
        border: solid 1px #ccc;

        .title {
          width: 90%;
          height: 10%;
        }

        .auditResult {
          width: 90%;
          height: 35%;
        }

        .acceptor {
          width: 90%;
          height: auto;
          display: flex;
          margin-top: 20px;

          .img {
            margin-left: 20px;
            ;
          }
        }

        .time {
          width: 95%;
        }
      }
    }

    .ImgBox {
      width: 100%;
      display: flex;
      margin-top: 20px;

      .title {
        display: flex;
        align-items: center;
      }

      .Left {
        width: 50%;
        display: flex;


      }

      .Right {
        width: 50%;
        display: flex;
      }
    }

    .ImgFiles {
      width: 100%;
      min-height: 100px;
      display: flex;
      margin-top: 20px;

      .title {
        display: flex;
        align-items: center;
      }

      .filBox {
        margin-left: 50px;
      }
    }

    .introduce2 {
      box-sizing: border-box;
      padding: 0px;
      margin: 0px;
      height: auto;
      width: 100%;
      border-top: none;
      display: flex;

      .left {
        width: 50%;
        display: flex;
        padding-top: 20px;
        align-items: center;
        flex-direction: column;
        border: solid 1px #ccc;

        .title {
          width: 90%;
          height: 10%;
        }

        .auditResult {
          width: 90%;
          height: 30%;
        }

        .acceptor {
          width: 90%;
          height: auto;
          display: flex;

          .img {
            margin-left: 20px;
            ;
          }
        }

        .time {
          width: 95%;
          padding-bottom: 0px;
          margin-bottom: 0px;
        }
      }

      .right {
        width: 50%;
        display: flex;
        padding-top: 20px;
        align-items: center;
        flex-direction: column;
        border: solid 1px #ccc;

        .title {
          width: 90%;
          height: 10%;
        }

        .auditResult {
          width: 90%;
          height: 30%;
        }

        .acceptor {
          width: 90%;
          height: auto;
          display: flex;

          .img {
            margin-left: 20px;
            ;
          }
        }

        .time {
          width: 95%;

        }
      }
    }

    .introduce3 {
      box-sizing: border-box;
      width: 100%;
      height: auto;
      border: solid 1px #ccc;
      font-size: 20px;
      padding-left: 10px;



      span {
        display: inline-block;
      }


      .tit {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 20px;
      }

      .title {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }

      .contentIdea {
        width: 100%;

        .textContent {
          border-bottom: 1px solid #169BD5;

          input {
            width: 170px;
            border: none;
            text-align: center;
          }
        }
      }

      .acceptor {
        width: 100%;

        .imgBox {
          display: flex;
          justify-content: center;
        }
      }



      .time {
        margin-bottom: 10px;
        margin-right: 10px;
      }
    }

    .BtnBox {
      width: 100%;
      display: flex;
      justify-content: center;

      .inner {
        button {
          margin-right: 20px;
        }
      }
    }

  }
</style>