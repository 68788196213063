<template>
  <div class="wytg">
    <div class="form-list">
      <div class="table-search">
        <h1>认领资源资产流转记录</h1>
        <!-- 方便找页面 后期可能删除 -->
      </div>
      <div class="table-content">
        <el-table border class="table" :data="tableData" :loading="loading">
          <el-table-column
            align="center"
            prop="xmbh"
            label="项目编号"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="xmmc"
            label="项目名称"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="gpdj"
            label="单价(元/亩)"
            width="160px"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="gpjg"
            label="合同价格(元)"
            width="160px"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="lznx"
            label="年限"
            width="160px"
          ></el-table-column>

          <el-table-column
            align="center"
            label="面积"
          >
          <template slot-scope="scope">
            {{
              scope.row.zcmj + (scope.row.mjdw == "0" ? "平方米" : "亩")
            }}
          </template>
        </el-table-column>
          <el-table-column
            align="center"
            prop="crf"
            label="出让方"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="srf"
            label="受让方"
          ></el-table-column>

          <el-table-column align="left" label="操作" width="300">
            <template slot-scope="scope">
              <el-button
                style="height: 28px; padding: 0 8px"
                type="primary"
                @click="detailClick(scope.row)"
                >查看</el-button
              >
              <el-button
                style="height: 28px; padding: 0 8px"
                type="primary"
                @click="renling(scope.row)"
                >认领</el-button
              >
              <el-button
                style="height: 28px; padding: 0 8px"
                type="primary"
                @click="BUrenling(scope.row)"
                >非本人项目</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import { selectClaimXmxx, updateClaimStatus } from "@/api/trustManagement";
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      tableData: [],
      loading: false,
    };
  },
  watch: {},
  created() {
    this.getList();
  },
  computed: mapGetters(["identityObj"]),
  methods: {
   async renling(data) {
    console.log(data);

      let res = await updateClaimStatus({
        xmbsm:data.xmbsm,
        status:'1'
      });
      if (!res.code) {
        this.$message.success(res.msg);
        this.getList();
      } else {
        this.$message.error(res.msg);
      }
    },
   async BUrenling(data) {
    console.log(data);

      let res = await updateClaimStatus({
        xmbsm:data.xmbsm,
        status:'2'
      });
      if (res.code==1) {
        this.$message.success(res.msg);
        this.getList();
      } else {
        this.$message.error(res.msg);
      }
    },
     detailClick(data) {
      console.log(data);
      // 查看项目详情
      this.$router.push({ path: "/home/projectDetail", query: { c: data.xmbh } });
    },

    // 获取列表数据
    async getList() {
      this.loading = true;
      let res = await selectClaimXmxx();
      if (!res.code) {
        this.tableData = res.data;
      } else {
        this.$message.error("查询失败");
      }
      this.loading = false;
    },

    submit() {},
    reset() {},
  },
};
</script>
<style lang="less" scoped>
.wytg {
  text-align: center;
  height: 85vh;
}
.form-list {
  .table-search {
    padding: 0 0 0 30px;
    /deep/.el-button {
      padding: 10px 20px !important;
    }
    .tools {
      margin-bottom: 18px;
      margin-top: 6px;
    }
  }
  .table-content {
    padding: 0 30px;
  }
}
</style>
