<template>
  <div class="ybmxm">
    <!-- 发起流转审批弹窗 -->
    <flowApproval
      v-if="isShow"
      @closeDialog3="closeDialog3"
      ref="flowApproval"
    />
    <transactWrite
      v-if="isTransactWrite"
      @closeDialog4="closeDialog4"
      ref="transactWrite"
    />

    <div class="project">
      <div style="padding: 0 30px">
        <h3>资源资产流转审批记录</h3>
        <el-form
          class="query-form"
          :model="form"
          label-position="right"
          label-width="90px"
          inline
          ref="form"
        >
          <el-form-item label="提交时间：">
            <el-date-picker
              v-model="form.startTime"
              placeholder="选择开始日期"
              size="small"
              value-format="yyyy-MM-dd"
              clearable
            >
            </el-date-picker>
            至
            <el-date-picker
              v-model="form.endTime"
              placeholder="选择结束日期"
              align="right"
              size="small"
              value-format="yyyy-MM-dd"
              clearable
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="状态">
            <el-select style="width: 140px" v-model="form.status" clearable>
              <el-option
                v-for="item in shTypes"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="交易类型">
            <el-select style="width: 140px" v-model="form.lzlx" clearable>
              <el-option
                v-for="item in changeTypeOptions"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="产权类型">
            <el-select style="width: 140px" v-model="form.cqlxbh" clearable>
              <el-option
                v-for="item in typeOptions"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <span class="button" style="margin-top: 0">
            <el-form-item>
              <el-button class="query-btn" type="primary" @click="query"
                >查询</el-button
              >
              <el-button type="primary" @click="submit()"
                >发起流转审批</el-button
              >
            </el-form-item>
          </span>
        </el-form>
      </div>
      <div class="table-card">
        <div class="table-con">
          <el-table
            border
            class="table"
            :data="tableData"
            style="width: 100% "
            max-height="850"
            :row-class-name="handleRowClass"
          >
            <el-table-column align="center" label="项目编号" width="200px">
              <template slot-scope="scope">
                <span style="display: inline-block;" v-for="(item, index) in scope.row.xmxxList" :key="index">
                  {{ item.xmbh }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="项目名称" width="230px">
              <template slot-scope="scope">
                <span
                  style="color: blue;display: inline-block;"
                  v-for="(item, index) in scope.row.xmxxList"
                  :key="index"
                  @click="showDetail(item, index)"
                  >{{ item.xmmc }}</span
                >
              </template>
            </el-table-column>
            <el-table-column align="center" label="总面积">
              <template slot-scope="scope">
                <span>{{ calculateTotalFlowAreaMu(scope.row.xmxxList) }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="土地类型">
              <template slot-scope="scope">
                <!-- <span>{{ getCqlx(scope.row.xmxxList[0].cqlxbh) }}</span> -->
                <span>
                  {{
                    [
                      ...new Set(
                        scope.row.xmxxList.map((item) => getCqlx(item.cqlxbh))
                      ),
                    ].join(", ")
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="交易类型">
              <template slot-scope="scope">
                <span>
                  {{
                    [
                      ...new Set(
                        scope.row.xmxxList.map((item) => getLzlx(item.lzlx))
                      ),
                    ].join(", ")
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="cjzj" label="总金额">
              <template slot-scope="scope">
                <span>{{ calculatecjzj(scope.row.xmxxList) }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column align="center" label="土地位置" width="200px">
              <template slot-scope="scope">
                <span style="display: inline-block;" v-for="(item, index) in scope.row.xmxxList" :key="index">
                  {{ item.xmdz }}</span
                >
              </template>
            </el-table-column> -->
            <el-table-column
              align="center"
              prop="uploadTime"
              label="提交时间"
            ></el-table-column>
            <el-table-column align="center" prop="status" label="当前进度">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 0 ">已提交</span>
                <span v-else-if="scope.row.status == 1">审批中</span>
                <span v-else-if="scope.row.status == 2">退回</span>
                <span v-else-if="scope.row.status == 3">审批结束</span>
              </template>
            </el-table-column>

            <el-table-column align="center" label="操作" width="200">
              <template slot-scope="scope">
                <el-button
                  style="height: 28px; padding: 0 8px"
                  type="primary"
                  @click="chakan(scope.row)"
                  >查看</el-button
                >
                <el-button
                  v-if="scope.row.status == 2"
                  style="height: 28px; padding: 0 8px"
                  type="primary"
                  @click="transact(scope.row)"
                  >办理</el-button
                >
                <el-button
                  v-if="scope.row.status == 0 || scope.row.status == 2"
                  style="height: 28px; padding: 0 8px"
                  type="primary"
                  @click="withdraw(scope.row)"
                  >撤销</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagenation">
          <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="currentPage"
            :page-size="pageSize"
            :total="total"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      title="确定撤销如下项目的流转审批吗?"
      :visible.sync="dialogVisible"
      width="90%"
      :before-close="handleClose"
    >
      <el-table
        ref="multipleTable"
        :data="withdrawlist"
        tooltip-effect="dark"
        style="width: 100%"
         max-height="550"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          align="center"
          label="项目编号"
          prop="xmbh"
          width="120"
        >
        </el-table-column>
        <el-table-column align="center" prop="xmmc" label="标题" width="220">
        </el-table-column>
        <el-table-column
          align="center"
          prop="cjTime"
          label="成交时间"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="flowAreaMu"
          label="面积/亩"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="lznx"
          label="年限"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="cjzj"
          label="金额"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="crf"
          label="流出方"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="clearSelection()">关闭</el-button>
        <el-button type="primary" @click="withdrawSubmit()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="查看详细" :visible.sync="dialogVisible1" width="90%"   :before-close="handleClose">
      <el-table
        ref="multipleTable"
        :data="viewlist"
        border
        tooltip-effect="dark"
         max-height="550"
        style="width: 100%"
      >
        <el-table-column align="center" label="项目编号" width="200px">
          <template slot-scope="scope">
            <span style="display: inline-block;" v-for="(item, index) in scope.row.list" :key="index">
              {{ item.xmbh }}</span
            >
          </template>
        </el-table-column>

        <el-table-column align="center" label="项目名称" width="250px">
          <template slot-scope="scope">
            <span
              style="color: blue;display: inline-block;"
              v-for="(item, index) in scope.row.list"
              :key="index"
              @click="showDetail(item, index)"
              >{{ item.xmmc }}</span
            >
          </template>
        </el-table-column>
        <el-table-column align="center" label="成交时间">
          <template slot-scope="scope">
            <span>{{ scope.row.list[0].cjTime }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="总面积">
          <template slot-scope="scope">
            <span>{{ calculateTotalFlowAreaMu(scope.row.list) }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" label="总金额">
          <template slot-scope="scope">
            <span>{{ calculatecjzj(scope.row.list) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="项目地址">
          <template slot-scope="scope">
            <span>{{ scope.row.list[0].xmdz }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="审批进度" width="200px">
          <template slot-scope="scope">

            <span>
             <!-- item.processName -->
              {{   [
                      ...new Set(
                        scope.row.list.map((item) => item.processName)
                      ),
                    ].join(", ") }}</span>
          </template>
        </el-table-column>

      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false,viewlist=[]">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  selectApprovalRecord,
  selectMyApprovalRecord,
  selectWithdrawXmxx,
  submitWithdraw,
} from "@/api/lzsp";
import { getCertificateStatus } from "@/api/account";

import { mapGetters } from "vuex";
import flowApproval from "./flowApproval";
import transactWrite from "./transactWrite";

export default {
  components: {
    flowApproval,
    transactWrite,
  },
  data() {
    return {
      form: {
        status: "",
        cqlxbh: "",
        lzlx: "",
        endTime: null,
        startTime: null,
      },
      tableData: [],
      tableData1: [],
      multipleSelection: [],
      regionOptions: [],
      props: {
        label: "areaName",
        children: "children",
        value: "id",
        fullPathName: "fullPathName",
        checkStrictly: true,
        emitPath: false,
      },
      // areaOptions: [
      //   { label: "全部", value: "" },
      //   { label: "100亩以下", value: 0, value2: 100 },
      //   { label: "100亩-300亩", value: 100, value2: 300 },
      //   { label: "300亩-500亩", value: 300, value2: 500 },
      //   { label: "500亩-1000亩", value: 500, value2: 1000 },
      //   { label: "1000亩以上", value: 1000, value2: "" },
      // ],
      typeOptions: [],
      statusOptions: [],
      changeTypeOptions: [
        { label: "出租", value: "0" },
        { label: "入股", value: "1" },
        { label: "转让(农户)", value: "2" },
        { label: "出让(集体)", value: "3" },
      ],
      shTypes: [
        {
          label: "审批中",
          value: "1",
        },
        {
          label: "没有人审批",
          value: "0",
        },
        {
          label: "审批结束",
          value: "3",
        },
        {
          label: "退回",
          value: "2",
        },
      ],
      withdrawlist: [], //撤回列表
      currentPage: 1,
      pageSize: 10,
      total: 0,
      viewlist: [], // 查看
      dialogVisible: false, //导出合同组件显示判断条件
      dialogVisible1: false, //导出合同组件显示判断条件
      dialogVisible3: false, //打起流转审批组件显示判断条件
      payInfo: {},
      status: 0,
      msg: "",
      fileList: [],

      visible: false,
      pzTitle: "",
      examineVisible: false,
      examineInfo: null,
      picLoading: false,
      noneBtnImg: false,
      UpLook: false,
      current: {},
      contractInfo: null,
      contractArray: [],
      selectRow: {},
      isShow: false,
      isTransactWrite: false,
      workFlowId: "",
    };
  },
  computed: mapGetters(["userInfo", "identityObj"]),
  created() {

    this.initData();
    // this.getRegions();
    this.getTypes();
    if (this.$route.query.c) {
      console.log(this.$route.query.c);
      this.getResult({ bzjId: this.$route.query.c });
    }

  },
  // mounted(){
  //   // if(this.isState==true){
  //   //   // this.selectNoApproval()
  //   //   this.submit()
  //   // }
  // },
  methods: {
    calculateTotalFlowAreaMu(list) {
      // 计算所有 flowAreaMu 的总和
      if (!Array.isArray(list)) {
        console.error("参数必须是一个数组");
        return 0;
      }

      return list.reduce((sum, item) => {
        // 检查每个 item 的 flowAreaMu 是否为数字，避免计算错误
        if (typeof item.flowAreaMu === "number") {
          return sum + item.flowAreaMu;
        }
        console.warn(`无效的 flowAreaMu 值: ${item.flowAreaMu}`);
        return sum; // 如果不是数字，则不加这个值
      }, 0);
    },
    calculatecjzj(list) {
      // 计算所有 cjzj 的总和
      if (!Array.isArray(list)) {
        console.error("参数必须是一个数组");
        return 0;
      }

      return list.reduce((sum, item) => {
        // 检查每个 item 的 cjzj 是否为数字，避免计算错误
        if (typeof item.cjzj === "number") {
          return sum + item.cjzj;
        }
        console.warn(`无效的 cjzj 值: ${item.cjzj}`);
        return sum; // 如果不是数字，则不加这个值
      }, 0);
    },

    // 撤回列表
    async withdraw(row) {
      console.log(row.workFlowId);
      this.dialogVisible = true;
      let res = await selectWithdrawXmxx({
        workFlowId: row.workFlowId,
      });
      this.workFlowId = row.workFlowId;
      this.withdrawlist = res.data;
      console.log(res);
    },
    // 撤销提交
    async withdrawSubmit(row) {
      // let ids = this.multipleSelection.map((item) => item.workFlowId);
      // console.log(ids);
      let res = await submitWithdraw({
        workFlowId: this.workFlowId,
        xmbhList: this.multipleSelection.map((item) => item.xmbh),
      });
      console.log(res);
      if (res.code == 0) {
        this.$message.success("撤销成功");
        this.dialogVisible = false; //关闭弹窗
        this.workFlowId = "";
        this.multipleSelection = [];
        this.initData();
      } else {
        this.$message.error("撤销失败");
      }
    },

    // 查看
    async chakan(row) {
      this.dialogVisible1 = true;
      let res = await selectMyApprovalRecord({
        workFlowId: row.workFlowId,
      });
      const newData = Object.keys(res.data.groupApprovalList).map((key) => {
        return {
          list: res.data.groupApprovalList[key],
        };
      });
      const newData1 = Object.keys(res.data.endList).map((key) => {
        return {
          list: [res.data.endList[key]],
        };
      });
      this.viewlist = newData.concat(newData1);
      // this.viewlist =  this.viewlist.list.push(res.data.endList);
      console.log(newData);
      console.log(newData1);
      console.log(this.viewlist);
    },
    // 获取流转类型
    getLzlx(type) {
      let res = "";
      if (type == 0) {
        res = "出租";
      } else if (type == 1) {
        res = "入股";
      } else if (type == 2) {
        res = "转让";
      } else if (type == 3) {
        res = "出让";
      }
      return res;
    },
    // 获取产权类型中文
    getCqlx(type) {
      let res = "";
      this.typeOptions.map((x) => {
        if (x.value == type) {
          res = x.label;
        }
      });
      return res;
    },
    // 关闭弹框
    closeDialog3() {
      this.isShow = false;
      this.initData();
    },
    closeDialog4() {
      this.isTransactWrite = false;
      this.initData();
    },
    // dialogClose(val) {
    //   // 关闭弹窗
    //   this.dialogVisible = val;
    // },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        this.viewlist=[]
        })
        .catch((_) => {});
    },

    toggleSelection(rows) {

      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },

    // 清除关闭
    clearSelection() {
      (this.dialogVisible = false), this.$refs.multipleTable.clearSelection();

    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
    },

    async initData() {
      // 获取数据
      this.tableData = [];
      let params = {
        startTime: this.form.startTime,
        endTime: this.form.endTime,
        lzlx: this.form.lzlx,
        cqlxbh: this.form.cqlxbh,
        status: this.form.status,
        current: this.currentPage,
        size: this.pageSize,
      };
      let res = await selectApprovalRecord(params);
      let { code, data, message } = res;
      if (data) {
        this.tableData = data.records;
        console.log("tableData", this.tableData);
        this.total = parseInt(data.total);
        // this.currentPage = parseInt(data.pages) ? parseInt(data.pages) : 1;
        // this.pageSize = parseInt(data.size);
      }
    },
    // 条件查询
    query() {
      this.currentPage = 1;
      this.initData();
    },
    // 清空查询条件
    clear() {
      this.currentPage = 1;
      this.form = {
        status: "",
        cqlxbh: "",
        lzlx: "",
        cjTime: "",
      };
      this.initData();
    },

    // 切换页码
    currentChange(val) {
      this.currentPage = val;
      this.initData();
    },
    // handelSelectChange(val) {
    //   // 选择发生变化
    //   this.initData();
    // },
    // handleAreaChange(val) {
    //   // 面积发生变化
    //   let obj = this.areaOptions.filter((item) => item.value === val)[0];
    //   this.form.maxArea = obj.value2;
    //   this.initData();
    // },
    // handleBlur(val) {
    //   // 输入框失去焦点时发生变化
    //   this.initData();
    // },
    handleRowClass({ row, rowIndex }) {
      // 行樣式
      if (rowIndex % 2 === 0) {
        return "odd";
      }
    },
    // async getStatus() {
    //   // 判断认证状态
    //   let res = await getCertificateStatus(this.userInfo.username);
    //   let { status, data, msg } = res;
    //   if (status) {
    //     this.status = data;
    //     this.msg = msg;
    //   }
    // },
    // 获取产权类型
    getTypes() {
      let cqList = JSON.parse(localStorage.getItem("types"));
      console.log(cqList);

      if (cqList && cqList.length > 0) {
        this.typeOptions = cqList.map((x) => {
          return {
            label: x.lx,
            value: x.bh,
          };
        });
      }
    },
    showDetail(row, index) {
      console.log(row,'查看相应的数据是什么');
      // 查看项目详情
      // this.$router.push({ name: "lzsp", query: { c: row.xmbh } });
      this.$router.push({
        name: "lzsp",
        query: {
          c: row.xmbh,
          approvalId:row.approvalId,
          isUpTyp: "afterUp",
          workFlowId:row.workFlowId

        },
      });
    },
    //发起流转审批
    submit() {
      this.isShow = true;
    },
    // 办理
    transact(obj) {
      console.log(obj);
      this.isTransactWrite = true;
      this.$nextTick(() => {
        this.$refs.transactWrite.init(obj);
      });
    },
  },
};
</script>

<style lang="less" scoped>
span{
  cursor: pointer; /* 鼠标悬停时变成小手 */
}
.ybmxm {
  // width: 1400px;
  text-align: left;
  height: 100%;
  position: relative;
  // margin-top: 46px;

  .s-title {
    .title {
      margin-left: 10px;
      font-size: 15px;
      font-weight: 600;
    }
  }
  .project {
    // margin-top: 38px;
    .table-con {
      margin-top: 12px;
      padding: 0 15px 0 18px;
    }
  }
  .news {
    margin-top: 33px;
  }
  .pagenation {
    // margin-top: 0;
    // position: absolute;
    // right: 20px;
    // bottom: 33px;
    text-align: right;
  }
}
// .query-form {
// display: flex;
// .el-form-item {
// display: flex;
// align-items: center;
// .el-form-item__content {
//   width: 100px;
// }
// }
// .query-btn {
// width: 70px;
// }
//}
</style>

<style lang="less">
.ybmxm {
  .el-dialog__wrapper {
    position: absolute;
  }
  .v-modal {
    position: absolute;
  }
  .el-checkbox-button__inner {
    width: 160px;
    border-left: 1px solid #dcdfe6;
  }
  .el-dialog--center .el-dialog__body {
    padding: 25px 60px 30px 38px;
  }
  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    border-radius: 5px;
    border: solid 1px #00a0e9;
    background: #fff;
    color: #00a0e9;
  }
  .el-dialog__footer {
    padding: 10px 20px 45px;
  }
}
.disUoloadSty .el-upload--picture-card {
  display: none; /* 上传按钮隐藏 */
}
</style>
