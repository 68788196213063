<template>
  <card title="成果展示" :withbg="true">
    <el-row class="p-achieve-list" :gutter="20">
      <el-col :span="6" v-for="item of showList" :key="item.code">
        <div class="achieve-col">
          <p><span class="num">{{ item.num }}</span> <span class="unit">{{ item.unit }}</span></p>
          <p class="dec">
            <img :src="item.icon" />
            {{ item.name }}
          </p>
        </div>
      </el-col>
    </el-row>
  </card>
</template>

<script>
  import Card from "@/components/Card/index.vue";
  // import { getCGZSInfo } from "@/api/financialService.js";
  import { getSLZT, SelectCompanyData } from "@/api/displayResults.js";
  import { mapGetters } from "vuex";
  const showList = [
    // {
    //   code: 1,
    //   num: 0,
    //   unit: '家',
    //   icon: require('@/assets/icon-show-11.png'),
    //   name: '累计放款'
    // },
    // {
    //   code: 2,
    //   num: 0,
    //   unit: '万元',
    //   icon: require('@/assets/icon-show-3.png'),
    //   name: '累计发放贷款'
    // },
    {
      code: 3,
      num: 0,
      unit: '家',
      icon: require('@/assets/icon-show-3.png'),
      name: '新型经营主体'
    },
    {
      code: 4,
      num: 0,
      unit: '家',
      icon: require('@/assets/icon-show-4.png'),
      name: '红名单主体'
    },
    // {
    //   code: 5,
    //   num: 1,
    //   unit: '家',
    //   icon: require('@/assets/icon-show-12.png'),
    //   name: '金融机构'
    // },
    // {
    //   code: 6,
    //   num: 4,
    //   unit: '个',
    //   icon: require('@/assets/icon-show-13.png'),
    //   name: '累计收录金融产品'
    // },
    {
      code: 7,
      num: 0,
      unit: '家',
      icon: require('@/assets/icon-show-6.png'),
      name: '累计评定信用主体'
    },
    {
      code: 8,
      num: 0,
      unit: '家',
      icon: require('@/assets/icon-show-7.png'),
      name: '黑名单主体'
    },
  ];

  export default {
    components: {
      Card,
    },
    computed: mapGetters(["topSelectAreacode"]),
    data() {
      return {
        showList
      }
    },
    mounted() {
      this.init();
    },
    methods: {
      init() {
        // this.getJRInfo();
        // this.getZT();
        this.getTwo()
      },
      // async getJRInfo(){
      //   let res=await getCGZSInfo();
      //   if(!res||res.code!=0){
      //     return;
      //   }
      //   let data=res.data;
      //   this.showList.forEach(item => {
      //     switch (item.code) {
      //       case 1:
      //         item.num=data.totalCount? data.totalCount:0;
      //         break;
      //         case 2:
      //         item.num=data.totalLoanAmount? data.totalLoanAmount:0;
      //         break;
      //         case 5:
      //         item.num=data.bankNum? data.bankNum:0;
      //         break;
      //         case 6:
      //         item.num=data.productCount? data.productCount:0;
      //         break;
      //     }
      //   });
      // },
      // async getZT() {
      //   let res = await getSLZT({
      //     areaCode:this.topSelectAreacode
      //   });
      //   if (!res || res.code != 0) {
      //     return;
      //   }
      //   let data = res.data;
      //   this.showList.forEach((item) => {
      //     switch (item.code) {
      //       case 4:
      //         item.num = data.redListTotal ? data.redListTotal : 0;
      //         break;
      //       case 3:
      //         item.num = data.total ? data.total : 0;
      //         break;
      //       case 8:
      //         item.num = data.blackListTotal ? data.blackListTotal : 0;
      //         break;
      //         case 7:
      //         item.num = data.totalEvaluated ? data.totalEvaluated : 0;
      //         break;
      //     }
      //   });
      // },
      async getTwo() {
        let res = await SelectCompanyData({
          areaCode: this.topSelectAreacode
        })

        if (res.code == 0) {
          this.showList[2].num = res.data ? res.data : 0
        }
      }
    }
  };
</script>

<style lang="less" scope>
  .p-achieve-list {
    height: auto;

    .achieve-col {
      background: #fff;
      border: 1px solid #DCDCDC;
      padding: 10px 0px 20px 50px;
      margin: 15px 0;

      p {
        padding: 0;
        margin: 0;
      }

      .num {
        display: inline;
        max-width: 280px;
        font-size: 48px;
        color: #333;
        font-family: "DIN-Alternate-Bold";
      }

      .dec {
        font-size: 18px;
        color: #666;
        display: flex;
        align-items: center;

        img {
          margin-right: 6px;
        }
      }
    }

  }
</style>