<template>
  <div class="wytg">
    <HeTong ref="hetong" v-if="showHt" :currentItem="currentItem" :currentCode="currentCode" />
    <ShowDetail v-if="showDetail" @closeDialog2="closeDialog2" ref="showDetail" />
    <upLook v-if="UpLook" @closeDialog3="closeDialog3" ref="UpLook" />
    <div class="form-list">
      <div class="table-search">
        <h3>已接管土地</h3>
        <!-- 方便找页面 后期可能删除 -->
        <FormQuery ref="formquery" :data="Query" :key="keyForm" @submit="search" @reset="reset">
        </FormQuery>
      </div>
      <div class="table-content" style="height:auto">
        <table-page :data="tableData" :columns="columns" :currentPage="query.pnum" :pageSize="query.psize"
          :total="total" :border="true" @sizeChange="handleSizeChange" @currentChange="handleCurrentChange">
          <template slot="orderType" slot-scope="scope">
            <span>{{
              scope.row.orderType == 1 ? "全程托管" : "环节托管"
              }}</span>
          </template>
          <template slot="Time" slot-scope="scope">
            <span>{{
              scope.row.startDate ? scope.row.startDate.substr(0, 10) : ""
              }}~{{
              scope.row.endDate ? scope.row.endDate.substr(0, 10) : ""
              }}</span>
          </template>
          <template slot="orderState" slot-scope="scope">
            <span v-if="scope.row.orderState == '00'">未接管</span>
            <span v-if="scope.row.orderState == '01'">接管中</span>
            <span v-else>接管结束</span>
          </template>
          <template slot="operation" slot-scope="scope">

            <el-button type="text" size="small" @click="detailClick(scope.row)">查看</el-button>
            <el-button type="text" size="small" @click="goout(scope.row)">导出合同</el-button>
            <el-button type="text" size="small" @click="upLook(scope.row,1)">上传合同</el-button>
            <el-button v-if=" scope.row.picUrl&&scope.row.picUrl.length>5 " type="text" size="small"
              @click="upLook(scope.row,0)">查看合同</el-button>
            <!-- <el-button type="text" size="small" @click="upLook(scope.row)">{{
              scope.row.status == 0 ? "上传" : "照片"
            }}</el-button> -->

          </template>
        </table-page>
      </div>
    </div>
  </div>
</template>
<script>
  import ShowDetail from "./showDetail";
  import FormQuery from "@/components/form/form-search";
  import TablePage from "@/components/table/table-page";
  import { yjgtdColumn } from "../tableColumn";
  import { takenOrderToPage } from "@/api/projectManage";
  import { getXzqArr } from "@/api/add";
  import { removeEmptyChildren } from "@/js/utils/util";
  import { mapGetters } from "vuex";
  // import { getXzqhInfoToCity } from "@/api/landTrustStatistics";
  import HeTong from "../../../quickLink/hetong.vue";
  import { getPersonalXmxxByPg } from "@/api/trustManagement";
  //上传合同照片组件
  import upLook from "./upContractPhotos.vue";
  const Query = [
    {
      name: "areaCode",
      label: "地区：",
      componentType: "cascader",
      placeholder: "请选择",
      width: "180px",
      props: {
        label: "areaName",
        children: "children",
        value: "id",
        checkStrictly: true,
      },
      dataOptions: [],
    },
    {
      name: "orderType",
      label: "托管类别：",
      componentType: "select",
      placeholder: "请选择",
      width: "160px",
      dataOptions: [
        {
          name: "全程托管",
          value: 1,
        },
        {
          name: "环节托管",
          value: 2,
        },
      ],
    },
    {
      name: "orderState",
      label: "状态：",
      componentType: "select",
      placeholder: "请选择",
      width: "160px",
      dataOptions: [
        {
          name: "待成交",
          value: "00",
        },
        {
          name: "待服务",
          value: "01",
        },
        {
          name: "待结束",
          value: "02",
        },
        {
          name: "待支付",
          value: "03",
        },
      ],
    },
    {
      name: "cropCode",
      label: "作物类型：",
      componentType: "input",
      inputType: "",
      placeholder: "",
      width: "160px",
    },
  ];
  export default {
    components: {
      FormQuery,
      TablePage,
      ShowDetail,
      HeTong,
      upLook,
    },
    filters: {
      name1Filter(value) {
        return value == 1 ? "全程托管" : "环节托管";
      },
    },
    data() {
      return {
        showDetail: false,
        keyForm: 1, //下拉框为请求来的数据时监听会用到
        form: {},
        Query: Query,
        query: {
          pnum: 1,
          psize: 10,
        },
        total: 0,
        tableData: [],
        columns: yjgtdColumn,
        options: [
          { value: 0, label: "已成交" },
          { value: 1, label: "未成交" },
        ],
        currentCode: 2,
        showHt: false,
        currentItem: {},
        UpLook: false,
      };
    },
    // watch: {
    //   keyForm(n, o) {
    //     console.log("keyForm", n);
    //   },
    // },
    created() {
      this.getRegions();
      this.getList();
    },
    computed: mapGetters(["identityObj"]),
    methods: {
      closeDialog2() {
        this.showDetail = false;
      },
      // 详情点击
      detailClick(val) {
        this.showDetail = true;
        this.$nextTick(() => {
          this.$refs.showDetail.init(val);
        });
      },
      // 关闭弹框
      async closeDialog3() {
        this.UpLook = false;
        this.getList();
      },
      //上传及查看合同照片
      async upLook(val, isUp) {

        this.UpLook = true;
        this.$nextTick(() => {
          this.$refs.UpLook.Init(val, isUp);
        });
        this.getList();
      },
      async getRegions() {
        // 获取地区信息
        let res = await getXzqArr({
          level: 3,
        });
        if (res.code == 0) {
          let hasRegion = setInterval(() => {
            this.Query[0].dataOptions = removeEmptyChildren(res.data);
            this.keyForm++;
            clearInterval(hasRegion);
          }, 100);
        }
      },
      // 分页点击
      handleCurrentChange(page) {
        this.query.pnum = page;
        this.getList();
      },
      // 分页改数量点击
      handleSizeChange(size) {
        this.query.pnum = 1;
        this.query.psize = size;
        this.getList();
      },
      // 获取列表
      async getList() {
        let res = await takenOrderToPage(this.query);
        if (!res.code) {
          this.tableData = res.data;
          this.total = res.dataNum;
        } else {
          this.$message.error("查询失败");
        }
      },
      // 条件搜索
      search(params) {
        console.log(params);
        let params2 = {
          areaCode: params.areaCode
            ? params.areaCode[params.areaCode.length - 1]
            : null,
          orderState: params.orderState,
          cropCode: params.cropCode,
          orderType: params.orderType,
          pnum: 1,
        };
        this.query = { ...this.query, ...params2 };
        this.getList();
      },
      // 条件重置
      reset() {
        this.query = {
          pnum: 1,
          psize: 10,
        };
        this.getList();
      },
      // 导出合同
      async goout(currentItem) {
        this.currentItem = currentItem;
        if (!this.identityObj.identityTypeCode) {
          this.$message.info("请登录后进行操作");
          return;
        }
        this.showHt = true;
        setTimeout(() => {
          this.$refs.hetong.viewHt2();
        }, 100);
        // }
      },
    },
  };
</script>
<style lang="less" scoped>
  .form-list {
    .table-search {
      padding: 0 0 0 30px;

      /deep/.el-button {
        padding: 10px 20px !important;
      }

      .tools {
        margin-bottom: 18px;
        margin-top: 6px;
      }
    }
  }
</style>