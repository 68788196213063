<template>
  <div class="user-login">
    <div class="login-form">
      <h2>农户实名认证</h2>
      <el-form ref="form" :model="form" label-width="160px" :rules="formRules">
        <el-form-item label="身份证人像面" prop="cardFront">
          <div class="upload">
            <div class="upload-box">
              <el-upload
                v-loading="loading"
                :element-loading-text="lo == 1 ? '努力识别中' : '上传中'"
                ref="front"
                action=""
                :auto-upload="false"
                :show-file-list="false"
                :file-list="fileList"
                name="files"
                :before-upload="beforeUpload"
                list-type="picture-card"
                :on-change="
                  (file, filelist) => {
                    handleChange(file, filelist, '0');
                  }
                "
              >
                <img
                  slot="default"
                  :src="imgUrl[0] ? imgUrl[0] : 'imgs/card-1.png'"
                  alt=""
                />
              </el-upload>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="身份证国徽面" prop="cardBack">
          <div class="upload">
            <div class="upload-box">
              <el-upload
                ref="back"
                action=""
                :auto-upload="false"
                :show-file-list="false"
                :on-remove="
                  (file, filelist) => {
                    handleRemove(file, filelist, '1');
                  }
                "
                :file-list="fileList1"
                :before-upload="beforeUpload"
                list-type="picture-card"
                name="files"
                :on-change="
                  (file, filelist) => {
                    handleChange(file, filelist, '1');
                  }
                "
              >
                <img
                  slot="default"
                  :src="imgUrl[1] ? imgUrl[1] : 'imgs/card-2.png'"
                  alt=""
                />
              </el-upload>
            </div>
            <p style="color: #ff6633; margin: 0">
              (特别提示：照片必须将身份证四个边框完整拍入)
            </p>
          </div>
        </el-form-item>
        <el-form-item label="真实姓名" prop="realName">
          <el-input placeholder="" v-model="form.realName"> </el-input>
        </el-form-item>
        <el-form-item label="身份证号码" prop="cardId">
          <el-input placeholder="" v-model="form.cardId"> </el-input>
        </el-form-item>

        <el-form-item label="所在地" prop="areaCode">
          <el-cascader
            ref="address"
            v-model="value"
            :options="options"
            :props="props"
            style="width: 49%; margin-right: 2%"
            @change="cityChange"
          ></el-cascader>
          <el-cascader
            v-model="value2"
            ref="address2"
            :options="options2"
            :props="props"
            @change="roadChange"
            style="width: 49%"
          ></el-cascader>
          <div class="el-form-item__error" v-show="areaHasChild">
            请选到最后一级
          </div>
        </el-form-item>
        <el-form-item label="银行卡号" prop="bankCardId">
          <el-input
            style="width: 258px"
            placeholder=""
            v-model="form.bankCardId"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="所属银行">
          <el-input
            style="width: 258px"
            placeholder=""
            v-model="form.affiliatedBank"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="银行预留手机号" prop="bankCardPhone">
          <el-input
            style="width: 258px"
            v-model="form.bankCardPhone"
          ></el-input>
          <el-button
            class="btn"
            type="primary"
            @click="getSmsCode"
            :disabled="flag"
            style="padding: 10px; margin-left: 10px"
            >{{ flag ? `（${current}） s` : "发送验证码" }}</el-button
          >
        </el-form-item>
        <el-form-item label="银行手机验证码" prop="smsCode">
          <el-input
            style="width: 258px"
            v-model="form.smsCode"
            placeholder="请输入短信验证码"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-button
          type="success"
          @click="onSubmitOther()"
          style="padding: 12px 100px; margin-top: 20px"
          >提交</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { uploadFile, deleteFile } from "@/api/fileApi";
import { getXzqArr, getIdentityList } from "@/api/add";
import { getSmsCodeNew, farmerCertificate, getsfz } from "@/api/certificate";
import { bankCardAttribution } from "./index";
import { removeEmptyChildren } from "@/js/utils/util";
import { mapGetters } from "vuex";
export default {
  data() {
    var checkArea = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("所在地不为空"));
      } else {
        setTimeout(() => {
          if (this.areaHasChild) {
            callback(new Error("请选到最后一级"));
          } else {
            callback();
          }
        }, 100);
      }
    };
    var validateIdNumber = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入身份证号码"));
      } else if (!/(^\d{15}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
        callback(new Error("身份证号码格式不正确"));
      } else {
        callback();
      }
    };

    return {
      lo: 0,
      loading: false,
      fileList1: [],
      fileList: [],
      baseUrl: window.globalUrl.BASE_API,
      areaHasChild: false,
      keyForm: 0,
      regionOptions: [{}],
      props: {
        label: "areaName",
        children: "children",
        value: "id",
        checkStrictly: true,
        expandTrigger: "hover",
        leaf: "isLeaf",
      },
      form: {
        realName: "",
        cardId: "",
        cardFront: "",
        cardBack: "",
        areaCode: "",
        bankCardId: "",
        bankCardPhone: "",
        smsCode: "",
        affiliatedBank: "",
        id: "",
      },
      uploadUrl: `${window.globalUrl.HOME_API_WEI}/trade-website/cqjyService/upLoadCqpzImages`, // 产权凭证,
      fileList: [],
      cropType: [],
      opertion: [],
      areaType: [],
      formRules: {
        realName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        cardId: [
          { required: true, validator: validateIdNumber, trigger: "blur" },
        ],
        cardFront: [
          { required: true, message: "请上传身份证人像面", trigger: "blur" },
        ],
        cardBack: [
          { required: true, message: "请上传身份证国徽面", trigger: "blur" },
        ],
        // areaCode: [{ required: true, validator: checkArea, trigger: "change" }],
        areaCode: [{ required: true, message: " ", trigger: "change" }],
        bankCardId: [
          { required: true, message: "请输入银行卡号", trigger: "blur" },
          // { pattern: /^\d{19}$/, message: "请输入19位数字", trigger: "blur" },
        ],

        bankCardPhone: [
          { required: true, message: "请输入银行预留手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
      },
      value: [],
      options: [],
      imgUrl: ["", ""], //身份证人像页的本地图片url
      imgInfo: [undefined, undefined], //身份证人像页的上传返回数据
      flag: false, //以下四个为发送验证码倒数60秒相关
      max: 60,
      current: 60,
      decreseInterval: null,
      options2: [],
      value2: null,
    };
  },
  created() {
    this.getRegions();
    // console.time("timer");
    // 执行一些耗时操作

    // console.log(" this.bankCardAttribution", bankCardAttribution("621700"));
    // console.timeEnd("timer");
    // 输出执行时间

    if (this.$route.params.item) {
      // 接收路由参数
      console.log("route-item", this.$route.params);
      this.form.id = this.$route.params.item.identityId ? this.$route.params.item.identityId : "";
      this.form.realName = this.$route.params.item.identityName
        ? this.$route.params.item.identityName
        : "";
      this.form.cardId = this.$route.params.item.identityCode
        ? this.$route.params.item.identityCode
        : "";
      // this.form.areaCode = this.$route.params.item.areaCode;

      this.form.bankCardPhone = this.$route.params.item.contactPhone
        ? this.$route.params.item.contactPhone
        : "";
      // 身份证照片
      this.form.cardFront = this.$route.params.item.userCardFrontImg
        ? this.$route.params.item.userCardFrontImg
        : "";
      this.form.cardBack = this.$route.params.item.userCardBackImg
        ? this.$route.params.item.userCardBackImg
        : "";
      this.imgUrl[0] = this.$route.params.item.userCardFrontImg
        ? this.baseUrl +
          "admin/file/get?ossFilePath=" +
          this.$route.params.item.userCardFrontImg
        : "";
      this.imgUrl[1] = this.$route.params.item.userCardBackImg
        ? this.baseUrl +
          "admin/file/get?ossFilePath=" +
          this.$route.params.item.userCardBackImg
        : "";
      this.imgInfo[0] = this.form.cardFront;
      this.imgInfo[1] = this.form.cardBack;
      this.form.bankCardId = this.$route.params.item.bankCardId;
      if (this.form.bankCardId.length >= 5) {
        const obj = bankCardAttribution(this.form.bankCardId); // 调用查看所属银行
        if (obj != "error") {
          this.form.affiliatedBank = obj.bankName;
        } else {
          this.form.affiliatedBank = "";
        }
      }
    }
  },
  watch: {
    "form.bankCardId": function () {
      if (this.form.bankCardId.length >= 5 && this.form.affiliatedBank == "") {
        const obj = bankCardAttribution(this.form.bankCardId + ""); // 调用查看所属银行
        if (obj != "error") {
          this.form.affiliatedBank = obj.bankName;
        } else {
          this.form.affiliatedBank = "";
        }
      }
    },
  },
  computed: mapGetters(["userInfo", "identityObj"]),
  methods: {
    async cityChange(val) {
      if (val) {
        let params = { parentCode: val[val.length - 1] };
        let res = await getXzqArr(params);
        if (res.code == 0) {
          this.value2 = null;
          this.form.areaCode = val[val.length - 1];
          if (res.data.length > 0) {
            this.options2 = removeEmptyChildren(res.data, "children");
            // this.areaHasChild = true;
          } else {
            this.options2 = [];
            this.areaHasChild = false;
          }
        }
      }
    },
    roadChange(val) {
      let node = this.$refs.address2.getCheckedNodes()[0];
      if (node.children.length == 0) {
        this.areaHasChild = false;
        this.form.areaCode = val[val.length - 1];
      } else {
        this.areaHasChild = true;
        this.form.areaCode = val[val.length - 1];
      }
    },
    regionChange() {
      let node = this.$refs.address.getCheckedNodes()[0];
      // if (node.level == 5) {
      this.form.areaCode = node.value;
      this.form.areaName = node.pathLabels.join("");

      // }
    },
    async handleChange(file, filelist, type) {
      let imgIndex = type * 1; //在数组中的索引
      if (this.beforeUpload(file.raw, imgIndex)) {
        if (type == 0) {
          this.loading = true;
        }

        if (this.imgInfo[imgIndex]) {
          //更换新的照片时需要删除旧照片
          this.deleteFile(this.imgInfo[imgIndex]);
          // this.imgInfo[imgIndex] = undefined;
          // this.imgUrl[imgIndex] = undefined;
        }
        let res = await this.uploadFile(file.raw, filelist, imgIndex);
      }
    },
    beforeUpload(file, imgIndex) {
      // 文件上传之前的函数
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 6;
      if (!isJPG) {
        this.$message.error("上传图片为 JPG 或 PNG 格式!");
        this.imgInfo[imgIndex] = undefined;
        this.imgUrl[imgIndex] = undefined;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 6MB!");
        this.imgInfo[imgIndex] = undefined;
        this.imgUrl[imgIndex] = undefined;
      }
      return isJPG && isLt2M;
    },
    handleRemove(file, fileList, type) {
      if (type == 1) {
        this.fileList1 = fileList;
        this.deleteFile(file.url2);
      } else {
        this.fileList = fileList;
        this.deleteFile(file.url2);
      }
    },
    async uploadFile(file, fileList, type) {
      let imgIndex = type * 1; //在数组中的索引
      console.log("imgIndex", imgIndex);
      let param = new FormData();
      param.append("catalog", "sfz");
      param.append("file", file);
      let res = await uploadFile(param);
      if (res.code == 0) {
        fileList.forEach((item) => {
          item.url2 = res.data.url;
        });
        if (type == 1) {
          this.fileList1 = fileList;
        } else {
          this.fileList = fileList;
        }
        if (type == 0) {
          this.lo = 1;
          let res1 = await getsfz({ identity: res.data.url });
          // console.timeEnd("myCode");
          console.log("sfz", res1.data.result);
          // 如果是人头面的话执行
          if (res1.data.result[0].note == "back") {
            this.form.realName = res1.data.result[0].detail.name;
            this.form.cardId = res1.data.result[0].detail.idn;
            this.loading = false;
            this.lo = 0;
          }
        }

        // console.time('myCode')

        this.imgUrl[imgIndex] =
          this.baseUrl + "admin/file/get?ossFilePath=" + res.data.url;
        this.imgInfo[imgIndex] = res.data.url;
        console.log(this.imgUrl[1]);
        console.log(this.imgInfo);
      }
    },
    async deleteFile(url) {
      let res = await deleteFile({ ossFilePath: url });
      if (res.code == 0 && res.data == true) {
        console.log("删除成功");
      }
    },
    async getSmsCode() {
      // 获取手机验证码
      if (this.form.bankCardPhone) {
        let param = {
          phone: this.form.bankCardPhone + "",
          type: "4",
        };
        let res = await getSmsCodeNew(param);
        let { code, data, msg } = res;
        if (code == 0) {
          this.$message.success(msg);
          this.current = this.max;
          this.decreseInterval = setInterval(() => {
            if (this.current <= this.max && this.current > 0) {
              this.current--;
              this.flag = true;
            } else if (this.current <= 0) {
              this.current = 0;
              this.flag = false;
              clearInterval(this.decreseInterval);
            }
          }, 1000);
        } else {
          this.$message.error(msg);
        }
      } else {
        this.$message.error("请先输入手机号！");
      }
    },
    async onSubmitOther() {
      this.form.cardFront = this.imgInfo[0];
      this.form.cardBack = this.imgInfo[1];
      this.form.userId =
        localStorage.getItem("loginStep1UserId") || this.userInfo.id;
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let cardId = {
            bossCardFront: this.imgUrl[0],
            bossCardBack: this.imgUrl[1],
          };
          let res = await farmerCertificate(this.form);
          if (res.code == 0) {
            this.$message.success("认证成功!即将跳转至登录页");
            localStorage.clear();
            setTimeout(() => {
              this.$router.push({ name: "login" });
            }, 500);
          } else {
            this.$message.error(res.msg);
          }
        } else {
          this.$message.error("请完善认证信息");
        }
      });
    },
    async loadIdentityList() {
      let res = await getIdentityList();
      if (res.code == 0) {
        if (res.data.length == 1) {
          this.$store.dispatch("SetIdentityObj", res.data[0]);
          this.$router.push({ name: "homepage" });
        } else {
          this.$router.push({ name: "chooseRole" });
        }
      }
    },
    async getRegions() {
      // 获取行政区树
      let res = await getXzqArr({
        level: 3,
      });
      if (res.code == 0) {
        this.options = removeEmptyChildren(res.data, "children");
      }
    },
  },
};
</script>
<style lang="less" scoped>
h2 {
  margin-bottom: 30px;
}
.user-login {
  width: 1200px;
  margin: 0 auto;
  background: #f2f2f2;
  padding: 30px 0;
  .login-form {
    width: 540px;
    padding: 6px 140px 40px 100px;
    background-color: #ffffff;
    margin: 0px auto;
    text-align: center;
    .btn {
      width: 100px;
      height: 40px;
      margin-left: 15px;
    }
  }
}
/deep/.el-form-item__content {
  text-align: left;
}
.upload-box {
  /deep/.el-upload {
    width: 148px !important;
    height: 126px !important;
    img {
      width: 100%;
      height: 100%;
    }
  }
  // .el-upload-list--picture-card .el-upload-list__item {
  //   width: 301px;
  //   height: 261px;
  // }
}
/deep/ .el-loading-mask {
  position: absolute;
  z-index: 2000;
  background-color: rgba(255, 255, 255, 0.9);
  margin: 0;
  top: -32px;
  right: 228px;
  bottom: 0;
  left: 0;
  transition: opacity 0.3s;
}

/deep/.el-loading-spinner .el-loading-text {
  color: #00a0e9;
  margin: -13px 0;
  font-size: 14px;
}
</style>
