<template>
  <el-dialog
  title="资源资产流转审批"
  :visible.sync="dialogVisible"
  width="80%"
  append-to-body
  :before-close="handleClose">


    <div class="screen">
      <el-form :inline="true">
        <el-form-item label="提交时间：">
          <el-date-picker
            style="width: 150px;"
            v-model="form.startTime"
            placeholder="选择开始日期"
            value-format="yyyy-MM-dd"
            clearable
          >
          </el-date-picker>
          至
          <el-date-picker
            style="width: 150px;"
            v-model="form.endTime"
            placeholder="选择结束日期"
            align="right"

            value-format="yyyy-MM-dd"
            clearable
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="流出方">
          <el-input
            style="width: 190px"
            v-model="form.crf"
            placeholder="请输入"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="交易类型">
          <el-select style="width: 190px" v-model="form.lzlx">
            <el-option
              v-for="item in typeOptions"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <div class="button" style="display: inline-block; margin: 0">
          <el-form-item>
            <el-button class="query-btn" type="primary" @click="findBtn"
              >查询</el-button
            >
            <el-button class="query-btn" type="primary" @click="resubmit"
              >重新提交</el-button
            >

          </el-form-item>
        </div>
      </el-form>

    </div>

    <div class="table-content">
      <table-page
         ref="approvalTable"
        :data="tableData"
        :defaultSelectList="defaultSelectList"
        :columns="columns"
        :currentPage="query.pnum"
        :pageSize="query.psize"
        :total="total"
        :loading="loading"
        :border="true"
        :isShow="isShow"
        @sizeChange="handleSizeChange"
        @currentChange="handleCurrentChange"

      >
      <template slot="xmmc" slot-scope="scope">
        <span  @click="showDetail(scope.row)"  style=" color: blue;">{{ scope.row.xmmc }}</span>
      </template>

        <template slot="flowAreaMu" slot-scope="scope">
          <span>{{ scope.row.flowAreaMu }}亩</span>

        </template>

        <!-- scope.row.applyId -->
        <template slot="cjzj" slot-scope="scope">
          <span>{{ scope.row.cjzj }}元</span>
        </template>

        <template slot="lznx" slot-scope="scope">
          <span>{{ scope.row.lznx }}年</span>

        </template>

        <template slot="content" slot-scope="scope">
          <!-- <span>{{ scope.row.zcmj }}万元</span> -->
          <span>{{ scope.row.content}}</span>
        </template>

        <template slot="operation" slot-scope="scope">
          <el-button  @click="HeadersTong(scope.row)"  type="primary"
          >上传合同</el-button
        >
          <!-- <span  style=" color: blue;"></span> -->
        </template>
      </table-page>

      <div style="margin-top:20px;">
        已选条数{{checkedNum? checkedNum :'0'}}条，流转面积{{allNum? allNum :'0'}}亩。
      </div>
    </div>
    <!-- 第二层弹窗 -->
        <el-dialog
      title="如下资源资产交易记录缺少合同归档文件，请上传完成后在提交审批"
      :visible.sync="dialogVisible2"
      width="80%"
      append-to-body
      :before-close="handleClose2">
      <TablePageTwo
      :data="tableData2"
      :columns="columns2"
      >
      <!-- <template slot="operation" slot-scope="scope">
        <el-button type="primary" >{{scope.row.cjzj}}万元</el-button>
      </template>
      <template slot="operation" slot-scope="scope">
        <el-button type="primary" >{{scope.row.flowAreaMu}}</el-button>
      </template> -->
      <template slot="operation" slot-scope="scope">
        <el-button type="primary" @click="HeadersTong(scope.row)">编辑合同归档文件</el-button>
      </template>
      </TablePageTwo>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">关 闭</el-button>
        <el-button type="primary" @click="submit()">提交审批</el-button>
      </span>
    </el-dialog>

  <!-- 第三层弹窗 -->
  <el-dialog
      :title="regionalTitle"
      :visible.sync="dialogVisible3"
      append-to-body
      :before-close="handleClose3">
     <!-- 图片上传 -->
     <el-upload class="upload-demo" ref="AvatarUpload" action=""
     :accept="'.jpg, .JPG, .png, .PNG, .jpeg, .JPEG,'" list-type="picture-card" :auto-upload="false"
     :file-list="fileList" :on-change="upLoadImg" multiple>
     <i slot="default" class="el-icon-plus"></i>
     <div slot="file" slot-scope="{ file }">
       <el-image ref="preview" style="height: 115px"
       class="el-upload-list__item-thumbnail"
       :src="file.url"
       :preview-src-list="innerTableList"
       alt=""
       :initial-index="BigImgIndex"
        />
       <span>{{ file.name }}</span>
       <span class="el-upload-list__item-actions">
         <span v-if="!file.isShow " class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
           <i class="el-icon-zoom-in"></i>
         </span>
         <span  class="el-upload-list__item-delete" @click="handleDownload(file)">
           <i class="el-icon-download"></i>
         </span>
         <span  class="el-upload-list__item-delete" @click="handleRemove(file)">
           <i class="el-icon-delete"></i>
         </span>
       </span>
     </div>
    </el-upload>

  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible3 = false">关闭</el-button>
    <el-button type="primary" @click="save()">保存</el-button>
  </span>
  </el-dialog>

</el-dialog>
</template>

<script>
import { uploadFile } from "@/api/fileApi";
// 图片下载
// import { download } from "@/api/ChargeUp";
// import { uploadContract, selectContractPic } from "@/api/add";
import { selectWithdrawList,reSubmitApproval,uploadApprovalContract,selectBackUpContract } from "@/api/FlowApproval.js";
import { WriteProps,resubmitProps } from "../tableColumn";
import TablePageTwo from "@/components/table/CallForBids-page";
// import TablePage from "@/components/table/Approve-page";
import TablePage from '@/components/table/CallForBids-page'

export default {
  data() {
    return {
        tableData: [],
        tableData2: [],
        isShow:false,//不展示分页，展示统计数据
        defaultSelectList:[],
        columns:WriteProps,
        columns2:resubmitProps,
        keyForm: 1,
        total: 0,
        dialogVisible: true,
        dialogVisible2:false,
        dialogVisible3:false,
        loading: false,
        fileList:[],
        workFlowId:'',//流程id
        innerTableList:[],
        InitObj:[],
        BigImgIndex:0,
        regionalTitle:'',
        baseUrl: window.globalUrl.BASE_API,
        form:{
          startTime:'',
          endTime:'',
          crf:'',
          lzlx:'',

        },
        typeOptions: [
        {
          label: "全部",
          value: '',
        },
        {
          label: "出租",
          value: "0",
        },
        {
          label: "入股",
          value: "1",
        },
        {
          label: "转让",
          value: "2",
        },
        {
          label: "出让",
          value: "3",
        },
        ],
        query:{
          current: 1,
           size: 10,
        },
    };
  },
    components:{
      TablePage,
      TablePageTwo
    },
  computed:{

    allNum() {
    // return this.tableData.reduce((sum, item) => {
    //   // 将 '亩' 去掉，并将其转换为浮点数
    //   // const flowArea = parseFloat(item.flowAreaMu.replace("亩", "")) || 0;
    //     const flowArea=item.flowAreaMu
    //   console.log(item.flowAreaMu, 'item.flowAreaMu'); // 记录原始值
    //   console.log(flowArea, 'result'); // 记录处理后的数值
    //   return sum + flowArea; // 累加
    // }, 0);
      return  this.tableData.reduce((total, item) => total + item.flowAreaMu, 0);
  },
    checkedNum(){
      return   this.tableData.length
    },

  },
  mounted(){

  },

  methods:{

    // 获取传过来的值
  init(obj){
      this.workFlowId=obj.workFlowId
       this.selectWithdrawList()
    },
      // 向后端发送图片
  async  save(){

      let ImgUrl = this.getViewUrl(this.fileList);
      console.log(ImgUrl,'查看ImgUrl')
      let params = {
        contractPicUrl: ImgUrl,
        xmbh: this.InitObj.xmbh,
        xmbsm:  this.InitObj.xmbsm,
        workFlowId: this.InitObj.workFlowId
      };
      let res = await uploadApprovalContract(params);
      if (res.code == 0) {
        this.$message.success(res.msg);
      } else {
        this.$message.error(res.msg);
      }
      this.dialogVisible3 = false
  },
  async upLoadImg(file, fileList) {
      if (file.name.toLowerCase().endsWith("pdf")) {
        // 修改符合条件的文件对象的 url 属性
        file.url =
          "https://tse2-mm.cn.bing.net/th/id/OIP-C.or-hk0X7YoPwI6IzdXK7sgHaHa?w=168&h=180&c=7&r=0&o=5&dpr=1.5&pid=1.7";
        file.isShow = "true";
      }

      let res = await this.upload(file, fileList);
   },
   // 上传
   async upload(file, fileList) {
      // console.log(fileList, "fileList");
      let param = new FormData();
      param.append("file", file.raw);
      let res = await uploadFile(param);
      file.url2 = res.data.url;
      // console.log("file", file);
      this.fileList = fileList;
    },
    handleClose(){
      this.$emit("closeDialog4", false);
    },
    handleClose2(){
      this.dialogVisible2=false
    },
    handleClose3(){
      this.dialogVisible3=false
    },
    handleCurrentChange(page) {
       this.query.pnum = page;
          // this.ApplicationRecordList();
    },
       //分页总页数切换
    handleSizeChange(size) {
         this.query = {
         pnum: 1,
         psize: size,
    };
                // this.ApplicationRecordList();
    },

    // 查询
    findBtn(){
      this.selectWithdrawList()
    },

    handSelect(){

    },
    // 重新提交
   async resubmit(){
      let xmbhList=[]
      let xmbsmList=[]
      console.log(this.tableData,'查看this.tableData.')
  // 遍历表格数据，提取所有的 xmbh 和 xmbsm
      this.tableData.forEach(item => {
            xmbhList.push(item.xmbh); // 将 xmbh 添加到 xmbhList 中
            xmbsmList.push(item.xmbsm); // 将 xmbsm 添加到 xmbsmList 中
        });



      let params={
        xmbhList,
        xmbsmList,
        workFlowId:this.workFlowId
      }
      let res = await reSubmitApproval(params)
      // this.dialogVisible2=true
      if(res.code==0){
        this.$message.success(res.msg);
       this.handleClose()
        // this.selectWithdrawList()

      }else{
        this.dialogVisible2=true
        this.tableData2=res.data
      }

    },
       // 改变回显的样式
   getViewUrl1(arr) {
      if(arr){
        arr.forEach((item) => {
        item.name = item.FilesName;
        item.url = this.baseUrl + "admin/file/get?ossFilePath=" + item.imgUrl;
        item.url2 = item.imgUrl;
      });
      this.fileList = arr;
      }
      console.log(  this.fileList,'查看  this.fileList')
    },
      //上传的图片数组
    getViewUrl(arr) {
      let list = [];
      // console.log(arr, "查看这个arr");
      if(arr){
        arr.forEach((item) => {
        list.push({
          FilesName: item.name,
          imgUrl: item.url2,
          uid: item.uid,
        });
      });
      return JSON.stringify(list);
      }

    },
    //上传合同照片
    async  HeadersTong(obj){
     this.fileList=[]
      this.regionalTitle=obj.xmmc
      this.dialogVisible3=true
      this.InitObj = obj;
      console.log(obj,'查看obj')
      let params = {
          xmbh: obj.xmbh,
          approvalId: obj.approvalId,


        };
        let res = await selectBackUpContract(params);
        if(res.data.contractPicUrl){
          console.log('这里执行了对吧')
         this.getViewUrl1(JSON.parse(res.data.contractPicUrl));
        }else{

        }

    },

     //删除
    handleRemove(file, fileList) {
      //可用版本1
      // console.log(file, fileList);
      const index = this.fileList.findIndex((item) => item.uid === file.uid);
      this.fileList.splice(index, 1);
    },
    //放大
    handlePictureCardPreview(file) {
      // console.log(file,'查看file')
      this.BigImgIndex= this.fileList.findIndex(item => item.uid === file.uid);
      // console.log( this.fileList,'查看fileList')
      let abc = [];
      this.fileList.forEach((its) => {
        if (!its.isShow) {
          abc.push(its.url);
        }
      });
      this.innerTableList = abc;
      this.$nextTick(() => {
        this.$refs.preview.clickHandler();
      });
    },
    // 被退回的列表
   async  selectWithdrawList(){
    let params={
      current:this.query.current,
        size:this.query.size,
        ...this.form,
        workFlowId:this.workFlowId
    }
    let res= await selectWithdrawList(params)
    if(res.code==0){

      console.log('res.data',res.data)
      if(res.data){
        // console.log(res.data,'查看res.data')
        let newAbc = res.data.flatMap(item =>
        item.twTradeXmxxList.map(subItem => ({
          ...subItem,
          content: item.content // 合并 content
        }))
      );
        console.log(newAbc,'查看newAbc')
        this.tableData=newAbc
      }
    }

    },
    // 第二层审批
   async submit(){
      let xmbhList=[]
      let xmbsmList=[]

      this.tableData.forEach((item,index)=>{
        xmbhList.push(item.xmbh)
      })
      this.tableData.forEach((item,index)=>{
        xmbsmList.push(item.xmbsm)
      })
      let params={
        xmbhList,
        xmbsmList,
      }
      console.log(params,'查看params')
      let res = await reSubmitApproval(params)
      if(res.code==0){
        this.$message.success({
          message:res.msg,
          offset: 85,
        })
      }else{
        this.$message.info({
          message:res.msg,
          offset: 85,
        })
      }
      this.dialogVisible2=true
    },
    // 点击跳转详情
    showDetail(row){
      console.log(row,'查看row``111111111111111')
      this.$router.push({
          name: "lzsp",
          query: {
            c: row.xmbh,
            isUpTyp:'afterUp',
            workFlowId:row.workFlowId,
            approvalId:row.approvalId,
          }
      });
    }

  }
};
</script>
<style lang="less" scoped>
span{
  cursor: pointer; /* 鼠标悬停时变成小手 */
}
.screen{
  padding-top:20px;
}

</style>>

