<template>
  <div class="wytg">
    <AddEdit
      ref="addEdit"
      :editData="editData"
      :visible="showEdit"
      @closeDialog="closeDialog"
    />
    <upLook v-if="UpLook" @closeDialog2="closeDialog2" ref="UpLook" />
    <div class="form-list">
      <div class="table-search">
        <h3>发布产权信息</h3>
        <!-- 方便找页面 后期可能删除 -->
        <el-form
          class=""
          :model="form"
          label-position="right"
          label-width="80px"
          inline
          ref="form"
        >
          <el-form-item label="项目名称">
            <el-input
              style="width: 140px"
              v-model="form.xmmc"
              placeholder="项目名称"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="项目状态">
            <el-select style="width: 140px" v-model="form.xmzt">
              <el-option
                v-for="item in xmzts"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="流转类型">
            <el-select style="width: 140px" v-model="form.lzlx">
              <el-option
                v-for="item in lzlxs"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产权类别">
            <el-select style="width: 140px" v-model="form.cqlx">
              <el-option
                v-for="item in typeOptions"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <div class="button" style="display: inline-block; margin: 0">
            <el-form-item>
              <el-button class="query-btn" type="primary" @click="query"
                >查询</el-button
              >
              <el-button class="query-btn" type="primary" @click="clear"
                >清空</el-button
              >
            </el-form-item>
          </div>
        </el-form>
        <div class="tools">
          <el-button type="success" @click="add">发布新供应</el-button>
        </div>
      </div>
      <div class="table-content">
        <table-page
          :data="tableData"
          :columns="columns"
          :currentPage="query.current"
          :pageSize="query.size"
          :total="total"
          :border="true"
          :loading="loading"
          @sizeChange="handleSizeChange"
          @currentChange="handleCurrentChange"
          @selectionChange="handleSelection"
        >
          <!-- <template slot="flowAreaMu" slot-scope="scope">
            <span>{{
              scope.row.flowAreaMu + (scope.row.mjdw == "0" ? "平方米" : "亩")
            }}</span>
          </template> -->
          <!-- <template slot="lzlx" slot-scope="scope">
            <span>{{ getLzlx(scope.row.lzlx) }}</span>
          </template> -->
          <template slot="fbsj" slot-scope="scope">
            <div v-html="formatDateTime(scope.row.fbsj)"></div>
          </template>

          <!-- <template slot="cqlxbh" slot-scope="scope">
            <span>{{ getCqlx(scope.row.cqlxbh) }}</span>
          </template> -->
          <template slot="name1" slot-scope="scope">
            <span>{{ scope.row.name1 | name1Filter }}</span>
          </template>
          <template slot="cjTime" slot-scope="scope">
            <div v-html="  scope.row.cjTime ?formatDateTime(scope.row.cjTime) : '-' "></div>
            <!-- <span>{{ scope.row.cjTime ? scope.row.cjTime : "-" }}</span> -->
          </template>
          <template slot="cjzj" slot-scope="scope">
            <span>{{ scope.row.cjzj ? scope.row.cjzj : "-" }}</span>
          </template>
          <template slot="jjzt" slot-scope="scope">
            <span v-if="scope.row.jjzt == '0'">已发布</span>
            <span v-else-if="scope.row.jjzt == '1'">待成交</span>
            <span v-else-if="scope.row.jjzt == '2'">{{ scope.row.srf }}</span>
            <span v-else-if="scope.row.jjzt == '3'">流拍</span>
          </template>
          <template slot="operation" slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="detailClick(scope.$index, scope.row)"
              >查看</el-button
            >
            <el-button
              v-if="scope.row.jjzt == '0' || scope.row.jjzt == '1'"
              type="text"
              size="small"
              @click="editClick(scope.$index, scope.row, 'edit')"
              >修改</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.jjzt != '2'"
              size="small"
              @click="delClick(scope.$index, scope.row)"
              >删除</el-button
            >
            <el-button
              v-if="scope.row.jjzt == '2'"
              type="text"
              size="small"
              @click="exportHt(scope.row)"
              >导出合同</el-button
            >
            <el-button
              v-if="scope.row.jjzt == '3'"
              type="text"
              size="small"
              @click="editClick(scope.$index, scope.row, 'reAdd')"
              >重新发起</el-button
            >
            <el-button  v-if="scope.row.jjzt == '2'" type="text" size="small" @click="upLook(scope.row,1)">上传合同</el-button>
            <el-button  v-if="scope.row.jjzt == '2'&& scope.row.picUrl!=null&&scope.row.picUrl!='[]'"  type="text" size="small" @click="upLook(scope.row,0)"> 查看合同</el-button>
          </template>
          <!-- v-if="!scope.row.picUrl==null||!scope.row.picUrl=='[]'"  -->
        </table-page>
      </div>
    </div>
    <exportContract
      :visible="dialogVisible"
      :xmbh="selectRow.xmbh"
      @close="dialogClose"
      :contractInfo="contractInfo"
      :contractArray="contractArray"
    ></exportContract>
  </div>
</template>
<script>
import TablePage from "@/components/table/table-page";
//新增和回显
import AddEdit from "./addEdit";
//审核结果组件
// import exportContract from "../components/exportContract.vue";
import exportContract from "./exportContract.vue";
//上传合同照片组件
import upLook from "../components/upContractPhotos.vue";
import { fbcqxxColumn } from "../tableColumn";
import { getXmxxDetail } from "@/api/exchange";
import { viewContract, checkContract } from "@/api/projectManage";
import { getPersonalXmxxByPg, deleteXmByBh } from "@/api/trustManagement";
import { mapGetters } from "vuex";

export default {
  components: {
    TablePage,
    AddEdit,
    upLook,
    exportContract,
  },
  filters: {
    name1Filter(value) {
      return value == 1 ? "全程托管" : "其它";
    },
  },
  data() {
    return {
      showEdit: false,
      editData: {},
      keyForm: 1, //下拉框为请求来的数据时监听会用到
      form: {
        xmmc: "",
        xmzt: null,
        lzlx: null,
        cqlx: null,
      },
      total: 0,
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      columns: fbcqxxColumn,
      loading: false,
      options: [
        { value: 0, label: "已成交" },
        { value: 1, label: "未成交" },
      ],
      typeOptions: [],
      xmzts: [
        {
          name: "全部",
          value: "",
        },
        {
          name: "待成交",
          value: "1",
        },
        {
          name: "竞标完成",
          value: "2",
        },
        {
          name: "流拍",
          value: "3",
        },
      ],
      dialogVisible: false,
      contractInfo: null,
      contractArray: [],
      selectRow: {},
      lzlxs: [],
      UpLook: false,
    };
  },
  watch: {
    keyForm(n, o) {
      console.log("keyForm", n);
    },
  },
  created() {
    this.getList();
    this.getTypes();
    this.getLzlxs();

  },
  computed: mapGetters(["identityObj"]),
  methods: {
  // 对时间进行换行
    formatDateTime(dateTime) {
   if (!dateTime) return '-';
    const parts = dateTime.split(' ');
    console.log(parts, '查看dataTie是否执行');

    return `${parts[0]}<br/>${parts[1]}`;
  },

    // 获取产权类型
    getTypes() {
      let cqList = JSON.parse(localStorage.getItem("types"));
      this.typeOptions = cqList.map((x) => {
        return {
          label: x.lx,
          value: x.bh,
        };
      });
    },
    // 获取流转类型
    getLzlx(type) {
      let res = "";
      if (type == 0) {
        res = "出租";
      } else if (type == 1) {
        res = "入股";
      } else if (type == 2) {
        res = "转让";
      } else if (type == 3) {
        res = "出让";
      }
      return res;
    },
    // 获取产权类型中文
    getCqlx(type) {
      let res = "";
      this.typeOptions.map((x) => {
        if (x.value == type) {
          res = x.label;
        }
      });
      return res;
    },
    // 关闭弹窗
    closeDialog() {
      this.showEdit = false;
      this.getList();
    },

    dialogClose(val) {
      // 关闭弹窗
      this.dialogVisible = val;
      this.contractInfo=null
      this.contractArray=[]
    },
    // 点击添加
    add() {
      this.$refs.addEdit.type = "add";
      this.editData = {
        bz: "",
        cjdj: null,
        cjzj: null,
        cqlxbh: "",
        cqzsList: [],
        currentIdentity: "",
        dz: "",
        fbsj: "",
        fkfs: "",
        ggjzsj: "",
        ggkssj: "",
        ghtjList: [],
        pgjzList: [],
        gpdj: null,
        gpjg: null,
        jjfd: null,
        jjjzsj: "",
        jpys: 5,
        jybzj: null,
        jyfs: "在线磋商" ,
        llcs: null,
        lxfs: "",
        lxr: "",
        lzlx: null,
        lznx: null,
        mjdw: "1",
        nz: "",
        qtsm: "",
        sfjnbzj: "",
        sfsyzlz: this.identityObj.identityTypeCode == "4" ? "1" : "" || this.identityObj.identityTypeCode == "3" ? "0" :"",  // 根据用户不同来显示
        srf: "",
        srfId: 0,
        unit: null,
        xctpList: [],
        xmbh: "",
        xmbsm: "",
        xmdz: "",
        xmmc: "",
        xmzt: "",
        xz: "",
        xzqbh: "",
        zcmj: null,
        zlzzmList: [],
        farmersList: [
        ], // 农户名单
        lzhdyt:"", // 流转用途
        syzq:'', // 收益周期
        tzfs:'', // 调整方式
        zffs:0, // 支付方式(0 请选择 1-次性支付2分期支付)
        txrq:'', //支付日期填写
        zfsj:'',  // 分期支付的时间(0 当年 1 后一年)
        gffhbz: "",
        fhgjlx: "0",
        fhgjxq: "",
        fhrq: "",
        fhsj: "",
        bdsyqs: "",
        bdsyns: "",
        bdsytzfs: "",
        bdsyrq: "",
        bdsysj: "",
        proportionInvestment:null
      };
      this.showEdit = true;
    },
    handleSelection(selects) {
      console.log(selects);
    },
    handleSelectChange() {
      this.page = 1;
      this.getList();
    },
    // 切换页码
    handleCurrentChange(page) {
      this.currentPage = page;
      this.getList();
    },
    // 切换每页容量
    handleSizeChange(size) {
      this.currentPage = 1;
      this.pageSize = size;
      this.getList();
    },
    // 关闭弹框
    async closeDialog2() {
      this.UpLook = false;
      let params = Object.assign(this.form, {
        current: this.currentPage,
        size: this.pageSize,
      });
      let res = await getPersonalXmxxByPg(params);
      if (!res.code) {
        this.tableData = res.data.records;
        this.total = parseInt(res.data.total);
      } else {
        this.$message.error("查询失败");
      }
    },
    async detailClick(index, data) {
      // 查看项目详情
      this.$router.push({ name: "projectDetail", query: { c: data.xmbh } });
    },
    // 点击编辑
    async editClick(index, row, type) {
      this.$refs.addEdit.type = type;
      let params = {
        xmbh: row.xmbh,
      };
      let res = await getXmxxDetail(params);
      console.log(res);
      console.log(JSON.parse(JSON.stringify( res.data)));
      let { status, data } = res;
      if (status == 200) {
        if(data.farmersList==null){
          data.farmersList=[]
        }
        // data.zffs = data.zffs == "1" ? "一次性支付" : data.zffs == "2" ? "分期支付" : data.zffs =="0" ? "请选择" : "请选择";

        this.editData = data;
        this.showEdit = true;
      }
    },
    // 点击删除
    delClick(index, data) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await deleteXmByBh({ xmbh: data.xmbh });
          if (res.status == 200) {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.getList();
          }
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    // 获取列表数据
    async getList() {
      let params = Object.assign(this.form, {
        current: this.currentPage,
        size: this.pageSize,
      });
      this.loading = true;
      let res = await getPersonalXmxxByPg(params);
      if (!res.code) {
        this.tableData = res.data.records;
        this.total = parseInt(res.data.total);
      } else {
        this.$message.error("查询失败");
      }
      this.loading = false;
    },
    // 根据登录用户类型筛选流转类型
    getLzlxs() {
      if (this.identityObj.identityTypeCode != 3) {
        this.lzlxs = [
          {
            name: "出租",
            value: 0,
          },
          {
            name: "入股",
            value: 1,
          },
          {
            name: "转让",
            value: 2,
          },
        ];
      } else {
        this.lzlxs = [
          {
            name: "出租",
            value: 0,
          },
          {
            name: "入股",
            value: 1,
          },
          {
            name: "出让",
            value: 3,
          },
        ];
      }
    },
    //导出合同
    // async exportHt(row) {
    //   console.log(row, "查看产权类型编号", row);
    //   this.selectRow = row;
    //   if (this.identityObj.identityTypeCode == "3" && row.cqlxbh == "01") {
    //     let resCheck = await checkContract({ type: "0", xmbh: row.xmbh });
    //     let resCheck2 = await checkContract({ type: "1", xmbh: row.xmbh });
    //     if (resCheck.rel && resCheck2.rel) {
    //       let res1 = await viewContract({ type: "0", xmbh: row.xmbh });
    //       let res2 = await viewContract({ type: "1", xmbh: row.xmbh });
    //       this.contractArray = [res1, res2];
    //       this.dialogVisible = true;
    //     } else if (!resCheck.rel) {
    //       this.$message.error(resCheck.message);
    //       return;
    //     }
    //   } else {
    //     let resCheck = await checkContract({ type: "0", xmbh: row.xmbh });
    //     if (!resCheck.rel) {
    //       this.$message.error(resCheck.message);
    //       return;
    //     }
    //     let res = await await viewContract({ type: "0", xmbh: row.xmbh });
    //     if (res) {
    //       let ContractName =
    //         this.getCqlx(row.cqlxbh) + this.getLzlx(row.lzlx) + "合同";
    //       this.contractInfo = [res, ContractName];
    //       this.dialogVisible = true;
    //     }
    //   }
    // },
    async exportHt(row) {
      this.selectRow = row;
      console.log(row, "查看能否查看到类型");
      //cqlxbh产权类型编号
      if (row.currentIdentity == "3" && row.cqlxbh == "01") {
        let resCheck = await checkContract({ type: "0", xmbh: row.xmbh });
        let resCheck2 = await checkContract({ type: "1", xmbh: row.xmbh });
        console.log("4655646",resCheck,resCheck2);
        if (resCheck.rel && resCheck2.rel) {
          let res1 = await viewContract({ type: "0", xmbh: row.xmbh });
          let res2 = await viewContract({ type: "1", xmbh: row.xmbh });
          console.log("res1,res2",res1,res2);
          this.contractArray = [res1, res2];
          this.dialogVisible = true;
        } else if (!resCheck.rel) {
          this.$message.error(resCheck.message);
          return;
        }
      } else {
        let resCheck = await checkContract({ type: "0", xmbh: row.xmbh });
        console.log("resCheck",resCheck);
        if (!resCheck.rel) {
          this.$message.error(resCheck.message);
          return;
        }
        let res = await await viewContract({ type: "0", xmbh: row.xmbh });
        console.log("res111",res);
        if (res) {
          this.contractInfo = res;
          this.dialogVisible = true;
        }
      }
    },
    // 条件查询
    query() {
      this.getList();
    },
    // 清空查询条件
    clear() {
      this.form.xmmc = "";
      this.form.xmzt = null;
      this.form.lzlx = null;
      this.form.cqlx = null;
      this.getList();
    },
    //上传合同照片
    async upLook(obj,isUp) {
      // console.log(isUp,'查看这里有吗')
      this.UpLook = true;
      this.$nextTick(() => {
        this.$refs.UpLook.Init(obj,isUp);
      });
      let params = Object.assign(this.form, {
        current: this.currentPage,
        size: this.pageSize,
      });
      let res = await getPersonalXmxxByPg(params);
      if (!res.code) {
        this.tableData = res.data.records;
        this.total = parseInt(res.data.total);
      } else {
        this.$message.error("查询失败");
      }
    },
    submit() {},
    reset() {},
  },
};
</script>
<style lang="less" scoped>
.form-list {
  .table-search {
    padding: 0 0 0 30px;
    /deep/.el-button {
      padding: 10px 20px !important;
    }
    .tools {
      margin-bottom: 18px;
      margin-top: 6px;
    }
  }
  .table-content {
    padding: 0 30px;
  }
}
</style>
