<template>
  <el-dialog :title="isEdit ? '编辑' : '发布'" :visible.sync="dialogVisible" :before-close="handleClose" width="1500px"
    height="1000px">
    <el-form class="login-from-item" :model="form" label-position="left" ref="form" :rules="formRules">
      <!-- 项目名称 -->
      <div class="ProductName">
        <div class="SearchItem">
          <el-form-item label="产品名称" prop="landType">
            <el-input v-model="form.ProductTitle" placeholder="请输入名称"></el-input>
          </el-form-item>
        </div>
        <div class="SearchItem">
          <el-form-item label="所属机构">
            <el-select value-key="deptId" v-model="form.OrganizationItem" style="width: 200px">
              <el-option v-for="item in options" :key="item.deptId" :label="item.name"
                :value="item"></el-option></el-select>
          </el-form-item>
        </div>
      </div>
      <!-- 产品图标 -->
      <div class="ProductIcon">
        <el-form-item label="产品图标">
          <img v-if="logoUrl.length > 0" :src="'/api/admin/file/get?ossFilePath=' + logoUrl" alt="" />
          <el-upload class="upload-demo" accept="|.png,.jpg," :action="uploadUrl" :disabled="loading" name="files"
            :auto-upload="false" :on-change="handleChange" :file-list="fileList" :show-file-list="false">
            <el-button type="primary" :loading="loading">
              {{ loading ? "上传中" : "上传文件" }}</el-button>
          </el-upload>
        </el-form-item>
      </div>
      <!-- 产品介绍 -->
      <div class="ProductIntroduction">
        <span>产品介绍</span>
        <el-input type="textarea" placeholder="请输入内容" v-model="form.introduction" maxlength="500" show-word-limit>
        </el-input>
      </div>
      <!-- 还款可选方式 -->
      <div class="ModeOfRepayment">
        <span>还款可选方式</span>
        <el-checkbox-group v-model="form.repayType">
          <el-checkbox v-for="(item, index) in repay_type" :key="index" :label="item.value">
            {{ item.description }}
          </el-checkbox>
        </el-checkbox-group>
        <!-- <el-input
          :disabled="IsDisable1 == '-1' ? true : false"
          v-model="form.otherRepayType"
          placeholder="请输入还款方式"
        ></el-input> -->
      </div>
      <!-- 担保方式 -->
      <div class="GuaranteeMode">
        <span>担保方式</span>
        <el-checkbox-group v-model="form.creditType">
          <el-checkbox v-for="(item, index) in guarantee" :key="index" :label="item.value">
            {{ item.description }}
          </el-checkbox>
        </el-checkbox-group>
        <el-input :disabled="IsDisable2 == '-1' ? true : false" v-model="form.otherCretieType"
          placeholder="请输入担保方式"></el-input>
      </div>
      <!-- 贷款额度 -->
      <div class="FramedQuota">
        <div class="LoansItem1">
          <el-form-item label="贷款额度">
            <el-input v-model="form.minMoney" type="number" /> 元 <span class="To">至</span>
            <el-input v-model="form.maxMoney" type="number" /> 元
          </el-form-item>
          <div>
            <span class="col" v-if="MinMoney">{{ MinMoney }}~</span>
            <span class="col">{{ MaxMoney }}</span>
          </div>
        </div>
        <div class="LoansItem">
          <el-form-item label="贷款期限">
            <el-input v-model="form.minTime" type="number" /> 个月 <span class="To">至</span>
            <el-input v-model="form.maxTime" type="number" /> 个月
          </el-form-item>
        </div>
      </div>
      <!-- 利率 -->
      <div class="Rate">
        <el-form-item label="利率">
          <el-input v-model="form.minRate" type="number" />% <span class="To">至</span>
          <el-input v-model="form.maxRate" type="number" />%
        </el-form-item>
      </div>
      <!-- 服务对象 -->
      <div class="serviceObject">
        <span>服务对象</span>
        <el-input type="textarea" placeholder="请输入内容" v-model="form.serviceUser" maxlength="200" show-word-limit>
        </el-input>
      </div>
      <!-- 申请条件 -->
      <div class="ApplicationStatus">
        <span>申请条件</span>
        <el-input type="textarea" placeholder="请输入内容" maxlength="500" show-word-limit v-model="form.applyCondition">
        </el-input>
      </div>
      <!-- 相关附件 -->
      <!-- accept="|.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z" -->
      <div class="RelevantAttachment">
        <span>相关附件</span>
        <el-upload class="upload-demo" accept="|.doc,.docx,.xls,.xlsx,.pdf" :action="uploadUrl" name="files"
          :auto-upload="false" :on-change="handleChange2" :file-list="fileList" :show-file-list="false">
          <i class="el-icon-download"></i>
        </el-upload>
        <li v-for="(item, index) in files" :key="index" @click="AddFiles(item)">
          {{ item.FilesName }}
        </li>
      </div>
      <!-- 启用状态 -->
      <div class="StartupStatu">
        <span>启用状态</span>
        <el-radio v-model="form.radio" label="1">启用</el-radio>
        <el-radio v-model="form.radio" label="2">禁用</el-radio>
      </div>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="Cancel()">取 消</el-button>
      <!-- //"dialogVisible = false" -->
      <el-button type="primary" @click="AddFinancialProduct()">
        {{ isEdit ? "保存" : "发布" }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  // 图片上传
  import { uploadFile } from "@/api/fileApi";
  import { digitUppercase } from "@/util/numConvert";

  // 所属机构接口,编辑新增接口,多选条件列表
  import {
    BelongToTheOrganization,
    AddEdits,
    ScreeningCondition,
    DetailsFinancialProducts
  } from "@/api/RuralFinance";

  export default {
    data() {
      return {
        loading: false, //上传文件的状态
        form: {
          ProductTitle: "", //产品名称
          OrganizationItem: {
            deptId: "",
            departName: "",
          }, //所属机构
          deptId: "",
          introduction: "", //产品介绍
          repayType: [], // 还款方式
          creditType: [], //担保方式
          minMoney: "", //最小钱数
          maxMoney: "", //最大钱数
          maxTime: "", //最大贷款期限
          minTime: "", // 最小贷款期限
          minRate: "", //最小利率
          maxRate: "", //最大利率
          serviceUser: "", //服务对象
          radio: "1", //启用状态
          applyCondition: "", //申请条件
          otherCretieType: "", //输入担保方式
          otherRepayType: "", //输入还款方式
          baseUrl: window.globalUrl.HOME_API,
        },
        formRules: {
          // landType: [
          //   { required: true, message: "请选择土地类型", trigger: "change" },
          // ],
          // orderContent: [
          //   { required: true, message: "请选择托管环节", trigger: "change" },
          // ],
          // cropCode: [
          //   { required: true, message: "请选择托管作物", trigger: "change" },
          // ],
          // orderType: [
          //   { required: true, message: "请选择托管类型", trigger: "change" },
          // ],
          // landArea: [
          //   { required: true, message: "请输入托管面积", trigger: "blur" },
          // ],
          // startDate: [
          //   { required: true, message: "请选择托管开始时间", trigger: "blur" },
          // ],
          // endDate: [
          //   { required: true, message: "请选择托管结束时间", trigger: "blur" },
          // ],
          // // Time: [{ required: true, message: "请选择托管时间", trigger: "blur" }],
          // univalentPrice: [
          //   { required: true, message: "请输入单价", trigger: "blur" },
          // ],
          // estimatePrice: [
          //   { required: true, message: "请输入总价", trigger: "blur" },
          // ],
          // areaCode: [{ required: true, validator: checkArea, trigger: "change" }],
          // landCertificateUrl: [
          //   { required: true, message: "请上传产权凭证", trigger: "change" },
          // ],
        },
        rules: [],
        departName: "", //
        options: [], //所属机构列表
        // departId: "", //机构名称
        fileList: [], //产品图标上传
        uploadUrl: `${window.globalUrl.HOME_API_WEI}/admin/file/upload`, //上传图片的地址
        logoUrl: "", //机构图标地址
        files: [], //相关文件列表
        checkList: ["0", "1"], //启用状态列表
        dialogVisible: true, //弹窗开启状态
        isEdit: false, //当前页面状态：编辑还是新增
        financeProductId: "", //编辑页的编号
        guarantee: [], //担保方式
        repay_type: [], //还款方式
        baseUrl: window.globalUrl.BASE_API,
      };
    },

    mounted() {
      this.BelongOrganization();
      // 还款方式
      this.Repayment();
      //担保方式
      this.ScreeningCondition();
    },
    computed: {
      IsDisable1() {
        return this.form.repayType.indexOf("6");
      },
      IsDisable2() {
        return this.form.creditType.indexOf("5");
      },
      OrganizationItem() {
        return this.options.forEach((item) => {
          if (item.options == this.options) {
            return item;
          }
        });
      },
      //贷款最小金额
      MinMoney() {
        if (this.form.minMoney == 0) {
          return "";
        } else {
          return digitUppercase(this.form.minMoney);
        }
      },
      MaxMoney() {
        if (this.form.minMoney == 0) {
          return "";
        } else {
          return digitUppercase(this.form.maxMoney);
        }
      },
    },
    methods: {
      handleClose() {
        this.$emit("closeDialog", false);
      },
      // 取消弹窗按钮
      Cancel() {
        this.$emit("closeDialog", false);
      },
      // 多选对象
      ChangeObj() {
        this.options.forEach((item) => {
          if (item.options == this.options) {
            return item;
          }
        });
      },
      // 担保方式
      async ScreeningCondition() {
        let params = "credit_type";
        let res = await ScreeningCondition(params);
        this.guarantee = res.data;
      },
      // 还款方式
      async Repayment() {
        let params = "repay_type";
        let res = await ScreeningCondition(params);
        this.repay_type = res.data;
      },

      //所属机构
      async BelongOrganization() {
        let deptId = JSON.parse(localStorage.getItem("userInfo")).content.deptId;
        let res = await BelongToTheOrganization(deptId);
        this.options = res.data;

        // console.log(this.options)
      },
      //文件上传
      async handleChange2(file, fileList) {
        console.log(fileList, "这是fileList");
        let param = new FormData();
        param.append("catalog", "financeAdd");
        param.append("file", file.raw);
        let res = await uploadFile(param);
        console.log(res, "这是res");
        let FilesName = res.data.sourceFileName;
        let imgUrl = res.data.url;
        let item = {
          FilesName: FilesName,
          imgUrl: imgUrl,
        };
        this.files.push(item);
        console.log(this.files, "查看");
      },
      //新增附件下载功能
      async AddFiles(item) {
        console.log(item, "查看item");
        console.log(this.baseUrl + "admin/file/get?ossFilePath=" + item.imgUrl);
        const url = this.baseUrl + "admin/file/get?ossFilePath=" + item.imgUrl;
        let dom = document.createElement("a");
        dom.style.display = "none";
        dom.href = url;
        dom.setAttribute("download", item.FilesName);
        document.body.appendChild(dom);
        dom.click();
      },
      //图片上传
      async handleChange(file) {
        this.loading = true;
        let param = new FormData();
        param.append("catalog", "Chat");
        param.append("file", file.raw);
        let res = await uploadFile(param);
        if (res.code == 0) {
          this.$message.success(`${this.isEdit ? "编辑成功" : "上传成功"} `);
          let imgUrl = res.data.url;
          this.logoUrl = imgUrl;
          this.loading = false;
        } else {
          this.$message.error(`${this.isEdit ? "编辑失败" : "上传失败"}`);
          this.loading = false;
        }
      },
      //点击发布新增
      async AddFinancialProduct() {

        let files = JSON.stringify(this.files);
        let creditType = this.form.creditType.toString();
        let repayType = this.form.repayType.toString();
        if (this.form.OrganizationItem.deptId > 0) {

          //判断贷款额度不能为0
          if (this.form.minMoney === "0" || this.form.maxMoney === "0") {
            console.log(this.form.minMoney, this.form.maxMoney)
            this.$message.error('额度不能为0')
            return
          } else if (this.form.minTime === "0" || this.form.maxTime === "0") {
            console.log(this.form.minTime, this.form.maxTime)
            this.$message.error('期限不能为0')
            return
          } else if (this.form.minRate === "0" || this.form.maxRate === "0") {
            console.log(this.form.minRate, this.form.minRate)
            this.$message.error('利率不能为0')
            return
          }


          let params = {
            financeProductId: this.financeProductId,
            title: this.form.ProductTitle,
            departId: this.form.OrganizationItem.deptId,
            departName: this.form.OrganizationItem.name,
            status: this.form.radio,
            maxTime: this.form.maxTime,
            minTime: this.form.minTime,
            maxMoney: this.form.maxMoney,
            minMoney: this.form.minMoney,
            creditType: creditType,
            maxRate: this.form.maxRate,
            minRate: this.form.minRate,
            // createTime: "2023-11-27 14:46:23",
            serviceUser: this.form.serviceUser,
            applyCondition: this.form.applyCondition,
            files: files,
            logoUrl: this.logoUrl,
            introduction: this.form.introduction,
            repayType: repayType,
            otherRepayDetail: this.form.otherRepayType,
            otherCretieType: this.form.otherCretieType,
          };
          let res = await AddEdits(params);

          //是否发布成功提示
          if (res.code == 0) {
            this.$message.success("发布成功");
            this.$emit("closeDialog", false);
          } else {
            this.$message.info("发布失败");
          }
        } else {
          this.$message.info("请选择所属机构");
        }
      },
      //做编辑功能 回显，有的按element要求的格式，重新定义
      async editObj(a, b) {
        let params = {
          financeProductId: b.financeProductId,
        }
        let res = await DetailsFinancialProducts(params)
        const obj = res.data[0]



        this.isEdit = true;
        let creditType = obj.creditType.split(",");
        let repayType = obj.repayType.split(",");
        this.form.ProductTitle = obj.title;
        this.form.OrganizationItem.deptId = obj.departId;
        this.form.OrganizationItem.departName = obj.departName;
        this.financeProductId = a;
        this.form.radio = obj.status;
        this.form.maxTime = obj.maxTime == 0 ? null : obj.maxTime;
        this.form.minTime = obj.minTime == 0 ? null : obj.minTime;
        this.form.maxMoney = obj.maxMoney == 0 ? null : obj.maxMoney;
        this.form.minMoney = obj.minMoney == 0 ? null : obj.minMoney;
        this.form.maxRate = obj.maxRate == 0 ? null : obj.maxRate;
        this.form.minRate = obj.minRate == 0 ? null : obj.minRate;
        this.form.creditType = creditType;
        this.form.repayType = repayType;

        this.form.introduction = obj.introduction;
        this.logoUrl = obj.logoUrl;
        this.form.applyCondition = obj.applyCondition;
        this.form.serviceUser = obj.serviceUser;
        this.form.otherCretieType = obj.otherCretieType;
        this.form.otherRepayDetail = obj.otherRepayType;
        this.files = JSON.parse(obj.files);
      },
    },
  };
</script>

<style lang="less" scoped>
  /deep/.el-dialog__body {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
  }

  /deep/.login-from-item {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: space-between !important;

    /deep/.el-form-item {
      display: flex;

      .el-input {
        width: 200px;
      }

      .el-form-item__label {
        width: 96px;
        height: 40px;
      }
    }
  }

  .ProductName {
    width: 80%;
    height: 50px;
    display: flex;
    justify-content: space-between;

    .SearchItem {
      display: flex;

      /deep/.el-form-item {
        display: flex;

        .el-input {
          width: 200px;
        }

        .el-form-item__label {
          width: 96px;
          height: 40px;
        }
      }
    }
  }

  .ProductIcon {
    width: 80%;
    height: 100px;
    margin: 5px 0px;

    img {
      width: 250px;
      height: 100px;
    }

    /deep/.el-form-item__content {
      display: flex;

      img {
        padding-left: 26px;
      }

      .upload-demo {
        margin-left: 20px;
        line-height: 100px;
      }
    }
  }

  .ProductIntroduction {
    width: 80%;
    height: 50px;
    display: flex;
    padding-bottom: 5px;

    span {
      width: 100px;
    }
  }

  .ModeOfRepayment {
    width: 80%;
    height: 50px;
    display: flex;
    line-height: 50px;

    span {
      width: 15%;
    }

    .el-checkbox-group {
      width: 65%;
      display: flex;

      .el-checkbox {
        flex: 1;
      }
    }

    .el-input {
      width: 15%;
      height: 50px;
      margin-left: 20px;
    }
  }

  .GuaranteeMode {
    width: 80%;
    height: 50px;
    display: flex;
    line-height: 50px;

    span {
      width: 15%;
    }

    .el-checkbox-group {
      width: 65%;
      display: flex;

      .el-checkbox {
        flex: 1;
      }
    }

    .el-input {
      width: 15%;
      height: 50px;
      /* margin-left: 20px; */
    }
  }

  .FramedQuota {
    width: 90%;
    height: 50px;
    display: flex;
    justify-content: space-between;

    .LoansItem1 {
      width: 55%;
      display: flex;

      div {
        width: 70%;
        line-height: 35px;

        .col {
          color: red;
          padding: 0px 5px;
        }

        .To {
          padding: 0px 5px;
        }
      }

      /deep/.el-form-item {
        .el-form-item__label {
          margin-right: 20px;
        }

        .el-form-item__content {
          display: flex;

          .el-input {
            width: 100px;
          }
        }
      }
    }

    .LoansItem {
      width: 45%;
      display: flex;

      .To {
        padding: 0px 5px;
      }

      /deep/.el-form-item__content {
        display: flex;

        .el-input {
          width: 100px;
        }
      }
    }
  }

  .Rate {
    width: 80%;
    height: 50px;
    display: flex;

    .To {
      padding: 0px 5px;
    }

    /deep/.el-form-item__label {
      padding-right: 58px;
    }

    /deep/.el-form-item__content {
      display: flex;

      .el-input {
        width: 100px;
      }
    }
  }

  .serviceObject {
    width: 80%;
    height: 50px;
    display: flex;
    padding: 5px 0px;

    span {
      width: 100px;
    }
  }

  .ApplicationStatus {
    width: 80%;
    height: 50px;
    display: flex;
    padding: 5px 0px;

    span {
      width: 100px;
    }
  }

  .RelevantAttachment {
    width: 80%;
    height: 50px;
    display: flex;
    padding-top: 20px;
    list-style: none;

    li {
      padding: 5px 10px;
    }

    i {
      font-size: 20px;
      padding-right: 100px;
    }

    span {
      margin-right: 50px;
    }

    a {
      margin-right: 20px;
    }
  }

  .StartupStatu {
    width: 80%;
    height: 50px;

    span {
      margin-right: 50px;
    }
  }
</style>