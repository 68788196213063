<template>
  <div class="wdbtsq">
    <h3>我的补贴申请</h3>
    <div>
      <table-page :data="tableData" :columns="columns" :loading="loading" :border="true">
        <template slot="declareStatus" slot-scope="scope">
          <span>
            {{ getDeclareStatusLabel(scope.row.declareStatus) }}
          </span>
        </template>
        <template slot="orderState" slot-scope="scope">
          <el-button type="text" size="small" @click="detailClick(scope.row)">
            查看
          </el-button>
        </template>
      </table-page>
    </div>
  </div>
</template>
<script>
  import { wdbtsqColumn } from '../tableColumn'
  import { getMySubsidyList } from '@/api/subsidy'
  import TablePage from "@/components/table/table-page";
  export default {
    components: {
      TablePage,
    },

    data() {
      return {
        columns: wdbtsqColumn,
        loading: false,
        tableData: [],
      }
    },

    watch: {

    },
    mounted() {
      this.getMySubsidyList()
    },
    methods: {
      //显示申报状态
      getDeclareStatusLabel(declareStatus) {
        switch (declareStatus) {
          case "0":
            return "草稿";
          case "1":
            return "进行中审批";
          case "2":
            return "审批通过";
          case "3":
            return "审批不通过";
          default:
            return "未知状态";
        }
      },
      // 查看详情
      detailClick(obj) {

        let type = obj.type
        console.log(type, '查看type')
        let id = obj.id
        switch (type) {
          case "1":
            this.$router.push({ path: '/memberCenter/wdbtsq/components/grainSubsidy', query: { id: id } })
            break;
          case "3":
            this.$router.push({ path: '/memberCenter/wdbtsq/components/farmMachinery', query: { id: id } })
            break;
          case "4":
            this.$router.push({ path: '/memberCenter/wdbtsq/components/socialAllowance', query: { id: id } })
            break;
          default:
            break;
        }

      },
      // 申请补贴列表
      async getMySubsidyList() {
        let res = await getMySubsidyList()
        this.tableData = res.data
      },

    }
  };
</script>
<style lang='less' scoped>
</style>