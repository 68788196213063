<template>
  <div class="PendingApproval">
    <showEdit v-if="isShow" @closeDialog="closeDialog" ref="dbsp" />
    <drocessRecord
      v-if="isShow2"
      @closeDialog2="closeDialog2"
      ref="drocessRecord"
    />
    <h3>已办审批</h3>
    <el-form :inline="true">
      <el-form-item label="提交时间：">
        <el-date-picker
          v-model="form.startTime"
          placeholder="选择开始日期"
          size="small"
          value-format="yyyy-MM-dd"
          clearable
        >
        </el-date-picker>
        至
        <el-date-picker
          v-model="form.endTime"
          placeholder="选择结束日期"
          align="right"
          size="small"
          value-format="yyyy-MM-dd"
          clearable
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="发起人">
        <el-input
          style="width: 190px"
          v-model="form.startPeople"
          placeholder="请输入"
        >
        </el-input>
      </el-form-item>
<!--
      <el-form-item label="任务类型">
        <el-select style="width: 190px" v-model="form.taskType">
          <el-option
            v-for="item in typeOptions"
            :key="item.label"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="审批号">
        <el-input
          style="width: 190px"
          v-model="form.processNumber"
          placeholder="请输入"
        >
        </el-input>
      </el-form-item> -->
      <div class="button" style="display: inline-block; margin: 0">
        <el-form-item>
          <el-button class="query-btn" type="primary" @click="list"
            >查询</el-button
          >
          <el-button class="query-btn" type="primary" @click="clear"
            >清空</el-button
          >
          <!-- isDownloading -->
          <el-button class="query-btn" type="primary" @click="approvalDown" :disabled="isDownloading"
            >{{totalFiles? '审批表下载中'+ downloadProgress +'/'+totalFiles : '批量下载审批表'}} </el-button
          >
          <!-- <el-button class="query-btn" type="primary" @click="clear"
            >下载本年度补贴公告公示</el-button
          > -->
        </el-form-item>
      </div>
    </el-form>

    <div class="table-content">
      <table-page
        :data="tableData"
        :columns="columns"
        :currentPage="query.pnum"
        :pageSize="query.psize"
        :total="total"
        :border="true"
        @sizeChange="handleSizeChange"
        @currentChange="handleCurrentChange"
        @selectionChange="handleSelection"
      >
        <template slot="status" slot-scope="scope">
          <span>{{ scope.row.status == 1 ? "启用" : "停用" }}</span>
        </template>
        <template slot="operation" slot-scope="scope">
          <el-button type="text" size="small" @click="detailsBtn(scope.row)"
            >查看</el-button
          >
          <el-button type="text" size="small" @click="turnover(scope.row)"
            >流转记录</el-button
          >
          <!-- isDownLoading -->
          <el-button type="text" size="small" v-if="scope.row.status == 0" @click="detailClick(scope.row)" :disabled="scope.row.isDownLoading"
            >{{scope.row.totalFiles? '审批下载中'+scope.row.downloadProgress +'/'+scope.row.totalFiles : '下载审批表'}}{{scope.row.isDownLoading  }} </el-button
          >
        </template>
      </table-page>
    </div>
    <!-- 弹窗 -->
    <div>
      <!-- 其他内容 -->
      <!-- <progress v-if="isDownloading" :value="downloadProgress" max="100"></progress> -->
      <!-- 其他内容 -->
      <!-- <el-progress v-if="isDownloading" :percentage="downloadedFiles"></el-progress> -->
    </div>
  </div>
</template>

<script>

import saveAs from 'file-saver';
import JSZip  from 'jszip';

import { dbspColumn1 } from "../tableColumn";
import TablePage from "@/components/table/table-page";
import showEdit from "../dbspDetails.vue";
import { selectHistoryProcess,selectPdfApproval,selectBatchPdf } from "@/api/spgl";
import drocessRecord from "../drocessRecord";
export default  {
  components: {
    TablePage,
    showEdit,
    drocessRecord
  },
  data() {
    return {
       // 其他状态
      isDownloading: false,  // 记录是否正在下载
      downloadProgress: 0,    // 下载进度
      totalFiles:0,//文件总数
      form: {
        endTime:null,
        startTime: null,
        taskType: null,
        processNumber:null,
        startPeople:null,
      },
      tableData: [],
      baseUrl: window.globalUrl.BASE_API,
      query: {
        pnum: 1,
        psize: 10,
      },
      columns: dbspColumn1,
      time: "",
      time1: "",
      isShow: false,
      isShow2: false,
      total: 0,
      dialogVisible: false,
    };
  },
  created() {
    // this.startTime = this.$moment().format("YYYY-01-01");
    // this.endTime = this.$moment().format("YYYY-MM-DD");
    this.list()
  },

  methods: {
    async list() {
      var params = {
        endTime: this.form.endTime,
        pageNo: this.query.pnum,
        pageSize: this.query.psize,
        startTime: this.form.startTime,
        taskType: this.form.taskType,
        startPeople:this.form.startPeople,
        processNumber:this.form.processNumber
      };
      let res = await selectHistoryProcess(params);
      this.total= parseInt(res.data.total)
      // this.query.pnum= parseInt(res.data.pages)? parseInt(res.data.pages) : 1
      // this.query.psize=parseInt(res.data.size)
      this.tableData= res.data.records
      console.log(res);
    },

    //下载本年度流转审批表
    async  approvalDown(){
      let params={
          ...this.form
      }
      let res =await selectBatchPdf(params)
      const fileUrlsList = res.data.map(item => {
        const parsedItem = JSON.parse(item);
        return {
          fileName: parsedItem.FilesName,
          fileUrl:  this.baseUrl + "admin/file/get?ossFilePath=" + encodeURIComponent(parsedItem.imgUrl)
        }
    });
    console.log(fileUrlsList,'查看fileUrlsList')
    this.$confirm('是否要下载打包文件？', '提示', {
        confirmButtonText: '下载',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(async () => {
        // // 显示进度条和初始化状态

        this.isDownloading = true;
        this.downloadProgress = 0;
        this.totalFiles=0;
        const zip = new JSZip();
        console.log(zip,'查看zip是什么')
        this.totalFiles = fileUrlsList.length;  // 文件总数
        console.log(this.totalFiles,'查看totalFiles')
        // console.log(num,'查看num')

        for (const item of fileUrlsList) {
          console.log(item,'查看ITem')
            try {
                const response = await new Promise((resolve, reject) => {
                    const xhr = new XMLHttpRequest();
                    xhr.open('GET', item.fileUrl, true);
                    xhr.responseType = 'blob'; // 设置响应类型为 blob

                    // 进度事件
                    xhr.onprogress = (event) => {
                      // console.log(event,'查看event')
                        if (event.lengthComputable) {

                            // const percentComplete = (event.loaded / event.total) * 100;
                            // this.downloadProgress = (percentComplete + (100 * downloadedFiles) / totalFiles); // 累加进度
                        }
                    };

                    xhr.onload = () => {
                        if (xhr.status === 200) {
                            resolve(xhr.response); // 返回 blob
                            this.downloadProgress++
                            console.log(this.downloadProgress,'查看downloadedFiles++')
                            if(this.downloadProgress==this.totalFiles){
                              this.totalFiles=0
                            }
                        } else {
                            reject(new Error(`Could not download file: ${item.fileUrl}`));
                        }
                    };

                    xhr.onerror = () => reject(new Error(`Network error while downloading: ${item.fileUrl}`));
                    xhr.send(); // 发送请求
                });

                const fileName = item.fileName + '.pdf';
                zip.file(fileName, response); // 添加到 zip
            } catch (error) {
                console.error(error);
                this.$message.error(`下载文件 ${item.fileName} 失败: ${error.message}`);
            }
        }

        // 生成 ZIP 文件
        const zipContent = await zip.generateAsync({ type: "blob" });
        saveAs(zipContent, "年度流转审批表.zip"); // 指定 ZIP 文件名

        this.$message.success('文件下载完成！');
        this.isDownloading = false; // 下载完成
        }).catch(() => {
            this.$message.info('已取消下载');
            this.isDownloading = false; // 取消时，也要重置进度状态
        });



    },
    // // 下载单个文件
    // downloadSingleFile(fileUrlsList) {
    //   console.log(fileUrlsList[0].fileName,'查看fileUrlsList')
    //   //   try {
    //   //   // 解析 JSON 字符串
    //   //   const downloadUrl =fileUrlsList[0].fileUrl;
    //   //   console.log(downloadUrl,'查看downloadUrl')
    //   //   // 创建一个 <a> 元素以触发下载
    //   //   const link = document.createElement('a');
    //   //   link.href = downloadUrl; // 设置 href 为文件的下载地址
    //   //   link.download =decodeURIComponent(fileUrlsList[0].fileName) ; // 设置下载的文件名

    //   //   // 将 <a> 元素添加到 DOM 中以便触发点击
    //   //   document.body.appendChild(link);
    //   //   link.click(); // 模拟点击触发下载

    //   //   // 下载后移除 <a> 元素
    //   //   document.body.removeChild(link);
    //   // } catch (error) {
    //   //   console.error('下载文件时出错:', error);
    //   // }



    //   let dom = document.createElement("a");
    //   dom.style.display = "none";
    //   dom.href =fileUrlsList[0].fileUrl;
    //   dom.setAttribute("download", fileUrlsList[0].fileName);
    //   document.body.appendChild(dom);
    //   dom.click();





    // },
// 下载并压缩多个文件
    // async  downloadAndZipFiles(fileUrlsList) {

    //   // //不能删，暂时是最最好使的数据
    //   //     // 弹窗提示
    //   //     this.$confirm('是否要下载打包文件？', '提示', {
    //   //         confirmButtonText: '下载',
    //   //         cancelButtonText: '取消',
    //   //         type: 'warning'
    //   //       }).then(async() => {
    //   //         const zip = new JSZip();
    //   //    for (const item of fileUrlsList) {
    //   //       try {
    //   //           const response = await fetch(item.fileUrl);
    //   //           console.log(response,'查看response')
    //   //           if (!response.ok) {
    //   //               throw new Error(`Could not download file: ${fileUrl}`);
    //   //           }

    //   //           const blob = await response.blob();
    //   //           // const fileName = fileUrl.split('/').pop(); // 从 URL 获取文件名
    //   //           const fileName=item.fileName+'.pdf'
    //   //           zip.file(fileName, blob);
    //   //       } catch (error) {
    //   //           console.error(error);
    //   //       }
    //   //   }

    //   //   const zipContent = await zip.generateAsync({ type: "blob" });
    //   //   saveAs(zipContent, "资源资产流转审批表.zip"); // 指定 ZIP 文件名
    //   //       }).catch(() => {
    //   //         this.$message.info('已取消下载');
    //   //       });
    // //测试进度条
    //   this.$confirm('是否要下载打包文件？', '提示', {
    //     confirmButtonText: '下载',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    // }).then(async () => {
    //     // // 显示进度条和初始化状态

    //     this.isDownloading = true;
    //     this.downloadProgress = 0;
    //     this.totalFiles=0;
    //     const zip = new JSZip();
    //     console.log(zip,'查看zip是什么')
    //     this.totalFiles = fileUrlsList.length;  // 文件总数
    //     console.log(this.totalFiles,'查看totalFiles')
    //     // console.log(num,'查看num')

    //     for (const item of fileUrlsList) {
    //       console.log(item,'查看ITem')
    //         try {
    //             const response = await new Promise((resolve, reject) => {
    //                 const xhr = new XMLHttpRequest();
    //                 xhr.open('GET', item.fileUrl, true);
    //                 xhr.responseType = 'blob'; // 设置响应类型为 blob

    //                 // 进度事件
    //                 xhr.onprogress = (event) => {
    //                   // console.log(event,'查看event')
    //                     if (event.lengthComputable) {

    //                         // const percentComplete = (event.loaded / event.total) * 100;
    //                         // this.downloadProgress = (percentComplete + (100 * downloadedFiles) / totalFiles); // 累加进度
    //                     }
    //                 };

    //                 xhr.onload = () => {
    //                     if (xhr.status === 200) {
    //                         resolve(xhr.response); // 返回 blob
    //                         this.downloadProgress++
    //                         console.log(this.downloadProgress,'查看downloadedFiles++')
    //                     } else {
    //                         reject(new Error(`Could not download file: ${item.fileUrl}`));
    //                     }
    //                 };

    //                 xhr.onerror = () => reject(new Error(`Network error while downloading: ${item.fileUrl}`));
    //                 xhr.send(); // 发送请求
    //             });

    //             const fileName = item.fileName + '.pdf';
    //             zip.file(fileName, response); // 添加到 zip
    //         } catch (error) {
    //             console.error(error);
    //             this.$message.error(`下载文件 ${item.fileName} 失败: ${error.message}`);
    //         }
    //     }

    //     // 生成 ZIP 文件
    //     const zipContent = await zip.generateAsync({ type: "blob" });
    //     saveAs(zipContent, "资源资产流转审批表.zip"); // 指定 ZIP 文件名

    //     this.$message.success('文件下载完成！');
    //     this.isDownloading = false; // 下载完成
    //     }).catch(() => {

    //         this.$message.info('已取消下载');
    //         this.isDownloading = false; // 取消时，也要重置进度状态
    //     });


    // },
    //下载审批表
    async  detailClick(obj){
    let params={
      taskId:obj.taskId
      // taskId:"05b5e91f-a09a-11ef-9a5d-fa163e65bed1"
      // taskId:'4527412a-9bb9-11ef-a830-10b1df72393a'
    }
    // console.log(params,'查看params')
    let res =await selectPdfApproval(params)
    console.log(res.data,'查看res.data')
    //判断返回值的长度
    const fileUrlsList = res.data.map(item => {
        const parsedItem = JSON.parse(item);
        return {
          fileName: parsedItem.FilesName,
          fileUrl:  this.baseUrl + "admin/file/get?ossFilePath=" + encodeURIComponent(parsedItem.imgUrl)
        }
    });

    if (fileUrlsList.length === 1) {
        // this.downloadSingleFile(fileUrlsList); // 下载单个文件
      let dom = document.createElement("a");
      dom.style.display = "none";
      dom.href =fileUrlsList[0].fileUrl;
      dom.setAttribute("download", fileUrlsList[0].FilesName);
      document.body.appendChild(dom);
      dom.click();

    } else if (fileUrlsList.length > 1) {
        // this.downloadAndZipFiles(fileUrlsList); // 下载并打包文件
        this.$confirm('是否要下载打包文件？', '提示', {
        confirmButtonText: '下载',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(async () => {
        // // 显示进度条和初始化状态

        // this.isDownloading = true;
        // this.downloadProgress = 0;
        // this.totalFiles=0;
        let isDownLoading=true;
        let downloadProgress = 0;
        let totalFiles=fileUrlsList.length;
        this.$set(obj, "downloadProgress", downloadProgress );
        this.$set(obj, "totalFiles",totalFiles );
        this.$set(obj, "isDownLoading",isDownLoading );
        const zip = new JSZip();
        // console.log(zip,'查看zip是什么')
        // this.totalFiles = fileUrlsList.length;  // 文件总数
        // console.log(this.totalFiles,'查看totalFiles')
        // console.log(num,'查看num')

        for (const item of fileUrlsList) {
          // console.log(item,'查看ITem')
            try {
                const response = await new Promise((resolve, reject) => {
                    const xhr = new XMLHttpRequest();
                    xhr.open('GET', item.fileUrl, true);
                    xhr.responseType = 'blob'; // 设置响应类型为 blob

                    // 进度事件
                    xhr.onprogress = (event) => {
                      // console.log(event,'查看event')
                        if (event.lengthComputable) {

                            // const percentComplete = (event.loaded / event.total) * 100;
                            // this.downloadProgress = (percentComplete + (100 * downloadedFiles) / totalFiles); // 累加进度
                        }
                    };

                    // xhr.onload = () => {
                    //     if (xhr.status === 200) {
                    //         resolve(xhr.response); // 返回 blob
                    //         downloadProgress++;
                    //         console.log('查看downloadedFiles++',downloadProgress  )
                    //         this.$set(obj, "downloadProgress", downloadProgress );
                    //         // this.downloadProgress++
                    //         // console.log(this.downloadProgress,'查看downloadedFiles++')
                    //         if(downloadProgress==totalFiles){
                    //           console.log(obj,'查看obj1')
                    //              this.$set(obj, "totalFiles", totalFiles );
                    //         }
                    //     } else {
                    //         reject(new Error(`Could not download file: ${item.fileUrl}`));
                    //     }
                    // };
                    xhr.onload = () => {
                    if (xhr.status === 200) {
                        resolve(xhr.response); // 返回 blob

                        // 更新下载进度
                        obj.downloadProgress++; // 自增下载进度
                        this.$set(obj, "downloadProgress", obj.downloadProgress); // 确保 Vue 识别到更新
                        console.log(obj.downloadProgress, '下载进度更新');

                        // 检查是否所有文件都已下载
                        if (obj.downloadProgress === obj.totalFiles) {
                             isDownLoading=!isDownLoading
                             this.$set(obj, "isDownLoading",isDownLoading );
                             this.$set(obj, "totalFiles", 0); // 重置文件总数

                        }
                    } else {
                        reject(new Error(`无法下载文件: ${item.fileUrl}`)); // 错误处理
                    }
                };
                    console.log(obj,'查看obj2')
                    xhr.onerror = () => reject(new Error(`Network error while downloading: ${item.fileUrl}`));
                    xhr.send(); // 发送请求
                });

                const fileName = item.fileName + '.pdf';
                zip.file(fileName, response); // 添加到 zip
            } catch (error) {
                console.error(error);
                this.$message.error(`下载文件 ${item.fileName} 失败: ${error.message}`);
            }
        }

        // 生成 ZIP 文件
        const zipContent = await zip.generateAsync({ type: "blob" });
        saveAs(zipContent, "资源资产流转审批表.zip"); // 指定 ZIP 文件名

        this.$message.success('文件下载完成！');
        this.isDownloading = false; // 下载完成
        }).catch(() => {

            this.$message.info('已取消下载');
            this.isDownloading = false; // 取消时，也要重置进度状态
        });

    }

    },
    // 关闭弹框
    closeDialog() {
      this.isShow = false;
      this.list();
    },
    // 切换时间
    changeTime(val) {
      console.log(val);
      // if (val == 1) {
      this.startTime = val;
      // } else {
      //   this.endTime = val[1];
      // }
    },
    // 切换时间
    changeTime1(val) {
      console.log(val);
      // if (val == 1) {
      //   this.startTime = val[0];
      // } else {
      this.endTime = val;
      // }
    },
    //分页单页切换
    handleCurrentChange(page) {
      this.query.pnum = page;
      this.list()
    },
    //分页总页数切换
    handleSizeChange(size) {
      this.query = {
        pnum: 1,
        psize: size,
      };
      this.list()
    },
    handleSelection(selects) {
      console.log(selects);
    },
    detailsBtn(row) {
      console.log("这里执行了嘛");
      this.isShow = true;
      this.$nextTick(() => {
 this.$refs.dbsp.receiveData(row,false);

});
    },
    turnover(row) {
      this.isShow2 = true;
      this.$nextTick(() => {
        this.$refs.drocessRecord.jilu(row);
      });
    },
    closeDialog2() {
      this.isShow2 = false;
    },
    clear() {
      console.log("清理");
      this.form={
        endTime:null,
        startTime: null,
        taskType: null,
        processNumber:null,
        startPeople:null
      }
      this.query=  {
        pnum: 1,
        psize: 10,
      }
    },
  },
};
</script>
